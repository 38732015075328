import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { ToastContainer, toast } from 'react-toastify';

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { UserContex } from '../App';

const SingleProduct = () => {
    const { state, dispatch } = useContext(UserContex);
    const history = useNavigate();
    const [addStock, setAddStock] = useState(false);
    const [addReview, setAddReview] = useState(false);
    const [size, setSize] = useState("");
    const [title, setTitle] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [description, setDescription] = useState("");
    const [star, setStar] = useState("");
    const [data, setData] = useState({});
    const [products,setProducts] = useState([]);
    const [cart, setCart] = useState([]);
    const [book, setBook] = useState([]);
    const [bookid, setBookId] = useState([]);
    
    const [cartid, setCartId] = useState([]);
    const [url, setUrl] = useState([]);
    const { productid } = useParams();

    const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);

    useEffect(() => {
        const l = JSON.parse(localStorage.getItem('cart'));
        const lid = JSON.parse(localStorage.getItem('cartid'));

        if (l) {
            setCart(l);
        }
        if (lid) {
            setCartId(lid);
        }

        fetch(`/getSingleConsumerStock/${productid}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("jwt")
            }
        }).then(res => res.json())
            .then(result => {
                setData(result.stock);
                setUrl(result.stock.pic);
            });

    }, [productid]);


    useEffect(  ()=>{

    

        fetch('/getallStock',{
          headers:{
              Authorization: "Bearer " +localStorage.getItem("jwt")
              
          }
      }).then(res=>res.json())
      .then(result=>{
          setProducts(result.stocks)
          
      })
    
    
    
    
      
    
    
    
    //   fetch(`/getconsumerprofile`,{
    //     method:"get",
    //     headers:{
    //         Authorization: "Bearer " +localStorage.getItem("jwt"),
    //         "Content-Type":"application/json"
    
            
    //     },
     
    // }).then(res=>res.json())
    // .then(result=>{
    
    //   // console.log("DSF",result.consumer[0].address)
    //     // setOrderHistoryData(result.orders)
    //     // setData(result.consumer)
    //     setAllAddress(result.consumer[0].address)
    // })
    
    
      },[])


    const addtoCart = (item) => {
        setCart(olditem => [...olditem, { product: item, qty: 1, size: size }]);
        setCartId(olditem => [...olditem, item._id]);

        const json = localStorage.getItem("cart");
        const previousPosts = json ? JSON.parse(json) : [];
        const updatedPosts = [...previousPosts, { product: item, qty: 1, size: size }];
        const stringifyed = JSON.stringify(updatedPosts);
        localStorage.setItem("cart", stringifyed);

        const jsonid = localStorage.getItem("cartid");
        const previousPostsid = jsonid ? JSON.parse(jsonid) : [];
        const updatedPostsid = [...previousPostsid, item._id];
        const stringifyedid = JSON.stringify(updatedPostsid);
        localStorage.setItem("cartid", stringifyedid);
    }


    const addtoBook = (item) => {
        setBook(olditem => [...olditem, { product: item }]);
        setBookId(olditem => [...olditem, item._id]);

        const json = localStorage.getItem("book");
        const previousPosts = json ? JSON.parse(json) : [];
        const updatedPosts = [...previousPosts, { product: item }];
        const stringifyed = JSON.stringify(updatedPosts);
        localStorage.setItem("book", stringifyed);

        const jsonid = localStorage.getItem("bookid");
        const previousPostsid = jsonid ? JSON.parse(jsonid) : [];
        const updatedPostsid = [...previousPostsid, item._id];
        const stringifyedid = JSON.stringify(updatedPostsid);
        localStorage.setItem("bookid", stringifyedid);
    }



    const removeFromBook = (item) => {
        // Remove item from state
        setBook(oldItems => oldItems.filter(bookItem => bookItem.product._id !== item._id));
        setBookId(oldItems => oldItems.filter(bookItemId => bookItemId !== item._id));
    
        // Remove item from localStorage (book)
        const json = localStorage.getItem("book");
        const previousPosts = json ? JSON.parse(json) : [];
        const updatedPosts = previousPosts.filter(post => post.product._id !== item._id);
        const stringifyed = JSON.stringify(updatedPosts);
        localStorage.setItem("book", stringifyed);
    
        // Remove item from localStorage (bookid)
        const jsonid = localStorage.getItem("bookid");
        const previousPostsid = jsonid ? JSON.parse(jsonid) : [];
        const updatedPostsid = previousPostsid.filter(id => id !== item._id);
        const stringifyedid = JSON.stringify(updatedPostsid);
        localStorage.setItem("bookid", stringifyedid);
    }
    const postReview = ()=>{


  fetch(`/addreview/${productid}`,{
    method:"put",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"
    },
    body: JSON.stringify({
      
        title,name, email,description,star:rating
    })
    
}).then(res=>res.json())
.then(data=> {
   
    if(data.error){
      toast.error(data.error)

    }
    else{
   toast.success("Updated")
  //  history.go('/cart')
            
  setAddReview(false)
    }
})
.catch(err=>{
    console.log(err)
})

    }




  const handleMouseEnter = (index) => {
    setHoverRating(index + 1);
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  const handleClick = (index) => {
    setRating(index + 1);
  };


    return (
        <>
            <Header></Header>

<ToastContainer/>
            {data && data.hasOwnProperty("pic") ?
                <>
                    <div className="relative max-w-screen-xl p-4 px-4 mx-auto bg-white sm:px-6 lg:px-8 mt-32 ">
                        <div className="relative">
                            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">

           <div className="flex flex-row items-start gap-4">
    <Carousel
        showArrows={true}
        showThumbs={true}
        className="carousel"
        renderThumbs={() =>
            url.map((pic, index) => (
                <img
                    key={index}
                    src={pic}
                    alt={`Thumbnail ${index}`}
                    className="rounded-lg"
                />
            ))
        }
    >
        {url.map((pic, index) => (
            <div key={index}>
                <img src={pic} alt={`Slide ${index}`} className="rounded-lg object-contain h-96 w-80" />
            </div>
        ))}
    </Carousel>
</div>

                                <div className="ml-auto lg:col-start-2 lg:max-w-2xl">
                                    <p className="text-base font-semibold leading-6 text-blue-500 uppercase">
                                        Description
                                    </p>
                                    <h4 className="mt-2 text-2xl font-extrabold leading-8 text-gray-900 sm:text-3xl sm:leading-9">
                                        {data.name}
                                    </h4>
                                    {bookid.includes(data._id)?  <svg
          className="h-5 w-5 text-red-500  top-2 right-2"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"

          onClick={()=>{removeFromBook(data)}}
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <path fill={`${"currentColor"}`} d="M12 20l-7 -7a4 4 0 0 1 6.5 -6a.9 .9 0 0 0 1 0a4 4 0 0 1 6.5 6l-7 7" />
        </svg>:

                <svg
          className="h-5 w-5 text-red-500  top-2 right-2"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"

          onClick={()=>{addtoBook(data)}}
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <path fill={`${"none"}`} d="M12 20l-7 -7a4 4 0 0 1 6.5 -6a.9 .9 0 0 0 1 0a4 4 0 0 1 6.5 6l-7 7" />
        </svg>
            }
            
                                    <p className="mt-4 text-lg leading-6 text-gray-500 ">
                                        {data.description}
                                    </p>
                                    <ul className="gap-6 mt-8 md:grid md:grid-cols-2 mb-6">
                                        <li className="mt-6 lg:mt-0">
                                            <div className="flex">
                                                <span className="text-base font-medium leading-6 text-gray-500 ">
                                                    $ {data.rate}
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                    Size
                                    <div className="flex mt-4 mb-6 text-gray-700 dark:text-gray-300">
                                        <div className="flex space-x-2">
                                            {['xs', 's', 'm', 'l', 'xl'].map(sizeOption => (
                                                <label className="text-center" key={sizeOption}>
                                                    <input
                                                        type="radio"
                                                        className="flex items-center justify-center w-6 h-6"
                                                        name="size"
                                                        value={sizeOption}
                                                        onClick={() => setSize(sizeOption)}
                                                    />
                                                    {sizeOption.toUpperCase()}
                                                </label>
                                            ))}
                                        </div>
                                    </div>

                                    {!cartid.includes(productid) ?
                                        <button type="button" className="py-2 mt-4 px-4 flex justify-center items-center bg-slate-600 hover:bg-slate-700 text-white w-1/2 transition ease-in duration-200 text-center text-base font-semibold shadow-md rounded-3xl"
                                            onClick={() => { addtoCart(data) }}>
                                            <svg className="h-8 w-8 mr-2 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                <circle cx="12" cy="12" r="10" />
                                                <line x1="12" y1="8" x2="12" y2="16" />
                                                <line x1="8" y1="12" x2="16" y2="12" />
                                            </svg>
                                            Add to Cart
                                        </button> :
                                        <>
                                            <span className="px-4 py-2 mt-6 text-base rounded-full text-green-600 bg-green-200">
                                                In Cart
                                            </span>
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                </>

            
                : <>LOADING...</>}


<div className='grid md:grid-cols-2 grid-cols-1 gap-6 p-2 md:p-12'>
                 <div class="max-w-screen-xl  mx-auto  ">
    <h1 class=" text-sm text-center font-black leading-none text-gray-800 uppercase font-bebas-neue  ">
    Our Solutions
</h1>
        <ul class="flex flex-wrap items-center gap-8">
            <li class="w-full">
               
                <p class="mt-2">
                    <p class="text-base leading-6 text-gray-500 text-justify">
                    Harnessing Nature's Sustainable Fiber
Mountain Crafts champions Himalayan Nettle as an eco-friendly alternative to conventional textiles. This remarkable bast fiber, known for its strength and durability, offers numerous benefits:
                            </p>
                </p>
            </li>
          
       
        </ul>
        <ul class="w-full mt-6 mb-6 text-sm text-gray-600 ">
        <li class="mb-3 flex items-center ">
            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                </path>
            </svg>
            Sustainable harvesting practices that support local ecosystems
        </li>
     
        <li class="mb-3 flex items-center ">
            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                </path>
            </svg>
            Natural pest resistance, eliminating the need for harmful chemicals        </li>
     
        <li class="mb-3 flex items-center ">
            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                </path>
            </svg>
            Minimal water requirements compared to cotton        </li>
     
        <li class="mb-3 flex items-center ">
            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                </path>
            </svg>
            Carbon sequestration, helping to mitigate climate change        </li>
     
        <li class="mb-3 flex items-center ">
            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                </path>
            </svg>
            Erosion control in mountainous regions, preventing landslides        </li>
     
    </ul>
    <ul class="flex flex-wrap items-center gap-8">
            <li class="w-full">
               
                <p class="mt-2">
                    <p class="text-base leading-6 text-gray-500 text-justify">
                    Our handwoven Himalayan Nettle products not only provide superior quality but also support indigenous communities and preserve traditional craftsmanship. By choosing Himalayan Nettle, you're investing in a sustainable future for both people and the planet.
                    </p>
                </p>
            </li>
          
       
        </ul>
    
    </div>
         
         <div className='md:px-12'>
         <h1 class=" text-sm text-center font-black leading-none text-gray-800 uppercase font-bebas-neue  ">
         Complete the Look

</h1>
           
     



<div class="flex overflow-x-scroll   hide-scroll-bar">
  <div class="flex flex-nowrap  mt-6 " >
    

  {/* <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">

        <img class="w-28 h-28   relative" src='https://img.freepik.com/premium-vector/stack-wood-logs_118813-3016.jpg?size=626&ext=jpg'>
        </img>
        <p class="text-gray-800 text-l font-medium mb-4 mt-4">
        Logs

        </p>
    
    </div>
    </a>
</div> */}

{products.slice(0,8).map((product) => (
            <div key={product._id} className="w-80 h-full p-2 ">

              <div className=" w-full overflow-hidden rounded-md bg-gray-200 relative">

                {bookid.includes(product._id)?  <svg
          className="h-5 w-5 text-red-500 absolute top-2 right-2"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"

          onClick={()=>{removeFromBook(product)}}
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <path fill={`${"currentColor"}`} d="M12 20l-7 -7a4 4 0 0 1 6.5 -6a.9 .9 0 0 0 1 0a4 4 0 0 1 6.5 6l-7 7" />
        </svg>:

                <svg
          className="h-5 w-5 text-red-500 absolute top-2 right-2"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"

          onClick={()=>{addtoBook(product)}}
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <path fill={`${"none"}`} d="M12 20l-7 -7a4 4 0 0 1 6.5 -6a.9 .9 0 0 0 1 0a4 4 0 0 1 6.5 6l-7 7" />
        </svg>
            }
            


        
        <Link to={`/singleproduct/${product._id}`}>

                <img
                  alt={product.imageAlt}
                  src={product.pic[0]}
                  className="h-80 w-80 object-cover object-center "
                />
                              </Link>

              </div>
              <div className="mt-4 flex justify-between">
                <div>
                  <h3 className="text-sm text-gray-700">
                      <span aria-hidden="true" className=" inset-0" />
                      {product.name}
                  </h3>
                  {/* <p className="mt-1 text-sm text-gray-500">{product.color}</p> */}
                </div>
                {/* <p className="text-sm font-medium text-gray-900">{selectedCurrency.value} {product.rate * selectedCurrency.conv}</p> */}
              </div>
            </div>
          ))}

 


 
  </div>
</div>
</div>
 
         </div>

         <h1 class=" text-lg text-center font-black leading-none text-gray-800 uppercase font-bebas-neue mt-4 ">
         Explore More

</h1>
         
         <div class="flex overflow-x-scroll   hide-scroll-bar">
            
  <div class="flex flex-nowrap   mt-6 " >
    



{products.slice(3,14).map((product) => (
            <div key={product._id} className="w-80 h-full p-2 ">




              <div className=" w-full overflow-hidden rounded-md bg-gray-200  relative">

              {bookid.includes(product._id)?  <svg
          className="h-5 w-5 text-red-500 absolute top-2 right-2"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"

          onClick={()=>{removeFromBook(product)}}
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <path fill={`${"currentColor"}`} d="M12 20l-7 -7a4 4 0 0 1 6.5 -6a.9 .9 0 0 0 1 0a4 4 0 0 1 6.5 6l-7 7" />
        </svg>:

                <svg
          className="h-5 w-5 text-red-500 absolute top-2 right-2"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"

          onClick={()=>{addtoBook(product)}}
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <path fill={`${"none"}`} d="M12 20l-7 -7a4 4 0 0 1 6.5 -6a.9 .9 0 0 0 1 0a4 4 0 0 1 6.5 6l-7 7" />
        </svg>
            }
            
                                    <Link to={`/singleproduct/${product._id}`}>
<img
                  alt={product.imageAlt}
                  src={product.pic[0]}
                  className="h-80 w-80 object-cover object-center "
                />              </Link>

              </div>
              <div className="mt-4 flex justify-between">
                <div>
                  <h3 className="text-sm text-gray-700">
                      <span aria-hidden="true" className=" inset-0" />
                      {product.name}
                  </h3>
                  {/* <p className="mt-1 text-sm text-gray-500">{product.color}</p> */}
                </div>
                {/* <p className="text-sm font-medium text-gray-900">{selectedCurrency.value} {product.rate * selectedCurrency.conv}</p> */}
              </div>
            </div>
          ))}

 


 
  </div>
</div>




<div class="bg-white dark:bg-gray-800 ">
    <div class="text-center w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
        <h2 class="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
            <span class="block">
                Have a review ?
            </span>
            <span class="block text-indigo-500">
                Share us your review for this product.
            </span>
        </h2>
        <div class="lg:mt-0 lg:flex-shrink-0">
            <div class="mt-12 inline-flex rounded-md shadow">
                <button type="button" class="py-4 px-6  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                onClick={()=>{setAddReview(true)}}
                >
                    Write a Review
                </button>
            </div>
        </div>
    </div>
</div>



{
addReview? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="     ">
        {/*content*/}
        <div className=" border-0 relative flex flex-col w-full bg-none outline-none focus:outline-none">
          {/*header*/}


         
          {/*body*/}
       

          <section class=" bg-gray-100/50">
    <div class="container  mx-auto shadow-md ">
        <div class="p-4 border-t-2 border-indigo-400 rounded-lg bg-gray-100/5 ">
            <div class="max-w-sm mx-auto md:w-full md:mx-0">
                <div class="inline-flex items-center space-x-4">
                   
                    <h1 class="text-gray-800">
                        Add Review
                    </h1>
                </div>
            </div>
            <div className='absolute top-0 right-0 text-2xl p-2'>

            <svg class="h-8 w-8 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
            onClick={()=>{setAddReview(false)}}
            >  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="18" y1="6" x2="6" y2="18" />  <line x1="6" y1="6" x2="18" y2="18" /></svg>
            </div>
        </div>
        <div class="space-y-6 bg-white">
            <div class="items-center w-full p-4 space-y-4 text-gray-500 md:inline-flex md:space-y-0">
                <h2 class="max-w-sm mx-auto md:w-1/3">
                    Title
                </h2>
                <div class="max-w-sm mx-auto md:w-2/3">
                    <div class=" relative ">
                        <input type="text" id="user-info-email" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Title"
                        value={title}
                        onChange={(e)=>setTitle(e.target.value)}
                        />
                        </div>
                    </div>


          
                </div>

                <div className="flex justify-center items-center ">
  <div className="flex items-center object-center space-x-1">
    {[...Array(5)].map((_, index) => (
      <button
        key={index}
        type="button"
        className={`text-2xl ${
          index < (hoverRating || rating) ? "text-yellow-500" : "text-gray-300"
        } transition duration-200 hover:text-yellow-400`}
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={handleMouseLeave}
        onClick={() => handleClick(index)}
      >
        ★
      </button>
    ))}
    <span className="ml-2 text-sm text-gray-600">
      {rating > 0 ? `${rating}/${5}` : "Rate"}
    </span>
  </div>
</div>

       
                <hr/>
              
                <div class="items-center w-full p-4 space-y-4 text-gray-500 md:inline-flex md:space-y-2">
                    {/* <h2 class="max-w-sm mx-auto md:w-1/3">
                         info
                    </h2> */}
                    <div class=" space-y-5 md:w-full">

                    <div className='grid grid-cols-2'>

                        <div>
                                <input type="text" id="user-info-name" class=" p-2 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Name"
                                value={name}
                                onChange={(e)=>setName(e.target.value)}
                                />
                              
                            </div>
                            <div>
                                    <input type="text" id="user-info-phone" class="p-2 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Email"
                                    
                                    value={email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                    />
                                </div>

                                </div>
                                <div class=" relative ">
                                    <textarea type="text" id="user-info-password" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Description"
                                        value={description}
                                        onChange={(e)=>setDescription(e.target.value)}
                                    />
                                </div>
                                
                            
                            </div>
                        </div>
                       
                            <hr/>
                            <div class="w-full px-4 pb-4 ml-auto text-gray-500 flex md:w-1/3">
                                <button type="submit" class="md:hidden py-2 px-6 m-2  bg-red-600 hover:bg-red-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                onClick={()=>{setAddReview(false)}}
                                >
                                    Cancle
                                </button>
                                <button  class=" py-2 px-4 m-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                onClick={()=>{postReview()}}
                                >
                                    Post
                                </button>
                            </div>
                        </div>
                    </div>
                </section>



{/* <label class="text-gray-700 font-bold text-2xl p-4" >
    Address
    </label>

    <select id="animals" class=" text-md block p-6 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" name="animals"
          
          value={address}
          onChange={(e)=>setAddress(e.target.value)}
          >
        <option value="">
            Select Address
        </option>

                <option 
                >
                USA
            </option>
        
      
       
    </select>
    <div className="flex -mx-3">
              <div className="w-full px-3 mb-5">
                <label htmlFor className="text-md text-gray-600 font-semibold px-1">Name</label>
                <div className="flex">
                  <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg" /></div>
                  <input type="email" required 
                  className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
                  placeholder="Name"
                  value={name}
                  onChange={(e)=>setName(e.target.value)}
                  />
                </div>
              </div>
            </div>


        
          

            <div className="flex -mx-3">
              <div className="w-full px-3 mb-5">
                <label htmlFor className="text-md text-gray-600 font-semibold px-1">Email</label>
                <div className="flex">
                  <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg" /></div>
                  <input type="email" required 
                  className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
                  placeholder="youremail@example.com"
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="flex -mx-3">
              <div className="w-full px-3 mb-5">
                <label htmlFor className="text-md text-gray-600 font-semibold px-1">Phone</label>
                <div className="flex">
                  <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg" /></div>
                  <input type="email" required 
                  className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
                  placeholder="youremail@example.com"
                  value={phone}
                  onChange={(e)=>setPhone(e.target.value)}
                  />
                </div>
              </div>
            </div>
          


          <div class="py-5 rounded-t sm:px-6">
    <div class="overflow-auto bg-white shadow  sm:rounded-md">
        <ul class="divide-y divide-gray-200">
            <li>
                <a class="block hover:bg-gray-50 ">
                    <div class="px-4 py-4 sm:px-6">
                        <div class="flex items-center justify-between">
                            <p class="text-gray-700 text-md  md:truncate">
                                Total Amount
                            </p>
                           
                        </div>
                        <div class="mt-2 sm:flex sm:justify-between">
                            <div class="sm:flex">
                                <p class="flex items-center font-light text-gray-500 text-md ">
                                    Nu. {total}
                                </p>
                            </div>
                        </div>
                    </div>
                </a>
            </li>
            <li>
                <a class="block hover:bg-gray-50 ">
                    <div class="px-4 py-4 sm:px-6">
                        <div class="flex items-center justify-between">
                            <p class="text-gray-700 text-md  md:truncate">
                                Delivery
                            </p>
                          
                        </div>
                        <div class="mt-2 sm:flex sm:justify-between">
                            <div class="sm:flex">
                                <p class="flex items-center font-light text-gray-500 text-md ">
                                    Nu. 250
                                </p>
                            </div>
                        </div>
                    </div>
                </a>
            </li>
            <li>
                <a class="block hover:bg-gray-50 ">
                    <div class="px-4 py-4 sm:px-6">
                        <div class="flex items-center justify-between">
                            <p class="text-gray-700 text-md  md:truncate">
                               Grand Total after Delivery charges and taxes  
                            </p>
                           
                        </div>
                        <div class="mt-2 sm:flex sm:justify-between">
                            <div class="sm:flex">
                                <p class="flex items-center font-light text-gray-500 text-md ">
                                    Nu. {total+250}
                                </p>
                            </div>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
        <div class="w-full p-4 mx-auto md:w-1/2">
            <button type="button" class="py-2 px-4  bg-red-500 hover:bg-red-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            
            onClick={()=>{

                postOrder(data)
                
              
              }}
            >
                Check Out
            </button>


        </div>
    </div>
</div> */}




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}

           
                    <Footer></Footer>
        </>
    );
}

export default SingleProduct;
// import React, { useContext, useState, useEffect } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import Header from './Header';
// import Footer from './Footer';
// import { UserContex } from '../App';

// const SingleProduct = () => {
//     const { state, dispatch } = useContext(UserContex);
//     const history = useNavigate();
//     const [size, setSize] = useState("");
//     const [data, setData] = useState({});
//     const [cart, setCart] = useState([]);
//     const [cartid, setCartId] = useState([]);
//     const [url, setUrl] = useState([]);
//     const [activeIndex, setActiveIndex] = useState(0); // For carousel active image
//     const { productid } = useParams();

//     useEffect(() => {
//         const storedCart = JSON.parse(localStorage.getItem('cart'));
//         const storedCartIds = JSON.parse(localStorage.getItem('cartid'));

//         if (storedCart) setCart(storedCart);
//         if (storedCartIds) setCartId(storedCartIds);

//         fetch(`/getSingleConsumerStock/${productid}`, {
//             headers: {
//                 Authorization: `Bearer ${localStorage.getItem("jwt")}`
//             }
//         })
//             .then(res => res.json())
//             .then(result => {
//                 setData(result.stock);
//                 setUrl(result.stock.pic);
//             });
//     }, [productid]);

//     const addToCart = (item) => {
//         const newCartItem = { product: item, qty: 1, size };
//         const updatedCart = [...cart, newCartItem];
//         const updatedCartIds = [...cartid, item._id];

//         setCart(updatedCart);
//         setCartId(updatedCartIds);

//         localStorage.setItem("cart", JSON.stringify(updatedCart));
//         localStorage.setItem("cartid", JSON.stringify(updatedCartIds));
//     };

//     // Custom Carousel Handler
//     const handleThumbnailClick = (index) => {
//         setActiveIndex(index);
//     };

//     return (
//         <>
//             <Header />

//             {data && data.hasOwnProperty("pic") ? (
//                 <div className="relative max-w-screen-xl p-4 px-4 mx-auto bg-white sm:px-6 lg:px-8 mt-32">
//                     <div className="relative">
//                         <div className="lg:grid lg:grid-cols-12  lg:items-center">
//                             {/* Thumbnails */}
//                             <div className="lg:col-span-1 flex lg:flex-col space-y-4 lg:space-y-4 space-x-4 lg:space-x-0 overflow-x-auto lg:overflow-visible">
//                                 {url.map((pic, index) => (
//                                     <img
//                                         key={index}
//                                         src={pic}
//                                         alt={`Thumbnail ${index}`}
//                                         className={`cursor-pointer rounded-lg border-2 ${
//                                             activeIndex === index ? "border-blue-600" : "border-gray-300"
//                                         } object-cover h-20 w-20`}
//                                         onClick={() => handleThumbnailClick(index)}
//                                     />
//                                 ))}
//                             </div>

//                             {/* Main Image */}
//                             <div className="lg:col-span-7">
//                                 <img
//                                     src={url[activeIndex]}
//                                     alt={`Main Slide ${activeIndex}`}
//                                     className="rounded-lg object-contain h-96 w-full"
//                                 />
//                             </div>

//                             {/* Product Details */}
//                             <div className="ml-auto lg:col-span-4 lg:max-w-2xl mt-8">
//                                 <p className="text-base font-semibold leading-6 text-blue-500 uppercase">
//                                     Description
//                                 </p>
//                                 <h4 className="mt-2 text-2xl font-extrabold leading-8 text-gray-900 sm:text-3xl sm:leading-9">
//                                     {data.name}
//                                 </h4>
//                                 <p className="mt-4 text-lg leading-6 text-gray-500">
//                                     {data.description}
//                                 </p>
//                                 <ul className="gap-6 mt-8 md:grid md:grid-cols-2 mb-6">
//                                     <li className="mt-6 lg:mt-0">
//                                         <div className="flex">
//                                             <span className="text-base font-medium leading-6 text-gray-500">
//                                                 $ {data.rate}
//                                             </span>
//                                         </div>
//                                     </li>
//                                 </ul>

//                                 {/* Size Selector */}
//                                 <div className="flex mt-4 mb-6 text-gray-700">
//                                     <div className="flex space-x-2">
//                                         {['xs', 's', 'm', 'l', 'xl'].map(sizeOption => (
//                                             <label key={sizeOption} className="text-center">
//                                                 <input
//                                                     type="radio"
//                                                     className="w-6 h-6"
//                                                     name="size"
//                                                     value={sizeOption}
//                                                     onClick={() => setSize(sizeOption)}
//                                                 />
//                                                 {sizeOption.toUpperCase()}
//                                             </label>
//                                         ))}
//                                     </div>
//                                 </div>

//                                 {/* Add to Cart Button */}
//                                 {!cartid.includes(productid) ? (
//                                     <button
//                                         type="button"
//                                         className="py-2 px-4 flex justify-center items-center bg-blue-600 hover:bg-blue-700 text-white w-full rounded-lg mt-4"
//                                         onClick={() => addToCart(data)}
//                                     >
//                                         <svg
//                                             className="h-8 w-8 mr-2 text-white"
//                                             viewBox="0 0 24 24"
//                                             fill="none"
//                                             stroke="currentColor"
//                                             strokeWidth="2"
//                                             strokeLinecap="round"
//                                             strokeLinejoin="round"
//                                         >
//                                             <circle cx="12" cy="12" r="10" />
//                                             <line x1="12" y1="8" x2="12" y2="16" />
//                                             <line x1="8" y1="12" x2="16" y2="12" />
//                                         </svg>
//                                         Add to Cart
//                                     </button>
//                                 ) : (
//                                     <span className="px-4 py-2 mt-6 text-base rounded-full text-green-600 bg-green-200">
//                                         In Cart
//                                     </span>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             ) : (
//                 <p>Loading...</p>
//             )}

//             <Footer />
//         </>
//     );
// };

// export default SingleProduct;
