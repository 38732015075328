import React, {useContext, useRef, useEffect, useState} from 'react'
import {useParams,Link, useNavigate} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import Select from "react-select";
import { Column } from '@ant-design/plots';
import { Document, Page, Text, View, StyleSheet , PDFViewer,PDFDownloadLink,Image} from '@react-pdf/renderer';

import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

import NavBar from './nav';

import {UserContex} from '../App'

const Report = ()=>{



    
  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])
  const  [creditData, setCreditData]= useState([])
  const  [TotalExpense, setTotalExpense]= useState()
  
  const [search,setSearch] = useState("")

  const [addCredit,setAddCredit]= useState(false)

  const[name,setName]= useState("")
  const[description,setDescription]= useState("")
  const[amount,setAmount]= useState()
  const[jrnl,setJrnl]= useState()

  const fileName = 'Bill'
  const [salesArray,setSalesArray] = useState([])
  const [PartneSsalesArray,setPartnerSalesArray] = useState([])
  const [creditArray,setCreditArray] = useState([])


  const  [ExpenseData, setExpenseData]= useState([])
  const  [ExpenseDataToday, setExpenseDataToday]= useState([])
  const  [employeeData, setEmployeeData]= useState([])
  const  [sawmillSalesData, setSawmillSalesData]= useState([])
  const  [sawmillSalesDataToday, setSawmillSalesDataToday]= useState([])
  const  [furnitureSalesDataToday, setFurnitureSalesDataToday]= useState([])
  const  [furnitureSalesData, setFurnitureSalesData]= useState([])
  const  [furnitureCreditsData, setFurnitureCreditsData]= useState([])
  const  [sawmillCreditsData, setSawmillCreditsData]= useState([])
  const  [date, setDate]= useState(new Date)
  const  [month, setMonth]= useState(new Date)
  const  [expenseToday, setexpenseToday]= useState(0)
  const  [sawmillSaleToday, setSawmillSaleToday]= useState(0)
  const  [furnitureSaleToday, setFurnitureSaleToday]= useState(0)
  const  [furnitureCreditToday, setFurnitureCreditToday]= useState(0)
  const  [advanceToday, setAdvanceToday]= useState(0)
  const  [sawmillCreditToday, setSawmillCreditToday]= useState(0)
  const  [fuleExpense, setFuelExpense]= useState(0)
  const [monthSalesData,setMonthSalesData] = useState([])  
  const [monthSawmillSalesData,setMonthSawmillSalesData] = useState([])  
  const [monthAdvanceData,setMonthAdvanceData] = useState([])  
  const [monthCreditsData,setMonthCreditsData] = useState([])  
  const [monthSawmillCreditsData,setMonthSawmillCreditsData] = useState([])  
  const [partnerSales,setPartnerSales] = useState([])
  const [partnerSalescount,setPartnerSalesCount] = useState([])
  const [othersexpense,setOthersExpense] = useState(0)
  const [rawmaterialexpense,setRawmaterialExpense] = useState(0)
  const [paymentexpense,setPaymentExpense] = useState(0)
  const [entertainmentexpense,setEntertainmentExpense] = useState(0)


  const [exp1,setExp1] = useState(0)
  const [exp2,setExp2] = useState(0)
  const [exp3,setExp3] = useState(0)
  const [exp4,setExp4] = useState(0)
  const [exp5,setExp5] = useState(0)
  const [exp6,setExp6] = useState(0)

  const [bagsales, setBagSales] = useState(0)
  const [coatsales, setCoatSales] = useState(0)
  const [homedecoresales, setHomedecoreSales] = useState(0)

  const [bagcredits, setBagCredits] = useState(0)
  const [coatcredits, setCoatCredits] = useState(0)
  const [homedecorecredits, setHomedecoreCredits] = useState(0)


  const [showCalender,setShowCalender] = useState(false)
  const[progress,setProgress]= useState(true)

// console.log(data)
// console.log("SALE",salesArray.length)

// console.log("DATE",date)


  useEffect(  ()=>{

   
    fetch(`/getExpensesreport`,{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{

    //   setData(result.expenses)

      const chartData = Object.entries(result.expenses).reduce((acc, [key, value]) => {

        
        acc.data.push({key:new Date(2023, key ).toLocaleString('default', { month: 'long' }), data:value.reduce((sum, item) => sum + item.amount, 0)});

      
        return acc;
      }, { data:[] });




      const chartDataSalse = Object.entries(result.sales).reduce((acc, [key, value]) => {


        // console.log(value[0].soldItem)
        
        acc.data.push({key:new Date(2023, key ).toLocaleString('default', { month: 'long' }), data:
        
        value.map(item=>{

          // console.log("VALue",item.soldItem)
        const dd = item.soldItem.reduce((sum,products)=>sum+item.amount,0)

            // console.log(key,dd)

            setSalesArray(old=>[...old,{key:new Date(2023, key ).toLocaleString('default', { month: 'long' }),data:dd}])
            // salesArray.push({key:key,data:dd})

        })});

      
        return acc;
      }, { data:[] });


      const chartDataPartnerSalse = Object.entries(result.partnersales).reduce((acc, [key, value]) => {


        // console.log(value[0].soldItem)
        
        acc.data.push({key:new Date(2023, key ).toLocaleString('default', { month: 'long' }), data:
        
        value.map(item=>{

          // console.log("VALue",item.soldItem)
        const dd = item.soldItem.reduce((sum,products)=>sum+item.amount,0)

            // console.log(key,dd)

            setPartnerSalesArray(old=>[...old,{key:new Date(2023, key ).toLocaleString('default', { month: 'long' }),data:dd}])
            // salesArray.push({key:key,data:dd})

        })});

      
        return acc;
      }, { data:[] });


      const chartDataCredit = Object.entries(result.credits).reduce((acc, [key, value]) => {


        // console.log(value[0].soldItem)
        
        acc.data.push({key:new Date(2023, key ).toLocaleString('default', { month: 'long' }), data:
        
        value.map(item=>{

          // console.log("VALue",item.soldItem)
        const dd = item.creditItem.reduce((sum,products)=>sum+item.amount,0)

            // console.log(key,dd)

            setCreditArray(old=>[...old,{key:new Date(2023, key ).toLocaleString('default', { month: 'long' }),data:dd}])
            // salesArray.push({key:key,data:dd})

        })});

      
        return acc;
      }, { data:[] });




      setData(chartData.data)

     


    //   result.expenses.groupBy(result.expenses, ({date})=> new Date(date).getMonth());


    //   setTotalExpense(result.sumExpense)
      
  })

   



  },[])




  
  
  const configExpense = {
    data,
    xField: 'key',
    yField: 'data',
    label: {
      // 可手动配置 label 数据标签位置
      // position: 'middle',
      // 'top', 'bottom', 'middle',
      // 配置样式
      style: {
        fill: '#FFFFFF',
        opacity: 0.9,
      },
    },
    color:"#F5222D",
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    
    meta: {
      type: {
        alias: '类别',
      },
      sales: {
        alias: '销售额',
      },
    },
  };
    





  const configSales = {
    data:salesArray,
    xField: 'key',
    yField: 'data',
    label: {
      // 可手动配置 label 数据标签位置
      // position: 'middle',
      // 'top', 'bottom', 'middle',
      // 配置样式
      style: {
        fill: '#FFFFFF',
        opacity: 0.9,
      },
    },
    color:"#389e0d",

    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: '类别',
      },
      sales: {
        alias: '销售额',
      },
    },
  };
  const configPartnerSales = {
    data:PartneSsalesArray,
    xField: 'key',
    yField: 'data',
    label: {
      // 可手动配置 label 数据标签位置
      // position: 'middle',
      // 'top', 'bottom', 'middle',
      // 配置样式
      style: {
        fill: '#FFFFFF',
        opacity: 0.9,
      },
    },
    color:"#389e0d",

    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: '类别',
      },
      sales: {
        alias: '销售额',
      },
    },
  };


  const configCredit = {
    data:creditArray,
    xField: 'key',
    yField: 'data',
    label: {
      // 可手动配置 label 数据标签位置
      // position: 'middle',
      // 'top', 'bottom', 'middle',
      // 配置样式
      style: {
        fill: '#FFFFFF',
        opacity: 0.9      },
    },
    color:"#fadb14",
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: '类别',
      },
      sales: {
        alias: '销售额',
      },
    },
  };
    

  useEffect(  ()=>{

    // stocks,sales,expense,credits,employee,sawmillsale,sawmillcredits
    fetch(`/detailedreport `,{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{

    setExpenseData(result.expense)
    // setSawmillSalesData(result.sawmillsale)
    setFurnitureSalesData(result.sales)
    setFurnitureCreditsData(result.credits)
    // setSawmillCreditsData(result.sawmillcredits)
    setEmployeeData(result.employee)
    setPartnerSales(result.partnersale)

    var dayexpense = 0
    var production_cost = 0
    var communications_marketing = 0
    var boutique_equipment_tools = 0
    var csr = 0
    var human_resources = 0
    var administrative_expenses = 0
    result.expense.map(expense=>{
        const nd = new Date(expense.date)
        if( nd.getDate()==date.getDate() && nd.getMonth()==date.getMonth()&& nd.getFullYear()==date.getFullYear()){
            // console.log("True")
            setExpenseDataToday(older=>[...older,expense])

            dayexpense+=expense.amount

            if(expense.description =="production_cost") production_cost+=expense.amount 
            if(expense.description =="communications_marketing") communications_marketing+=expense.amount 
            if(expense.description =="boutique_equipment_tools") boutique_equipment_tools+=expense.amount 
            if(expense.description =="csr") csr+=expense.amount 
            if(expense.description =="human_resources") human_resources+=expense.amount 
            if(expense.description =="administrative_expenses") administrative_expenses+=expense.amount 
        }
    
    })

    var dayadvance = 0
    result.employee.map(employee=>{
        employee.advance.map(item=>{
            const nd = new Date(item.date)
        if( nd.getDate()==date.getDate() && nd.getMonth()==date.getMonth()&& nd.getFullYear()==date.getFullYear()){
            dayadvance+=item.amount

        }

        })

    })






  

    var dayfurnituresale = 0;
    var coats =0
    var bags =0
    var homedecore =0
    result.sales.map(sale=>{
        const nd = new Date(sale.date)
        if( nd.getDate()==date.getDate() && nd.getMonth()==date.getMonth()&& nd.getFullYear()==date.getFullYear()){
            setFurnitureSalesDataToday(older=>[...older,sale])
            console.log("SALES", sale)
            dayfurnituresale+=(sale.amount)


            sale.soldItem.map(item=>{

              if(item.product.category == "Coats") coats+=1
              if(item.product.category == "Bags") bags+=1
              if(item.product.category == "Home Decore") homedecore+=1
            })

        //         sale.soldItem.map(item=>{
        // dayfurnituresale+=(item.rate*item.qty)
        // // console.log(item.product)

        //     })

        }
    
    })



    var dayfurniturecredit = 0;

    var bagcred = 0
    var coatcred = 0
    var homedecorecred = 0
    result.credits.map(sale=>{
        const nd = new Date(sale.date)
        if( nd.getDate()==date.getDate() && nd.getMonth()==date.getMonth()&& nd.getFullYear()==date.getFullYear()){

            dayfurniturecredit+=sale.amount

        //         sale.creditItem.map(item=>{
        // dayfurnituresale+=(item.rate*item.qty)
        // // console.log(item.product)

        //     })

        sale.CreditItem.map(item=>{

          if(item.product.category == "Coats") coatcred+=1
          if(item.product.category == "Bags") bagcred+=1
          if(item.product.category == "Home Decore") homedecorecred+=1
        })


        }
    
    })


    const counts = partnerSales.reduce((acc, sale) => {
      sale.soldItem.forEach(item => {
        const category = item.product.category;
        const existingCategory = acc.find(c => c.category === category);
        if (existingCategory) {
          existingCategory.count += 1;
        } else {
          acc.push({ category, count: 1 });
        }
      });
      return acc;
    }, []);


    console.log("PARTNER SALE", counts)

    setPartnerSalesCount(counts)


    setFurnitureCreditToday(dayfurniturecredit)
      

    setFurnitureSaleToday(dayfurnituresale)

    setCoatSales(coats)
    setBagSales(bags)
    setHomedecoreSales(homedecore)

    setexpenseToday(dayexpense)
    setAdvanceToday(dayadvance)


  setExp1(production_cost)
  setExp2(communications_marketing)
  setExp3(boutique_equipment_tools)
  setExp4(csr)
  setExp5(human_resources)
  setExp6(administrative_expenses)

    setBagCredits(bagcred)
    setCoatCredits(coatcred)
    setHomedecoreCredits(homedecorecred)

      setProgress(false)
  })
   
  },[])



 
 
  
    
  const searchdate=(datequery)=>{

    const query = datequery.date
    // console.log("Query",query)

    setSawmillSalesDataToday([])
    setExpenseDataToday([])
    setFurnitureSalesDataToday([])
    // setFurnitureCreditsDataToday([])
    var dayexpense = 0
    var production_cost = 0
    var communications_marketing = 0
    var boutique_equipment_tools = 0
    var csr = 0
    var human_resources = 0
    var administrative_expenses = 0
    ExpenseData.map(expense=>{
        const nd = new Date(expense.date)
        if( nd.getDate()==query.getDate() && nd.getMonth()==query.getMonth()&& nd.getFullYear()==query.getFullYear()){
            // console.log("True")
            setExpenseDataToday(older=>[...older,expense])

            dayexpense+=expense.amount

            if(expense.description =="production_cost") production_cost+=expense.amount 
            if(expense.description =="communications_marketing") communications_marketing+=expense.amount 
            if(expense.description =="boutique_equipment_tools") boutique_equipment_tools+=expense.amount 
            if(expense.description =="csr") csr+=expense.amount 
            if(expense.description =="human_resources") human_resources+=expense.amount 
            if(expense.description =="administrative_expenses") administrative_expenses+=expense.amount 

          }
    
    })



   


    var dayadvance = 0
    employeeData.map(employee=>{
        employee.advance.map(item=>{
            const nd = new Date(item.date)
            console.log("DATE",nd)
        if( nd.getDate()==query.getDate() && nd.getMonth()==query.getMonth()&& nd.getFullYear()==query.getFullYear()){
            dayadvance+=item.amount

        }

        })

    })




    var dayfurnituresale = 0;
    var coats =0
    var bags =0
    var homedecore =0
    furnitureSalesData.map(sale=>{
        const nd = new Date(sale.date)
        if( nd.getDate()==query.getDate() && nd.getMonth()==query.getMonth()&& nd.getFullYear()==query.getFullYear()){
           console.log("SALE",sale)
            setFurnitureSalesDataToday(older=>[...older,sale])

            dayfurnituresale+=(sale.amount)

            sale.soldItem.map(item=>{

              if(item.product.category == "Coats") coats+=1
              if(item.product.category == "Bags") bags+=1
              if(item.product.category == "Home Decore") homedecore+=1
            })

 

        }
    
    })




  const counts = partnerSales.filter(sale => {
    const saleDate = new Date(sale.date);
    return (
      saleDate.getDate() === query.getDate() &&
      saleDate.getMonth() === query.getMonth() &&
      saleDate.getFullYear() === query.getFullYear()
    );
  })
  .reduce((acc, sale) => {
    sale.soldItem.forEach(item => {
      const category = item.product.category;
      const existingCategory = acc.find(c => c.category === category);
      if (existingCategory) {
        existingCategory.count += 1;
      } else {
        acc.push({ category, count: 1 });
      }
    });
    return acc;
  }, []);
    


  console.log("Counts",counts)

  setPartnerSalesCount(counts)
    var dayfurniturecredit = 0;
    var bagcred = 0
    var coatcred = 0
    var homedecorecred = 0
    furnitureCreditsData.map(sale=>{
        const nd = new Date(sale.date)
        if( nd.getDate()==query.getDate() && nd.getMonth()==query.getMonth()&& nd.getFullYear()==query.getFullYear()){

            dayfurniturecredit+=sale.amount

            sale.CreditItem.map(item=>{

              if(item.product.category == "Coats") coatcred+=1
              if(item.product.category == "Bags") bagcred+=1
              if(item.product.category == "Home Decore") homedecorecred+=1
            })
    

        }
    
    })


  


    setFurnitureCreditToday(dayfurniturecredit)
      

    setFurnitureSaleToday(dayfurnituresale)

    setexpenseToday(dayexpense)
    setAdvanceToday(dayadvance)

    setExp1(production_cost)
  setExp2(communications_marketing)
  setExp3(boutique_equipment_tools)
  setExp4(csr)
  setExp5(human_resources)
  setExp6(administrative_expenses)

   

    setCoatSales(coats)
    setBagSales(bags)
    setHomedecoreSales(homedecore)

    setBagCredits(bagcred)
    setCoatCredits(coatcred)
    setHomedecoreCredits(homedecorecred)
  
  }
  
  const searchMonth=(datess)=>{

    const query = new Date(datess)
    setSawmillSalesDataToday([])
    setExpenseDataToday([])

    setFurnitureSalesDataToday([])
    // setFurnitureCreditsDataToday([])
    var dayexpense = 0
    var production_cost = 0
    var communications_marketing = 0
    var boutique_equipment_tools = 0
    var csr = 0
    var human_resources = 0
    var administrative_expenses = 0
    ExpenseData.map(expense=>{
        const nd = new Date(expense.date)
        if(  nd.getMonth()==query.getMonth()&& nd.getFullYear()==query.getFullYear()){
            // console.log("True")
            setExpenseDataToday(older=>[...older,expense])

            dayexpense+=expense.amount

            if(expense.description =="production_cost") production_cost+=expense.amount 
            if(expense.description =="communications_marketing") communications_marketing+=expense.amount 
            if(expense.description =="boutique_equipment_tools") boutique_equipment_tools+=expense.amount 
            if(expense.description =="csr") csr+=expense.amount 
            if(expense.description =="human_resources") human_resources+=expense.amount 
            if(expense.description =="administrative_expenses") administrative_expenses+=expense.amount 
        }
    
    })

    var dayadvance = 0
    employeeData.map(employee=>{
        employee.advance.map(item=>{
            const nd = new Date(item.date)
        if(  nd.getMonth()==query.getMonth()&& nd.getFullYear()==query.getFullYear()){
            dayadvance+=item.amount

        }

        })

    })



   


    var dayfurnituresale = 0;
    var coats =0
    var bags =0
    var homedecore =0
    furnitureSalesData.map(sale=>{
        const nd = new Date(sale.date)
        if(  nd.getMonth()==query.getMonth()&& nd.getFullYear()==query.getFullYear()){
            setFurnitureSalesDataToday(older=>[...older,sale])

            dayfurnituresale+=(sale.amount)

            sale.soldItem.map(item=>{

              if(item.product.category == "Coats") coats+=1
              if(item.product.category == "Bags") bags+=1
              if(item.product.category == "Home Decore") homedecore+=1
            })

 

        }
    
    })



    const counts = partnerSales.filter(sale => {
      const saleDate = new Date(sale.date);
      return (
        saleDate.getMonth() === query.getMonth() &&
        saleDate.getFullYear() === query.getFullYear()
      );
    })
    .reduce((acc, sale) => {
      sale.soldItem.forEach(item => {
        const category = item.product.category;
        const existingCategory = acc.find(c => c.category === category);
        if (existingCategory) {
          existingCategory.count += 1;
        } else {
          acc.push({ category, count: 1 });
        }
      });
      return acc;
    }, []);
      
  
  
    console.log("Counts",counts)
  
    setPartnerSalesCount(counts)


    var dayfurniturecredit = 0;
    var bagcred = 0
    var coatcred = 0
    var homedecorecred = 0
    furnitureCreditsData.map(sale=>{
        const nd = new Date(sale.date)
        if( nd.getMonth()==query.getMonth()&& nd.getFullYear()==query.getFullYear()){

            setMonthCreditsData(older=>[...older,sale])

            dayfurniturecredit+=sale.amount

            sale.CreditItem.map(item=>{

              if(item.product.category == "Coats") coatcred+=1
              if(item.product.category == "Bags") bagcred+=1
              if(item.product.category == "Home Decore") homedecorecred+=1
            })
    

     

        }
    
    })


  


    setFurnitureCreditToday(dayfurniturecredit)
      

    setFurnitureSaleToday(dayfurnituresale)

    setexpenseToday(dayexpense)
    setAdvanceToday(dayadvance)

    setExp1(production_cost)
  setExp2(communications_marketing)
  setExp3(boutique_equipment_tools)
  setExp4(csr)
  setExp5(human_resources)
  setExp6(administrative_expenses)

    setCoatSales(coats)
    setBagSales(bags)
    setHomedecoreSales(homedecore)


    setBagCredits(bagcred)
    setCoatCredits(coatcred)
    setHomedecoreCredits(homedecorecred)

   setAdvanceToday(dayadvance)
  
  }
  
  

  
const PrintSales = (da) => (

    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
      <Image style={styles.logo} src="/logo.png"
   />
  
        <Text style={styles.address}>Mountain Crafts</Text>
        <Text style={{fontSize:10, marginBottom: 3}}>Thimphu</Text>
        <Text style={{fontSize:10,marginBottom: 3}}>TPN: GAB66324</Text>
        <Text style={{fontSize:10,marginBottom: 3}}>Contact:</Text>
  
      </View>
  
      <View style={styles.invoiceDetails}>
        <View style={styles.itemList}>
          <View style={styles.itemRow}>
            <Text style={styles.label}>Name</Text>
            <Text style={styles.label}>Date</Text>
            <Text style={styles.label}>Amount</Text>
          </View>
          {furnitureSalesDataToday.length>0? furnitureSalesDataToday.map((item, index) => (
            <View key={index} style={styles.itemRow}>
              <Text style={styles.itemDescription}>{item.customer.name}</Text>
              <Text style={styles.itemDescription}>{item.date.substring(0,10)}</Text>
              {/* <Text style={styles.itemDescription}>{item.qty}</Text> */}
              <Text style={styles.itemDescription}>{item.amount}</Text>
            </View>
          )):<></>}
        </View>
       
        <View style={styles.totalRow}>
          <Text style={styles.totalLabel}>Total: {furnitureSaleToday}</Text>
          {/* <Text style={styles.totalValue}>{invoice.total}</Text> */}
        </View>
      </View>
    </Page>
  </Document>
    
  );
  
  
const PrintFurnitureCredit = (da) => (

    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
      <Image style={styles.logo} src="/logo.png"
   />
  
       <Text style={styles.address}>Mountain Crafts</Text>
        <Text style={{fontSize:10, marginBottom: 3}}>Thimphu</Text>
        <Text style={{fontSize:10,marginBottom: 3}}>TPN: GAB66324</Text>
        <Text style={{fontSize:10,marginBottom: 3}}>Contact:</Text>
      </View>
  
      <View style={styles.invoiceDetails}>
        <View style={styles.itemList}>
          <View style={styles.itemRow}>
            <Text style={styles.label}>Name</Text>
            <Text style={styles.label}>Date</Text>
            <Text style={styles.label}>Amount</Text>
          </View>
          {monthCreditsData.length>0? monthCreditsData.map((item, index) => (
            <View key={index} style={styles.itemRow}>
              <Text style={styles.itemDescription}>{item.customer.name}</Text>
              <Text style={styles.itemDescription}>{item.date.substring(0,10)}</Text>
              {/* <Text style={styles.itemDescription}>{item.qty}</Text> */}
              <Text style={styles.itemDescription}>{item.amount}</Text>
            </View>
          )):<></>}
        </View>
       
        <View style={styles.totalRow}>
          <Text style={styles.totalLabel}>Total: {furnitureCreditToday}</Text>
          {/* <Text style={styles.totalValue}>{invoice.total}</Text> */}
        </View>
      </View>
    </Page>
  </Document>
    
  );
  
const PrintSawmillSales = (da) => (

    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
      <Image style={styles.logo} src="/logo.png"
   />
  
          <Text style={styles.address}>Mountain Crafts</Text>
        <Text style={{fontSize:10, marginBottom: 3}}>Thimphu</Text>
        <Text style={{fontSize:10,marginBottom: 3}}>TPN: GAB66324</Text>
        <Text style={{fontSize:10,marginBottom: 3}}>Contact:</Text>
      </View>
  
      <View style={styles.invoiceDetails}>
        <View style={styles.itemList}>
          <View style={styles.itemRow}>
            <Text style={styles.label}>Name</Text>
            <Text style={styles.label}>Date</Text>
            <Text style={styles.label}>Amount</Text>
          </View>
          {sawmillSalesDataToday.length>0? sawmillSalesDataToday.map((item, index) => (
            <View key={index} style={styles.itemRow}>
              <Text style={styles.itemDescription}>{item.customer.name}</Text>
              <Text style={styles.itemDescription}>{item.date.substring(0,10)}</Text>
              {/* <Text style={styles.itemDescription}>{item.qty}</Text> */}
              <Text style={styles.itemDescription}>{item.amount?item.amount:""}</Text>
            </View>
          )):<></>}
        </View>
       
        <View style={styles.totalRow}>
          <Text style={styles.totalLabel}>Total: {sawmillSaleToday}</Text>
          {/* <Text style={styles.totalValue}>{invoice.total}</Text> */}
        </View>
      </View>
    </Page>
  </Document>
    
  );
  
const PrintSawmillCredit = (da) => (

    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
      <Image style={styles.logo} src="/logo.png"
   />
  
  <Text style={styles.address}>Mountain Crafts</Text>
        <Text style={{fontSize:10, marginBottom: 3}}>Thimphu</Text>
        <Text style={{fontSize:10,marginBottom: 3}}>TPN: GAB66324</Text>
        <Text style={{fontSize:10,marginBottom: 3}}>Contact:</Text>
      </View>
  
      <View style={styles.invoiceDetails}>
        <View style={styles.itemList}>
          <View style={styles.itemRow}>
            <Text style={styles.label}>Name</Text>
            <Text style={styles.label}>Date</Text>
            <Text style={styles.label}>Amount</Text>
          </View>
          {monthSawmillCreditsData.length>0? monthSawmillCreditsData.map((item, index) => (
            <View key={index} style={styles.itemRow}>
              <Text style={styles.itemDescription}>{item.customer?item.customer.name:"No Name"}</Text>
              <Text style={styles.itemDescription}>{item.date.substring(0,10)}</Text>
              {/* <Text style={styles.itemDescription}>{item.qty}</Text> */}
              <Text style={styles.itemDescription}>{item.amount}</Text>
            </View>
          )):<></>}
        </View>
       
        <View style={styles.totalRow}>
          <Text style={styles.totalLabel}>Total: {sawmillCreditToday}</Text>
          {/* <Text style={styles.totalValue}>{invoice.total}</Text> */}
        </View>
      </View>
    </Page>
  </Document>
    
  );
  
     
const PrintExpense = (da) => (

    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
      <Image style={styles.logo} src="/logo.png"
   />
  
  <Text style={styles.address}>Mountain Crafts</Text>
        <Text style={{fontSize:10, marginBottom: 3}}>Thimphu</Text>
        <Text style={{fontSize:10,marginBottom: 3}}>TPN: GAB66324</Text>
        <Text style={{fontSize:10,marginBottom: 3}}>Contact:</Text>
      </View>
  
      <View style={styles.invoiceDetails}>
        <View style={styles.itemList}>
          <View style={styles.itemRow}>
            <Text style={styles.label}>Name</Text>
            <Text style={styles.label}>Date</Text>
            <Text style={styles.label}>Amount</Text>
          </View>
          {ExpenseDataToday.length>0? ExpenseDataToday.map((item, index) => (
            <View key={index} style={styles.itemRow}>
              <Text style={styles.itemDescription}>{item.name}</Text>
              <Text style={styles.itemDescription}>{item.date.substring(0,10)}</Text>
              {/* <Text style={styles.itemDescription}>{item.qty}</Text> */}
              <Text style={styles.itemDescription}>{item.amount ?item.amount:""}</Text>
            </View>
          )):<></>}
        </View>
       
        <View style={styles.totalRow}>
          <Text style={styles.totalLabel}>Total: {expenseToday?expenseToday:""}</Text>
          {/* <Text style={styles.totalValue}>{invoice.total}</Text> */}
        </View>
      </View>
    </Page>
  </Document>
    
  );
  
     
  
  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      padding: 30,
    },
    header: {
      marginBottom: 2,
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 10,
    },
    address: {
      fontSize: 12,
      marginBottom: 10,
    },
    invoiceDetails: {
      marginBottom: 20,
    },
    label: {
      fontSize: 15,
      fontWeight: 'bold',
    },
    labelfooter: {
      fontSize: 10,
      fontWeight: 'bold',
      marginBottom: 8,
      marginTop: 8,
  
    },
    value: {
      fontSize: 12,
    },
    itemList: {
      marginTop: 2,
    },
    itemRow: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      marginBottom: 8,
      marginLeft:8
    },
    itemDescription: {
      width: '20%',
      textAlign:'center',
      fontSize:12
  
    },
    itemQuantity: {
      width: '15%',
      textAlign: 'left',
    },
    itemAmount: {
      width: '25%',
      textAlign: 'right',
    },
    totalRow: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: 10,
    },
    totalLabel: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    totalValue: {
      fontSize: 14,
      marginLeft: 10,
    },
    logo: {
      width: 80, 
      height: 80, 
      marginBottom: 10,
      justifyContent: 'center',
      alignItems: 'center',  },
  
  
    horizontalLine: {
      borderTop: 1,
      borderColor: 'black',
      marginVertical: 4,
      width: '100%',
    },
  });
  



return(

    <div>


 <script src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js" defer></script>

 <div  className="flex h-screen bg-white">

 <div className=" "></div>

        <link rel="stylesheet" href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css" />
  






        <div className="flex-1 flex flex-col overflow-hidden ">

            
<NavBar/>




            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
  <div className="container mx-auto px-6 py-8">
    <h3 className="text-gray-400 text-3xl font-medium">Report</h3>

  
    <br/>










    
    <div class="grid lg:grid-cols-5 grid-cols-2 gap-4 justify-items-center mb-4 ">


  
{/* 

<span class={`px-4 py-2 m-2 flex w-48 items-center text-base rounded-full text-blue-500   ${showCalender?"bg-blue-200":"border border-indigo-300 undefined"} cursor-pointer`}
onClick={()=>setShowCalender(!showCalender)}
>
<svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="5" width="16" height="16" rx="2" />  <line x1="16" y1="3" x2="16" y2="7" />  <line x1="8" y1="3" x2="8" y2="7" />  <line x1="4" y1="11" x2="20" y2="11" />  <rect x="8" y="15" width="2" height="2" /></svg>
    Search by Date
</span>
 */}



<div >
    <div class="p-4 bg-white shadow-lg rounded-2xl w-full dark:bg-gray-800 cursor-pointer"
    onClick={()=>setShowCalender(!showCalender)}

    >
    <div class="flex items-center">
        <span class="relative w-4 h-4 p-2 bg-green-500 rounded-full">
            <svg width="20" fill="currentColor" height="20" class="absolute h-2 text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
                </path>
            </svg>
        </span>
        <p class="ml-2 text-gray-700 text-md dark:text-gray-50">
            Search by Date
        </p>
    </div>
    <div class="flex flex-col justify-start">
    <span class={`px-4 py-2 m-2 flex w-full items-center text-base rounded-full text-blue-500   ${showCalender?"bg-blue-200":"border border-indigo-300 undefined"} cursor-pointer`}
onClick={()=>setShowCalender(!showCalender)}
>
<svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="5" width="16" height="16" rx="2" />  <line x1="16" y1="3" x2="16" y2="7" />  <line x1="8" y1="3" x2="8" y2="7" />  <line x1="4" y1="11" x2="20" y2="11" />  <rect x="8" y="15" width="2" height="2" /></svg>
    Search by Date
</span>
      
    </div>
   
</div>
{/* <div class="relative h-2 bg-gray-200 rounded w-32">
            <div class="absolute top-0 left-0 w-full h-2 bg-green-500 rounded">
            </div>
        </div> */}
</div>


<div>
    <div class="p-4 bg-white shadow-lg rounded-2xl w-full dark:bg-gray-800">
    <div class="flex items-center">
        <span class="relative w-4 h-4 p-2 bg-green-500 rounded-full">
            <svg width="20" fill="currentColor" height="20" class="absolute h-2 text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
                </path>
            </svg>
        </span>
        <p class="ml-2 text-gray-700 text-md dark:text-gray-50">
            Search by Month
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="my-4 text-2xl font-bold text-left text-gray-800 dark:text-white">
        <DatePicker 
                dateFormat="MMMM yyyy" // Display only month and year
                showMonthYearPicker

className="rounded-lg w-full
        bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" selected={month} onChange={month => {searchMonth(month);setMonth(month)}} />
        </p>
        {/* <div class="relative h-2 bg-gray-200 rounded w-28">
            <div class="absolute top-0 left-0 w-2/3 h-2 bg-green-500 rounded">
            </div>
        </div> */}
    </div>
</div>
</div>

{/* 

<div className="py-1">
Search by Month

<span class={`px-4 py-2 m-2 flex w-48 items-center text-base rounded-full text-blue-500   border border-indigo-300 undefined cursor-pointer`}
// onClick={()=>setShowCalender(!showCalender)}
>
    
<svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="5" width="16" height="16" rx="2" />  <line x1="16" y1="3" x2="16" y2="7" />  <line x1="8" y1="3" x2="8" y2="7" />  <line x1="4" y1="11" x2="20" y2="11" />  <rect x="8" y="15" width="2" height="2" /></svg>
<DatePicker 
                dateFormat="MMMM yyyy" // Display only month and year
                showMonthYearPicker

className="rounded-lg w-full
        bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" selected={month} onChange={month => {searchMonth(month);setMonth(month)}} />


</span>


            </div> */}


            <div>


</div>

<div>



</div>



</div>








{showCalender?<>
<div className='md:w-1/2 w-full '>
    <FullCalendar
  
  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
  headerToolbar={{
    // left: 'prev,next today',
    // center: 'title',
    // right: 'dayGridMonth',
  }}
  initialView='dayGridMonth'
  editable={true}
  selectable={true}
  selectMirror={true}
  dayMaxEvents={true}
  height={400}
//   width={400}
  // weekends={weekendsVisible}
  // events={data} 
  // select={handleDateSelect}
  // eventContent={renderEventContent}
  //  eventClick={handleEventClick}
  // eventsSet={handleEvents}

  dateClick={(info)=>{

      searchdate(info)

      // console.log("INFOOO",info.dateStr)
  }}
  
/>
</div>




<br/>

</>:<></>}


    <div class="grid grid-cols-1 gap-4 my-4 md:grid-cols-2 lg:grid-cols-3 ">
                    <div class="w-full">
                        <div class="relative w-full px-4 py-6 bg-white shadow-lg dark:bg-gray-700">
                            <p class="text-sm font-semibold text-gray-700 border-b border-gray-200 w-max dark:text-white">
                                Expense
                            </p>
                            <div class="flex items-end my-6 space-x-2">
                               {progress?               <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
:<p class="text-5xl font-bold text-black dark:text-white">
Nu. {expenseToday+advanceToday}
</p>

}
                                
                                <span class="flex items-center text-xl font-bold text-red-500">
                                <svg width="20" fill="currentColor" height="20" class="h-3 transform rotate-180" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                        </path>
                                    </svg>
                                    {/* {(100*(expenseToday+advanceToday)/(sawmillSaleToday+furnitureSaleToday)).toFixed(2)}% */}
                                </span>
                            </div>
                            <div class="dark:text-white">
                                <div class="flex items-center justify-between pb-2 mb-2 text-sm border-b border-gray-200 sm:space-x-12">
                                    <p>
                                    Production Cost
                                    </p>
                                    <div class="flex items-end text-xs">
                                        {exp1}
                                        {/* <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                           
                                           
                                            {(expenseToday*100/(expenseToday+advanceToday))}%
                                            <PDFDownloadLink document={<PrintExpense test={"TESt sf"}/>} fileName="Expense.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading bill...' : <><svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />  <rect x="7" y="13" width="10" height="8" rx="2" /></svg>
         </>
      }
    </PDFDownloadLink>
                                        </span> */}
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                    Communications Marketing
                                    </p>
                                    <div class="flex items-end text-xs">
                                        {exp2}
                                        <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            {(exp2*100)/(expenseToday+advanceToday)}%
                                        </span>
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                    Equipment Tools
                                    </p>
                                    <div class="flex items-end text-xs">
                                        {exp3}
                                        <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            {exp3*100/(expenseToday+advanceToday)}%
                                        </span>
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                    CSR
                                    </p>
                                    <div class="flex items-end text-xs">
                                        {exp4}
                                        <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            {exp4*100/(expenseToday+advanceToday)}%
                                        </span>
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                    HR
                                    </p>
                                    <div class="flex items-end text-xs">
                                        {exp5}
                                        <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            {exp5*100/(expenseToday+advanceToday)}%
                                        </span>
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                    Administrative 
                                    </p>
                                    <div class="flex items-end text-xs">
                                        {exp6}
                                        <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            {exp6*100/(expenseToday+advanceToday)}%
                                        </span>
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                        Advance
                                    </p>
                                    <div class="flex items-end text-xs">
                                        {advanceToday}
                                        <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            {advanceToday*100/(expenseToday+advanceToday)}%
                                        </span>
                                    </div>
                                </div>
                                {/* <div class="flex items-center justify-between space-x-12 text-sm md:space-x-24">
                                    <p>
                                        Others
                                    </p>
                                    <div class="flex items-end text-xs">
                                        {othersexpense}
                                        <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            {othersexpense*100/(expenseToday+advanceToday)}%
                                        </span>
                                    </div>
                                </div> */}
                              

                            </div>
                            <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                        Total Expense
                                    </p>
                                    <div class="flex items-end text-xs">
                                        Nu. {expenseToday}
                                        <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-red-500 transform rotate-180" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            {/* {(furnitureCreditToday*100)/(furnitureCreditToday+sawmillCreditToday)} */}
                                            <PDFDownloadLink document={<PrintExpense test={"TESt sf"}/>} fileName="Expense.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading ...' : <><svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />  <rect x="7" y="13" width="10" height="8" rx="2" /></svg>
         </>
      }
    </PDFDownloadLink>
                                        </span>
                                    </div>
                                </div>
                        </div>
                    </div>




                    <div class="w-full">
                        <div class="relative w-full px-4 py-6 bg-white shadow-lg dark:bg-gray-700">
                            <p class="text-sm font-semibold text-gray-700 border-b border-gray-200 w-max dark:text-white">
                                Sales 
                            </p>
                            <div class="flex items-end my-6 space-x-2">
                            {progress?               
                                            <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>

                                           
                                           : <p class="text-4xl font-bold text-black dark:text-white">
                                    Nu. {(sawmillSaleToday+furnitureSaleToday)}
                                </p> }
                                <span class="flex items-center text-xl font-bold text-green-500">
                                    <svg width="20" fill="currentColor" height="20" class="h-3" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                        </path>
                                    </svg>
                                    {/* {(100-(100*expenseToday/(sawmillSaleToday+furnitureSaleToday))).toFixed(2)}% */}
                                </span>
                            </div>
                            <div class="dark:text-white">
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                        Coats
                                    </p>
                                    <div class="flex items-end text-xs">
                                        Nos.  {coatsales}
                                     
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                        Bags
                                    </p>
                                    <div class="flex items-end text-xs">
                                        Nos.  {bagsales}
                                     
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                        Home Decore
                                    </p>
                                    <div class="flex items-end text-xs">
                                        Nos.  {homedecoresales}
                                     
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                        Total Sale
                                    </p>
                                    <div class="flex items-end text-xs">
                                        Nu. {furnitureSaleToday}
                                        <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            {/* {(furnitureSaleToday*100/(sawmillSaleToday+furnitureSaleToday))} % */}
                                            <PDFDownloadLink document={<PrintSales test={"TESt sf"}/>} fileName="Sales.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading bill...' : <><svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />  <rect x="7" y="13" width="10" height="8" rx="2" /></svg>
         </>
      }
    </PDFDownloadLink>
      
                                        </span>
                                    </div>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                    <div class="w-full">
                        <div class="relative w-full px-4 py-6 bg-white shadow-lg dark:bg-gray-700">
                            <p class="text-sm font-semibold text-gray-700 border-b border-gray-200 w-max dark:text-white">
                                Credit 
                            </p>
                            <div class="flex items-end my-6 space-x-2">
                            {progress?               
                                            <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>

                                           
                                           :<p class="text-5xl font-bold text-black dark:text-white">
                                    Nu. {(furnitureCreditToday+sawmillCreditToday)}
                                </p>}
                                <span class="flex items-center text-xl font-bold text-red-500">
                                    <svg width="20" fill="currentColor" height="20" class="h-3 transform rotate-180" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                        </path>
                                    </svg>
                                
                                </span>
                            </div>
                            <div class="dark:text-white">
                               
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                        Coats 
                                    </p>
                                    <div class="flex items-end text-xs">
                                    {coatcredits}
                                        
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                        Home Decore 
                                    </p>
                                    <div class="flex items-end text-xs">
                                    {homedecorecredits}
                                        
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                        Bags 
                                    </p>
                                    <div class="flex items-end text-xs">
                                    {bagcredits}
                                        
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                        Total Credit
                                    </p>
                                    <div class="flex items-end text-xs">
                                        Nu. {furnitureCreditToday}
                                        <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-red-500 transform rotate-180" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            {/* {(furnitureCreditToday*100)/(furnitureCreditToday+sawmillCreditToday)} */}
                                            <PDFDownloadLink document={<PrintFurnitureCredit test={"TESt sf"}/>} fileName="Credit.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading ...' : <><svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />  <rect x="7" y="13" width="10" height="8" rx="2" /></svg>
         </>
      }
    </PDFDownloadLink>
                                        </span>
                                    </div>
                                </div>

                                
                               
                            </div>
                            
                        </div>

                        
                    </div>
                    <div class="w-full">
                        <div class="relative w-full px-4 py-6 bg-white shadow-lg dark:bg-gray-700">
                            <p class="text-sm font-semibold text-gray-700 border-b border-gray-200 w-max dark:text-white">
                                Partner Sale 
                            </p>
                            <div class="flex items-end my-6 space-x-2">
                            {progress?               
                                            <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>

                                           
                                           :<p class="text-5xl font-bold text-black dark:text-white">
                                    Nu. {(furnitureCreditToday+sawmillCreditToday)}
                                </p>}
                                <span class="flex items-center text-xl font-bold text-red-500">
                                    <svg width="20" fill="currentColor" height="20" class="h-3 transform rotate-180" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                        </path>
                                    </svg>
                                
                                </span>
                            </div>
                            <div class="dark:text-white">

                              {partnerSalescount?partnerSalescount.map(item=>{

                                return(
                                  <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                  <p>
                                      {item.category} 
                                  </p>
                                  <div class="flex items-end text-xs">
                                  {item.count}
                                      
                                  </div>
                              </div>
                                )
                              }
                              ):<></>}
                               
                             
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                        Total Credit
                                    </p>
                                    <div class="flex items-end text-xs">
                                        Nu. {furnitureCreditToday}
                                        <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-red-500 transform rotate-180" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            {/* {(furnitureCreditToday*100)/(furnitureCreditToday+sawmillCreditToday)} */}
                                            <PDFDownloadLink document={<PrintFurnitureCredit test={"TESt sf"}/>} fileName="Credit.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading ...' : <><svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />  <rect x="7" y="13" width="10" height="8" rx="2" /></svg>
         </>
      }
    </PDFDownloadLink>
                                        </span>
                                    </div>
                                </div>

                                
                               
                            </div>
                            
                        </div>

                        
                    </div>
                   
                
                
                </div>

                <div className='flex lg:flex-row flex-col'>
<div className="container mx-auto px-6 py-8">
    <h3 className="text-gray-400 text-3xl font-medium">Expenses</h3>

    <div class="w-full p-4 bg-white shadow-lg rounded-2xl dark:bg-gray-700">
    {data? <Column {...configExpense}/>:<>Loading...</>}

   
  
    <span class="flex items-center px-2 py-1 mt-4 text-xs font-semibold text-yellow-500 bg-yellow-100 rounded-md w-36">
        MONTHLY EXPENSE
    </span>
</div>


   
    



 
   
  </div>


  <div className="container mx-auto px-6 py-8">
    <h3 className="text-gray-400 text-3xl font-medium">Sales</h3>


    <div class="w-full p-4 bg-white shadow-lg rounded-2xl dark:bg-gray-700">
    {salesArray? <Column {...configSales}/>:<>Loading...</>}

   
  
    <span class="flex items-center px-2 py-1 mt-4 text-xs font-semibold text-yellow-500 bg-yellow-100 rounded-md w-36">
        Monthly Sales
    </span>
</div>


   
    
    <br></br>



 
   
  </div>

</div>

  

<div className='flex md:flex-row flex-col'>
<div className="container mx-auto px-6 py-8 ">
    <h3 className="text-gray-400 text-3xl font-medium">Credits</h3>


    <div class="w-full p-4 bg-white shadow-lg rounded-2xl dark:bg-gray-700">
    {creditArray? <Column {...configCredit}/>:<>Loading...</>}

   
  
    <span class="flex items-center px-2 py-1 mt-4 text-xs font-semibold text-yellow-500 bg-yellow-100 rounded-md w-36">
        MONTHLY CREDITS
    </span>
</div>

    
    <br></br>



 
   
  </div>

<div className="container mx-auto px-6 py-8 ">
    <h3 className="text-gray-400 text-3xl font-medium">Partner Sales</h3>


    <div class="w-full p-4 bg-white shadow-lg rounded-2xl dark:bg-gray-700">
    {creditArray? <Column {...configPartnerSales}/>:<>Loading...</>}

   
  
    <span class="flex items-center px-2 py-1 mt-4 text-xs font-semibold text-yellow-500 bg-yellow-100 rounded-md w-36">
        PARTNERS SALE
    </span>
</div>

    
    <br></br>



 
   
  </div>




</div>


{/* 
                <div class="grid grid-cols-1 gap-4 my-4 md:grid-cols-2 ">


                <div class="container max-w-3xl px-4 mx-auto ">
    <div class="py-4">
        <div class="px-4 py-4 -mx-4 overflow-x-auto s">
            <div class="inline-block min-w-full overflow-hidden rounded-lg shadow">
                <table class="min-w-full leading-normal">
                    <thead>
                        <tr>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Customer
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Phone
                            </th>
                            
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Amount
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Date
                            </th>
                        </tr>
                    </thead>
                    <tbody className="h-32 overflow-hidden">

                        {sawmillSalesDataToday.reverse().map(item=>{

                            return(
                                <tr>
                                <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                    <div class="flex items-center">
                                       
                                        <div class="ml-3">
                                            <p class="text-gray-900 whitespace-no-wrap">
                                                {item.customer.name}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                               
                                <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                    <p class="text-gray-900 whitespace-no-wrap">
                                    {item.customer.phone}
                                    </p>
                                </td>
                                <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                    <span class="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                                        <span aria-hidden="true" class="absolute inset-0 bg-green-200 rounded-full opacity-50">
                                        </span>
                                        <span class="relative">
                                            Nu. {item.amount?item.amount:""}
                                        </span>
                                    </span>
                                </td>
                                <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                    <p class="text-gray-900 whitespace-no-wrap">
                                        {item.date.substring(0,10)}
                                    </p>
                                </td>
                            </tr>
                            )
                        })}
                       
                    
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

                <div class="container max-w-3xl px-4 mx-auto ">
    <div class="py-4">
        <div class="px-4 py-4 -mx-4 overflow-x-auto s">
            <div class="inline-block min-w-full overflow-hidden rounded-lg shadow">
                <table class="min-w-full leading-normal">
                    <thead>
                        <tr>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Customer
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Phone
                            </th>
                           
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Amount
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Date
                            </th>
                        </tr>
                    </thead>
                    <tbody className="h-32 overflow-hidden">

                        {furnitureSalesDataToday.map(item=>{

                            return(
                                <tr>
                                <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                    <div class="flex items-center">
                                        <div class="flex-shrink-0">
                                          
                                        </div>
                                        <div class="ml-3">
                                            <p class="text-gray-900 whitespace-no-wrap">
                                                {item.customer?item.customer.name:"NO NAME"}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                    <p class="text-gray-900 whitespace-no-wrap">
                                        {item.customer? item.customer.phone:"PHONE"}
                                    </p>
                                </td>
                               
                                <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                    <span class="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                                        <span aria-hidden="true" class="absolute inset-0 bg-green-200 rounded-full opacity-50">
                                        </span>
                                        <span class="relative">
                                          Nu. {item.amount?item.amount:""}
                                        </span>
                                    </span>
                                </td>
                                <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                    <p class="text-gray-900 whitespace-no-wrap">
                                        {item.date.substring(0,10)}
                                    </p>
                                </td>
                            </tr>
                            )
                        })}
                       
                    
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
                <div class="container max-w-3xl px-4 h-2 ">
    <div class="py-4">
        <div class="px-4 py-4 -mx-4 overflow-x-auto ">
            <div class="inline-block min-w-full overflow-auto rounded-lg shadow">
            EXPENSE

                <table class="min-w-full overflow-auto leading-normal">

                    <thead>
                        <tr>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Name
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Description
                            </th>
                           
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Amount
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Date
                            </th>
                        </tr>
                    </thead>

                    <tbody className="h-32 overflow-hidden">

                        {ExpenseDataToday.map(item=>{

                            return(
                                <tr>
                                <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                    <div class="flex items-center">
                                        <div class="flex-shrink-0">
                                          
                                        </div>
                                        <div class="ml-3">
                                            <p class="text-gray-900 whitespace-no-wrap">
                                                {item.name?item.name:"NO NAME"}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                    <p class="text-gray-900 whitespace-no-wrap">
                                        {item.description? item.Description:"N/A"}
                                    </p>
                                </td>
                               
                                <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                    <span class="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                                        <span aria-hidden="true" class="absolute inset-0 bg-green-200 rounded-full opacity-50">
                                        </span>
                                        <span class="relative">
                                          Nu. {item.amount}
                                        </span>
                                    </span>
                                </td>
                                <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                    <p class="text-gray-900 whitespace-no-wrap">
                                        {item.date.substring(0,10)}
                                    </p>
                                </td>
                            </tr>
                            )
                        })}
                       
                    
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>





</div> */}



  </div>
  
 








</main>
</div>

    </div>
  </div>  
);


  
}



export default Report




