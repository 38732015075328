import React, { useState,createContext,useContext,useEffect,useReducer } from 'react';
import {BrowserRouter,Link,Route, Switch, useNavigate} from 'react-router-dom';
import {UserContex} from '../App'


const NavBar = ()=>{

    const {state, dispatch}= useContext(UserContex)


    const history = useNavigate()






    const Logout = ()=>{
        localStorage.clear()
        dispatch({type:"CLEAR"})
        history('/login')
    }

    return(

        <div>
        <nav class=" bg-gray-50 shadow ">
            <div class="max-w-7xl mx-auto px-2">
                <div class="flex items-center justify-between h-16">
                    <div class="w-full justify-items-start justify-start  ">
                        <a class="flex-shrink-0" href="/dashboard">
                        <svg class="h-8 w-8 text-gray-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="5 12 3 12 12 3 21 12 19 12" />  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />  <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg>
                        {/* <img src='/logorx.png' className='w-24 h-16 '/> */}
                        </a>
                        
                    </div>
                    <div class="block">
                        
                    </div>
                    <div class="-mr-2 flex ">
                      
                    <button class="flex p-2 items-center rounded-full  "
                            
                            // onClick={()=>{if(window.confirm('Are you sure you want to delete?')) Logout()}}                           
                    
                        >
                   <svg class="h-6 w-6 text-gray-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
</svg>

                    
                     </button>
                    <button class="flex p-2 ml-2 items-center rounded-full     "
                            
                            onClick={()=>{if(window.confirm('Are you sure you want to delete?')) Logout()}}                           
                    
                        >
                   <svg class="h-6 w-6 text-gray-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"
                        
                        
                        />  <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />  <path d="M7 12h14l-3 -3m0 6l3 -3" /></svg>       
                     </button>
                  
    
                    </div>
                </div>
            </div>
           
        </nav>
    </div>
        )


}

export default NavBar