
import React,{useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import Header from './Header';

import {UserContex} from '../App'
import Footer from './Footer';


const Nettle = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[skills,setSkills]=useState([])
  const[docs,setDocs]=useState([])
  const[salary,setSalary]= useState("")
  const [dob,setDob]= useState(new Date())
  const [url,setUrl] = useState("")  

  const PostData =()=>{

   
//     fetch("/signinemployee",{
//       method:"post",
//       headers:{
//           "Content-Type":"application/json"
//       },
//       body: JSON.stringify({
        
//           password,
//           email:email
//       })
      
//   }).then(res=>res.json())
//   .then(data=> {
     
//       if(data.error){
//         toast.error(data.error)

//       }
//       else{
//         console.log("Login Successful")
//           localStorage.setItem("jwt", data.token)
//           localStorage.setItem("user", JSON.stringify( data.user))
//           dispatch({type:"USER", payload:data.user})
//           history.push('/dashboard')
//       }
//   })
//   .catch(err=>{
//       console.log(err)
//   })

       }

    return(


<>
<Header></Header>

<div class="relative md:block lg:block hidden mt-12">

<img src="Nettle.jpg" class="absolute h-full w-full object-cover"/>
{/* <div class="absolute inset-0 bg-purple-500 opacity-50"></div> */}
<div class="absolute inset-0 overflow-hidden">
    {/* <div class="absolute inset-0 bg-gradient-to-r from-black to-transparent"></div> */}
  </div>


 <div class="container mx-auto px-6 md:px-12 relative z-10 flex items-center p-32">
        <div class=" flex flex-col  relative z-10">
            <span class="font-bold uppercase text-white md:animate-bounce repeat-1 duration-1000">
                ____________________
            </span>
            <h1 class=" font-bold text-4xl md:text-6xl text-white text-center leading-tight mt-2  ">
            Himalayan Nettle 
               
                
            
            </h1>
           
        </div>
    </div>
   
   
    </div>

<div class="p-4">



 
<div class="flex flex-wrap w-full">
    
   
  
  
 
  
    



            </div>

     

    <div class="max-w-screen-xl  mx-auto ">
    <h1 class=" text-2xl text-center font-black leading-none text-gray-800 uppercase font-bebas-neue  ">
    The Problem
</h1>
        <ul class="flex flex-wrap items-center gap-8">
            <li class="w-full">
               
                <p class="mt-2">
                    <p class="text-base leading-6 text-gray-500 text-justify">
                    Mountain Crafts is dedicated to preserving Bhutan's indigenous heritage and empowering rural artisans through sustainable, fair trade practices. We showcase exquisite, handcrafted Bhutanese textiles and handicrafts, connecting traditional artisans with global markets. Our mission is to promote cultural preservation, stimulate passion for eco-friendly, nature-based products, and support sustainable businesses. By investing profits back into producer communities, we foster economic growth and well-being. Mountain Crafts is committed to sharing Bhutan's rich cultural legacy while ensuring a sustainable future for its artisans and traditional crafts.                    
                            </p>
                </p>
            </li>
          
       
        </ul>
    </div>
   
    <div class="max-w-screen-xl  mx-auto mt-8 ">
    <h1 class=" text-2xl text-center font-black leading-none text-gray-800 uppercase font-bebas-neue  ">
    Our Solutions
</h1>
        <ul class="flex flex-wrap items-center gap-8">
            <li class="w-full">
               
                <p class="mt-2">
                    <p class="text-base leading-6 text-gray-500 text-justify">
                    Harnessing Nature's Sustainable Fiber
Mountain Crafts champions Himalayan Nettle as an eco-friendly alternative to conventional textiles. This remarkable bast fiber, known for its strength and durability, offers numerous benefits:
                            </p>
                </p>
            </li>
          
       
        </ul>
        <ul class="w-full mt-6 mb-6 text-sm text-gray-600 ">
        <li class="mb-3 flex items-center ">
            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                </path>
            </svg>
            Sustainable harvesting practices that support local ecosystems
        </li>
     
        <li class="mb-3 flex items-center ">
            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                </path>
            </svg>
            Natural pest resistance, eliminating the need for harmful chemicals        </li>
     
        <li class="mb-3 flex items-center ">
            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                </path>
            </svg>
            Minimal water requirements compared to cotton        </li>
     
        <li class="mb-3 flex items-center ">
            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                </path>
            </svg>
            Carbon sequestration, helping to mitigate climate change        </li>
     
        <li class="mb-3 flex items-center ">
            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                </path>
            </svg>
            Erosion control in mountainous regions, preventing landslides        </li>
     
    </ul>
    <ul class="flex flex-wrap items-center gap-8">
            <li class="w-full">
               
                <p class="mt-2">
                    <p class="text-base leading-6 text-gray-500 text-justify">
                    Our handwoven Himalayan Nettle products not only provide superior quality but also support indigenous communities and preserve traditional craftsmanship. By choosing Himalayan Nettle, you're investing in a sustainable future for both people and the planet.
                    </p>
                </p>
            </li>
          
       
        </ul>
    
    </div>

    <div class="max-w-screen-xl  mx-auto mt-12">
    <h1 class=" text-2xl text-center font-black leading-none text-gray-800 uppercase font-bebas-neue  ">
    Care instructions:</h1>
        <ul class="flex flex-wrap items-center gap-8">
            <li class="w-full">
               
                <p class="mt-2">
                    <p class="text-base leading-6 text-gray-500 text-justify">
                    Hand wash in cool water with mild pH-neutral detergent. Avoid wringing or twisting. Lay flat to dry away from direct sunlight. Iron on low heat if needed. Store in a cool, dry place to prevent mildew. Avoid bleach and harsh chemicals.



                           </p>
                </p>
            </li>
          
       
        </ul>
    </div>


</div>

<Footer></Footer>


</>
  


    );
}

export default Nettle;