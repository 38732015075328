
import React,{useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import Header from './Header';

import {UserContex} from '../App'
import Footer from './Footer';


const GiftGuide = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[skills,setSkills]=useState([])
  const[docs,setDocs]=useState([])
  const[salary,setSalary]= useState("")
  const [dob,setDob]= useState(new Date())
  const [url,setUrl] = useState("")  

  const PostData =()=>{

   
//     fetch("/signinemployee",{
//       method:"post",
//       headers:{
//           "Content-Type":"application/json"
//       },
//       body: JSON.stringify({
        
//           password,
//           email:email
//       })
      
//   }).then(res=>res.json())
//   .then(data=> {
     
//       if(data.error){
//         toast.error(data.error)

//       }
//       else{
//         console.log("Login Successful")
//           localStorage.setItem("jwt", data.token)
//           localStorage.setItem("user", JSON.stringify( data.user))
//           dispatch({type:"USER", payload:data.user})
//           history.push('/dashboard')
//       }
//   })
//   .catch(err=>{
//       console.log(err)
//   })

       }

    return(


<>
<Header></Header>

<div className="relative h-screen overflow-hidden scroll-smooth bg-indigo-900 md:block ">

<img src="https://res.cloudinary.com/daehmzcuc/image/upload/v1734112640/WhatsApp_Image_2024-12-13_at_11.56.59_PM_gprqub.jpg" className="absolute object-cover object-center  w-full h-full" alt="Background" />
{/* <video autoPlay loop muted playbackRate={2.0}  class=" absolute h-1/2 w-full object-fill md:h-full md:w-full md:object-cover">
  <source src="/bgvid.mp4" type="video/mp4" />
     </video> */}



<div className="absolute inset-0 bg-black opacity-25"></div>


 
<div className="relative z-10 flex flex-col items-center justify-center h-full w-full text-center max-w-lg mx-auto ">
  <div className="flex items-center justify-center w-full h-full">
    <h1 
      className=" font-serif animate-pulse  leading-tight text-white text-6xl whitespace-nowrap transition-transform transition-opacity duration-[200ms] ease-in-out"
     
    >
Coming Soon...
<br></br>


</h1>

  </div>

  
 
  {/* <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-4"
  
  >
    <svg className="h-14 w-14 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <polyline points="7 13 12 18 17 13" />
      <polyline points="7 6 12 11 17 6" />
    </svg>
  </div> */}
</div>
</div>







<Footer></Footer>


</>
  


    );
}

export default GiftGuide;