import React,{ useState, useContext ,createContext, useReducer,useEffect} from 'react';
// import SignupPage from './pages/Signup';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoutes from './utils/ProtectedRoutes';
import ProtectedRoutesUser from './utils/ProtectedRoutesUser';


import {reducer,initialState} from "./reducers/userReducer"
// import Login from './pages/Login'
import Home from './pages/HomePage'
import Shop from './pages/Shop'
import AboutUs from './pages/AboutUs'
import ContactUs from './pages/ContactUs'
import Cart from './pages/Cart'
import Login from './pages/Login'
import Dashboard from './pages/Home'
import Inventory from './pages/Inventory'
import AllSales from './pages/AllSales'
import Sales from './pages/Sales';
import Customer from './pages/Customer';
import Orders from './pages/Orders';
import Report from './pages/Report';
import Employee from './pages/Employee';
import Expense from './pages/Expense';
import CategoryProduct from './pages/CategoryProducts';
import SingleProduct from './pages/SingleProduct';
import OnlineConsumer from './pages/OnlineConsumer';
import EmployeeProfile from './pages/EmployeeProfile';
import Stories from './pages/Stories';
import PartnerStores from './pages/PartnerStores';
import SinglePartnerStore from './pages/SinglePartnerStore';
import Success from './pages/Success';
import PartnerWithUs from './pages/PartnerWithUs';
import PhilanthropicGiving from './pages/PhilanthropicGiving';
import Register from './pages/Register';
import Profile from './pages/Profile';
import Signup from './pages/Signup';
import OurMaterials from './pages/OurMaterials';
import Nettle from './pages/Nettle';
import OrganicCotton from './pages/OrganicCotton';
import Kishu from './pages/Kishu';
import SilkonCotton from './pages/SilkOnCotton';
import Yathra from './pages/Yathra';
import NaturalDyes from './pages/NaturalDye';
import Reset from './pages/Reset';
import Mission from './pages/Mission';
import Team from './pages/Team';
import Sustainability from './pages/Sustainablity';
import GiftGuide from './pages/GiftGuide';
import FAQ from './pages/FAQ';
import ReturnPolicy from './pages/ReturnPolicy';
import Book from './pages/Book';
export const UserContex = createContext()


function App() {
  const [state, dispatch] = useReducer(reducer,initialState)


  
   const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
//   useEffect(() => {
//     // Example: Emit a message to the server
//     socket.emit('chat message', 'Hello, server!');

//     // Example: Listen for messages from the server
//     socket.on('chat message', (msg) => {
//         console.log('Message from server: ' + msg);
//     });

//     // Clean up on component unmount
//     return () => {
//         socket.disconnect();
//     };
// }, []);






  return (
    <>
        <UserContex.Provider value={{state,dispatch}}>

      <Router>
        {/* {isLoggedIn ? (
        <button onClick={logOut}>Logout</button>
        ) : (
        <button onClick={logIn}>Login</button>
        )} */}
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/shop" element={<Shop/>}/>
          <Route path="/about" element={<AboutUs/>}/>
          <Route path="/sustainable" element={<Sustainability/>}/>
          <Route path="/giftguide" element={<GiftGuide/>}/>
          <Route path="/team" element={<Team/>}/>
          <Route path="/faq" element={<FAQ/>}/>
          <Route path="/returnpolicy" element={<ReturnPolicy/>}/>
          <Route path="/mission" element={<Mission/>}/>
          <Route path="/materials" element={<OurMaterials/>}/>
          <Route path="/nettle" element={<Nettle/>}/>
          <Route path="/cotton" element={<OrganicCotton/>}/>
          <Route path="/kishuthara" element={<Kishu/>}/>
          <Route path="/silk-on-cotton" element={<SilkonCotton/>}/>
          <Route path="/yathra" element={<Yathra/>}/>
          <Route path="/natural-dyes" element={<NaturalDyes/>}/>
          <Route path="/contact" element={<ContactUs/>}/>
          <Route path="/partner-with-us" element={<PartnerWithUs/>}/>
          <Route path="/philanthropicgiving" element={<PhilanthropicGiving/>}/>
          <Route path="/stories" element={<Stories/>}/>
          <Route path="/cart" element={<Cart/>}/>
          <Route path="/book" element={<Book/>}/>
          <Route path="/singleproduct/:productid" element={<SingleProduct/>}/>
          <Route path="/productcategory/:name" element={<CategoryProduct/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/reset" element={<Reset/>}/>
          <Route path="/consumerlogin" element={<Register/>}/>
          <Route path="/success" element={<Success/>}/>
          <Route path="/register" element={<Signup/>}/>
          {/* <Route path="/login" element={<Login/>}/> */}
          
          {/* <Route path="/profile" element={<Profile/>}/> */}
          {/* <Route path="/category" element={<EventCategory/>}/> */}
          
          {/* <Route path="*" element={<Error/>}/> */}

          <Route  element={<ProtectedRoutes/>}>
          <Route path="/Dashboard" element={<Dashboard/>}/>
          <Route path="/inventory" element={<Inventory/>}/>
          <Route path="/inventory" element={<Inventory/>}/>
          <Route path="/sales" element={<Sales/>}/>
          <Route path="/partnerstores" element={<PartnerStores/>}/>
          <Route path="/singlerecord/:customerid" element={<Customer/>}/>
          <Route path="/singlepartner/:customerid" element={<SinglePartnerStore/>}/>
          <Route path="/orders" element={<Orders/>}/>
          <Route path="/employee" element={<Employee/>}/>
          <Route path="/expenses" element={<Expense/>}/>
          <Route path="/onlineconsumer" element={<OnlineConsumer/>}/>
          <Route path="/report" element={<Report/>}/>
          <Route path="/employeeprofile/:employeeid" element={<EmployeeProfile/>}/>
          
          </Route>


          <Route  element={<ProtectedRoutesUser/>}>
          <Route path="/profile" element={<Profile/>}/>
         
          </Route>
         
         
        </Routes>
      </Router>
      </UserContex.Provider>

    </>
  );
}

export default App;
