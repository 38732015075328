import React, {useContext, useRef, useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
// import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
// import {UserContex} from '../App'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HomeHeader from './HomeHeader'
import Header from './Header'
import { Carousel } from 'react-responsive-carousel';
import Footer from './Footer'

import MiniSearch from 'minisearch';
import AOS from "aos"
import "aos/dist/aos.css"

const HomePageCustomer = ()=>{


  const history = useNavigate()
//   const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")
  const  [cart, setCart]= useState([])

  const [addStock,setAddStock]= useState(false)
  const [editStock,setEditStock]= useState(false)

  const[name,setName]= useState("")
  const[email,setEmail]= useState("")
  const[nameEdit,setNameEdit]= useState("")
  const[idEdit,setidEdit]= useState("")
  const[quantity,setQuantity]= useState()
  const[quantityEdit,setQuantityEdit]= useState()
  const[rate,setRate]= useState()
  const[rateEdit,setRateEdit]= useState()

  const [popup,setCheckOut]= useState(false)

  const [book, setBook] = useState([]);
  const [bookid, setBookId] = useState([]);
  
  const [saw, setSaw] = useState(() => {
    const status = localStorage.getItem('seen');
    return status ? false : true;
  });

  const[address,setAddress]= useState("")
  const[allAddress,setAllAddress]= useState([])

  const [url,setUrl] = useState([])  

  const[progress,setProgress]= useState(false)
  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState([]);

//   const {
//     transcript,
//     listening,
//     resetTranscript,
//     browserSupportsSpeechRecognition
//   } = useSpeechRecognition();

// const {
//     transcript,
//     interimTranscript,
//     finalTranscript,
//     resetTranscript,
//     listening,
//     } = useSpeechRecognition();

//     const [startDate, setStartDate] = useState(new Date());


//   const monthNames = ["January", "February", "March", "April", "May", "June",
//   "July", "August", "September", "October", "November", "December"];

// var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

// const d = new Date();




  useEffect(  ()=>{

    

    fetch('/getallStock',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.stocks)
      
  })




  



//   fetch(`/getconsumerprofile`,{
//     method:"get",
//     headers:{
//         Authorization: "Bearer " +localStorage.getItem("jwt"),
//         "Content-Type":"application/json"

        
//     },
 
// }).then(res=>res.json())
// .then(result=>{

//   // console.log("DSF",result.consumer[0].address)
//     // setOrderHistoryData(result.orders)
//     // setData(result.consumer)
//     setAllAddress(result.consumer[0].address)
// })


  },[])

  useEffect(() => {
    // Create a MiniSearch instance
    const miniSearch = new MiniSearch({
      fields: ['name', 'description','category'], // fields to index for full-text search
      storeFields: ['_id', 'name', 'description','category','pic'], // fields to return with search results
      searchOptions: {
        boost: { realestateType: 3, description: 2, address:2 },
        prefix: true,
        fuzzy: 0.5,
      },
      idField:"_id"
    });

    // Index the products data
    miniSearch.addAll(data);

    // Perform the search
    if (searchQuery) {
      const searchResults = miniSearch.search(searchQuery);
      setResults(searchResults);
    } else {
      setResults([]);
    }
  }, [searchQuery]);

  useEffect(() => {
    // This effect uses the `value` variable,
    // so it "depends on" `value`.

    const l = JSON.parse(localStorage.getItem('cart'))

    if(l){
      setCart(l)
    }


    // setSearch(transcript)
    // fetchPost(transcript)
  }, [])


  useEffect(  ()=>{
    AOS.init();
    AOS.refresh();
  
  },[])
  


  // useEffect(() => {
  //   const detectLocation = async () => {
  //     try {
  //       // Check if currency is already in localStorage
  //       const savedCurrency = localStorage.getItem("currency");
  //       if (savedCurrency) {
  //         console.log("Currency already set:", JSON.parse(savedCurrency));
  //         return; // Exit if currency already exists
  //       }
  
  //       // If not, fetch location and set currency
  //       const response = await fetch("https://ipapi.co/json/");
  //       if (!response.ok) {
  //         throw new Error("Failed to fetch location data");
  //       }
  //       const data = await response.json();
  //       const { country_code } = data;
  
  //       let currency = "Default";
  
  //       if (["IN", "BD", "NP", "PK"].includes(country_code)) {
  //         currency = "INR";
  //       } else if (["BT"].includes(country_code)) {
  //         currency = "NU";
  //       } else if (["FR", "DE", "IT", "ES", "NL"].includes(country_code)) {
  //         currency = "EUR";
  //       } else if (country_code === "US") {
  //         currency = "USD";
  //       }
  
  //       console.log("Setting currency:", currency);
  //       localStorage.setItem("currency", JSON.stringify(currency));
  //     } catch (error) {
  //       console.error("Error detecting location:", error);
  //     }
  //   };
  
  //   detectLocation();
  // }, []);
  
  

 

  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/searchall-stock',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.stock)
      })


  }

  const singleStock = (Itemid)=>{

      console.log(Itemid)
    fetch('/getSingleStock',{
      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        id:Itemid
      })
  }).then(res=>res.json())
  .then(result=>{


    // setDataEdit(result.stock)
    console.log(result)
    setidEdit(result.stock._id)
    setNameEdit(result.stock.name)
    setQuantityEdit(result.stock.quantity)
    setRateEdit(result.stock.rate)
            
  })

  }


  const uploadppt=({target:{files}})=>{


    console.log("UPLOAD")

    setProgress(true)

    let data = new FormData()

    for(const file of files){

   data.append('uploadimage', file)
   data.append('name',file.name)
}
       fetch("/uploadimage", {

         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{

      console.log("PROGRESS",data.url)

          setUrl(old=>[...old,data.url])
          setProgress(false)

      

       
     })
     .catch(err=>{
         console.log(err)
     })

}
  

// const orderPrescription = ()=>{




//   fetch('/addOrder',{

//     method:"post",
//     headers:{
//       "Content-Type":"application/json",
//       Authorization: "Bearer " +localStorage.getItem("jwt")

//     },
//     body: JSON.stringify({
     
//       consumer:state._id,
//       prescription:url,
//       address:address

//     })
    
//   }).then(res=>res.json())
//   .then(data=>{
//     if(data.error){
//       console.log("Error")
//     }

//     else{
//       // toast.success("ORDER PLACED SUCESSFULLY");
//          window.location.reload();

//     }
//   })
//   .catch(err=>{
//     console.log(err)
//   })

//   // window.location.reload();
  




// }




// const addtoCart = (item)=>{

//   setCart(olditem=>[...olditem, item._id])

//   fetch('/addToCart',{

//     method:"post",
//     headers:{
//       Authorization: "Bearer " +localStorage.getItem("jwt"),

//       "Content-Type":"application/json"

//     },
//     body:JSON.stringify({
//       product:item._id,
//       consumer:state._id
      
//     })

//   }).then(res=> res.json())
//     .then(results=>{
      
//       // setData(results.order)
//     })



// const json = localStorage.getItem("cart");
//   const previousPosts = json ? JSON.parse(json) : [];
//   const updatedPosts = [...previousPosts, item._id];
//   // const uniquePosts = Array.from(new Set(updatedPosts))
//   const stringifyed = JSON.stringify(updatedPosts);
//   localStorage.setItem("cart", stringifyed);
// }



const [scrollY, setScrollY] = useState(0);

useEffect(() => {
  const handleScroll = () => setScrollY(window.scrollY);
  
  window.addEventListener('scroll', handleScroll);
  
  return () => window.removeEventListener('scroll', handleScroll);
}, []);

// Adjust these values based on desired effect and container size
const maxScroll = 20; // When the effect should be fully applied
const scale = Math.max(0.5, 1 - scrollY / maxScroll); // Scaling effect
const translateY = Math.max(-window.innerHeight / 1, -scrollY); // Moves up from center to top
const opacity = Math.max(0.5, 1 - scrollY / maxScroll); // Fading out effect


const [scrollYB, setScrollYB] = useState(0);
const wrapperWomenRef = useRef(null);
const wrapperMenRef = useRef(null);

useEffect(() => {
  const handleScroll = () => setScrollYB(window.scrollYB);
  window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);
}, []);



const features = [
  {
    title: 'Composed of natural fibers and fabrics',
    description: 'This is the description for feature 1.',
    image: 'https://via.placeholder.com/400',
  },
  {
    title: 'Includes use of natural colors and dyes (plant-based)',
    description: 'This is the description for feature 2.',
    image: 'https://via.placeholder.com/400',
  },
  {
    title: 'Provides income for rural communities',
    description: 'This is the description for feature 3.',
    image: 'https://via.placeholder.com/400',
  },
  {
    title: 'Produced in an environmentally friendly manner',
    description: 'This is the description for feature 3.',
    image: 'https://via.placeholder.com/400',
  },
  {
    title: 'Woven by rural/indigenous women based on multi-generational traditions',
    description: 'This is the description for feature 3.',
    image: 'https://via.placeholder.com/400',
  },
  {
    title: 'Supports research and documentation of indigenous crafts production',
    description: 'This is the description for feature 3.',
    image: 'https://via.placeholder.com/400',
  },
  // Add more features as needed
];

const [currentIndex, setCurrentIndex] = useState(0);

// Automatically change the slide every 5 seconds
useEffect(() => {
  const interval = setInterval(() => {
    nextSlide();
  }, 3000); // 5000ms = 5 seconds

  return () => clearInterval(interval); // Clean up the interval when component unmounts
}, [currentIndex]);

const nextSlide = () => {
  setCurrentIndex((prevIndex) => (prevIndex + 1) % features.length);
};

const prevSlide = () => {
  setCurrentIndex((prevIndex) =>
    prevIndex === 0 ? features.length - 1 : prevIndex - 1
  );
};



const PostData =()=>{
  const password = Array.from({ length: 12 }, () => 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:,.<>?'[Math.floor(Math.random() * 86)]).join('');
  const coupon = Array.from({ length: 5 }, () => 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'[Math.floor(Math.random() * 86)]).join('');
  
  fetch("/signupcouponConsumer",{
    method:"post",
    headers:{
        "Content-Type":"application/json"
    },
    body: JSON.stringify({
      
        password:password,
        email:email,
        coupon:coupon
    })
    
}).then(res=>res.json())
.then(data=> {

  
   
    if(data.error){
      toast.error(data.error)
      localStorage.setItem("seen", true)
      setSaw(false)

    }
    else{
      toast.success("Signup Sucessful, Cheack your email")

      localStorage.setItem("seen", true)
      setSaw(false)      
        history('/consumerlogin')
    }
})
.catch(err=>{
    console.log(err)
})

     }


     const addtoBook = (item) => {
      setBook(olditem => [...olditem, { product: item }]);
      setBookId(olditem => [...olditem, item._id]);
  
      const json = localStorage.getItem("book");
      const previousPosts = json ? JSON.parse(json) : [];
      const updatedPosts = [...previousPosts, { product: item }];
      const stringifyed = JSON.stringify(updatedPosts);
      localStorage.setItem("book", stringifyed);
  
      const jsonid = localStorage.getItem("bookid");
      const previousPostsid = jsonid ? JSON.parse(jsonid) : [];
      const updatedPostsid = [...previousPostsid, item._id];
      const stringifyedid = JSON.stringify(updatedPostsid);
      localStorage.setItem("bookid", stringifyedid);
  }
  
  
  
  const removeFromBook = (item) => {
      // Remove item from state
      setBook(oldItems => oldItems.filter(bookItem => bookItem.product._id !== item._id));
      setBookId(oldItems => oldItems.filter(bookItemId => bookItemId !== item._id));
  
      // Remove item from localStorage (book)
      const json = localStorage.getItem("book");
      const previousPosts = json ? JSON.parse(json) : [];
      const updatedPosts = previousPosts.filter(post => post.product._id !== item._id);
      const stringifyed = JSON.stringify(updatedPosts);
      localStorage.setItem("book", stringifyed);
  
      // Remove item from localStorage (bookid)
      const jsonid = localStorage.getItem("bookid");
      const previousPostsid = jsonid ? JSON.parse(jsonid) : [];
      const updatedPostsid = previousPostsid.filter(id => id !== item._id);
      const stringifyedid = JSON.stringify(updatedPostsid);
      localStorage.setItem("bookid", stringifyedid);
  }

return(

    <main class="bg-white h-screen  ">
      <div className='md:block lg:block hidden'>
      <Header></Header>

      </div>
      <div className='md:hidden lg:hidden block'>
      <Header></Header>

      </div>

<ToastContainer></ToastContainer>

    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full ">

            



{/*             

    <div class="md:hidden lg:hidden block flex justify-center mt-6 "
    
    onClick={()=>{history('/shop')}}
    >

<div class="relative overflow-hidden bg-lime-100 rounded-lg shadow-lg w-11/12">
    <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -top-6 -right-6 md:-right-4"/>
    <div class="px-4 py-5 sm:p-6">
        <dl>
            <dt class="text-sm font-medium leading-5 text-gray-500 truncate">
                Join us
            </dt>
            <dd class="mt-1 text-3xl font-semibold leading-9 text-gray-900">
            Empowering local communities through sustaining indigenous arts and crafts.
                </dd>
            <dd class="font-semibold text-gray-500">
                <span>
                    Explore 
                  
                </span>
            </dd>
        </dl>
    </div>
</div>

    </div> */}

            


        </div>
     

    </div>

  

    <div className="relative h-1/2 overflow-hidden scroll-smooth bg-indigo-900 md:hidden lg:hidden block ">

  <img src="/bann.png" className="absolute object-cover w-full h-full" alt="Background" />
  {/* <video autoPlay loop muted playbackRate={2.0}  class=" absolute h-full w-full object-fill md:h-full md:w-full md:object-cover">
    <source src="/bgvid.mp4" type="video/mp4" />
       </video>

   */}
 
  <div className="absolute inset-0 bg-black opacity-25"></div>
  {/* <a href='/cart'
 
  >
     <svg class="h-8 w-8 text-slate-300 top-0 right-0 absolute m-6"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="9" cy="21" r="1" />  <circle cx="20" cy="21" r="1" />  <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" /></svg>

     </a> */}

     <a class="" href="/cart">

<svg class="h-8 w-8 text-slate-300 top-0 right-0 absolute  "  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="9" cy="21" r="1" />  <circle cx="20" cy="21" r="1" />  <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" /></svg>
<button type="button" class="absolute  right-0 w-5 h-5 -mt-3   text-white bg-red-600 rounded-full fill-current">
<span class="text-xs">
{cart?cart.length:"0"}
</span>
</button>
</a>

  <div className="relative z-10 flex flex-col items-center justify-center h-full w-full text-center max-w-lg mx-auto">
  <div className="flex items-center justify-center w-full h-full ">
  <h1 
    className=" font-sans font-extrabold leading-tight text-white text-4xl whitespace-nowrap transition-transform transition-opacity duration-[200ms] ease-in-out"
  >
    Mountain Crafts
  </h1>
</div>
    <div className="absolute bottom-0 left-1/2  transform -translate-x-1/2 mb-4"
   
    >
      <svg className="h-14 w-14 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <polyline points="7 13 12 18 17 13" />
        <polyline points="7 6 12 11 17 6" />
      </svg>
    </div>
  </div>
</div>
    <div className="relative h-2/3 overflow-hidden scroll-smooth bg-stone-200 md:block lg:block hidden">

  <img src="/landbg.png" className="absolute object-cover object-center mt-16 w-full h-full " alt="Background" />


  
 


   
 
</div>

    {/* <div className="relative h-2/3 overflow-hidden scroll-smooth bg-indigo-900 md:block lg:block hidden">

  <img src="/landbg.png" className="absolute object-cover w-full h-full" alt="Background" />


  
 
  <div className="absolute inset-0 bg-black opacity-25"></div>
  <a 
   style={{ 
    transform: `scale(${scale}) translateY(${translateY}px)`,
    opacity: opacity,
  }}
  >

    <a href='/profile'>

    
     <svg class="h-6 w-6 text-slate-500 top-0 right-0 absolute  mt-6 mr-24"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

     </a>
    <a href='/shop'>

     <svg class="h-6 w-6 text-slate-500 top-0 right-0 absolute  mt-6 mr-16"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="11" cy="11" r="8" />  <line x1="21" y1="21" x2="16.65" y2="16.65" /></svg>
     </a>

     <a href='/cart'>
      
     <svg class="h-6 w-6 text-slate-500 top-0 right-0 absolute  m-6" 
     viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="9" cy="21" r="1" />  <circle cx="20" cy="21" r="1" />  <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" /></svg>
<button type="button" class="absolute  right-0 w-4 h-4   m-4 text-white bg-red-600 rounded-full fill-current">
<span class="text-xs">
{cart?cart.length:"0"}
</span>
</button>
</a>

     </a>


   
  <div className="relative z-10 flex flex-col items-center justify-center h-full w-full text-center max-w-lg mx-auto mt-32">
    <div className="flex items-center justify-center w-full h-full">
      <h1 
        className=" font-sans font-extrabold leading-tight text-white text-8xl whitespace-nowrap transition-transform transition-opacity duration-[200ms] ease-in-out"
        style={{ 
          transform: `scale(${scale}) translateY(${translateY}px)`,
          opacity: opacity,
        }}
      >
        Mountain Crafts
      </h1>
    
    </div>
    <div className="flex items-center justify-center  h-full ">
      <h1 
        className=" font-sans font-extrabold leading-tight text-white text-2xl whitespace-nowrap transition-transform transition-opacity duration-[200ms] ease-in-out"
        style={{ 
          transform: `scale(${scale}) translateY(${translateY-190}px)`,
          opacity: opacity,
        }}
      >
        Elevate Your Fashion. Shop with Impact!
      </h1>
    
    </div>
    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-4"
     style={{ 
      transform: `scale(${scale}) translateY(${translateY}px)`,
      opacity: opacity,
    }}
    >
      <svg className="h-14 w-14 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <polyline points="7 13 12 18 17 13" />
        <polyline points="7 6 12 11 17 6" />
      </svg>
    </div>
  </div>
</div>
 */}


{/* <div className="flex items-center justify-center ">
      <h1 
        className=" font-serif  leading-tight mt-4 text-black text-5xl whitespace-nowrap transition-transform transition-opacity duration-[200ms] ease-in-out"
       
      >
        Explore Everything
      </h1>
    
    </div> */}



<div class=" p-6 px-6 mx-auto bg-white ">
    <div class="mb-16 text-center">
        <h2 class="text-base font-semibold tracking-wide text-indigo-600 uppercase">
            Our Products
        </h2>
        <p class="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 dark:text-white sm:text-4xl">
            Explore Everyting
        </p>
    </div>
    <div class="grid md:grid-cols-6 grid-cols-2 object-center    ">
    <div class=" p-2 md:border-b border-black  md:border  bg-cover bg-center" >
                
    <a href='/productcategory/jacket'>

            <img src="/5.png" className=" object-cover  " alt="Background" />
</a>
        </div>
       
    <div class="w-full p-2 border-b md:border border-black lg:w-full   bg-cover bg-center" >
                
    <a href='/productcategory/men'>

            <img src="/6.png" className=" object-cover " alt="Background" />
            </a>

        </div>
    <div class="w-full p-2 border-b md:border-black md:border lg:w-full  bg-cover bg-center" >
    <a href='/productcategory/bag'>

               
            <img src="/7.png" className=" object-cover " alt="Background" />
</a>
        </div>
       
      
        
        <div class="w-full p-2  md:border border-black lg:w-full  bg-cover bg-center" >
                
               <a href='/productcategory/decore'>

            <img src="/8.png" className=" object-cover " alt="Background" />
            </a>

        </div>


        <div class="w-full p-2  md:border border-black lg:w-full   bg-cover bg-center" >
                
               <a href='/productcategory/sleeveless'>

            <img src="/9.png" className=" object-cover " alt="Background" />
            </a>

        </div>
        <div class="w-full p-2 md:block md:border hidden lg:w-full border-black  ">
        <a href='/productcategory/summer'>

<img src="/10.png" className=" object-cover " alt="Background" />
</a>
        </div>
    </div>
</div>

<h1 class=" text-2xl text-center font-black leading-none text-gray-800 uppercase font-bebas-neue mt-4 ">
         Picked for you

</h1>
         
         <div class="flex overflow-x-scroll   hide-scroll-bar">
            
  <div class="flex flex-nowrap p-8  mt-6 " >
    


  {data.slice(3, 10).map((product) => (
  <div key={product._id} className="w-80 h-full p-2 relative">
      <div className="w-full overflow-hidden rounded-md bg-gray-200 relative">
        {/* SVG Icon positioned on top of the image */}
        {bookid.includes(product._id)?  <svg
       className="h-5 w-5 text-red-500 absolute top-2 right-2"
       width="24"
       height="24"
       viewBox="0 0 24 24"
       strokeWidth="2"
       stroke="currentColor"
       fill="none"
       strokeLinecap="round"
       strokeLinejoin="round"

       onClick={()=>{removeFromBook(product)}}
     >
       <path stroke="none" d="M0 0h24v24H0z" />
       <path fill={`${"currentColor"}`} d="M12 20l-7 -7a4 4 0 0 1 6.5 -6a.9 .9 0 0 0 1 0a4 4 0 0 1 6.5 6l-7 7" />
     </svg>:

             <svg
       className="h-5 w-5 text-red-500 absolute top-2 right-2"
       width="24"
       height="24"
       viewBox="0 0 24 24"
       strokeWidth="2"
       stroke="currentColor"
       fill="none"
       strokeLinecap="round"
       strokeLinejoin="round"

       onClick={()=>{addtoBook(product)}}
     >
       <path stroke="none" d="M0 0h24v24H0z" />
       <path fill={`${"none"}`} d="M12 20l-7 -7a4 4 0 0 1 6.5 -6a.9 .9 0 0 0 1 0a4 4 0 0 1 6.5 6l-7 7" />
     </svg>
         }
        <Link to={`/singleproduct/${product._id}`}>

        <img
          alt={product.imageAlt}
          src={product.pic[0]}
          className="h-96 w-80 object-cover object-center"
        />
            </Link>

      </div>
      <div className="mt-4 flex justify-between">
        <div>
          <h3 className="text-sm text-gray-700">
            <span aria-hidden="true" className="inset-0" />
            {product.name}
          </h3>
        </div>
      </div>
  </div>
))}



 
  </div>
</div>



{saw?

<div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
<div className='p-24 mt-12'>


<div class="flex flex-wrap w-full   shadow-2xl ">
<div class="w-1/2">
                <img class="hidden object-cover w-full h-screen md:block" src="/sign.jpeg"/>
            </div>
    <div class="flex flex-col w-96 md:w-1/2 relative bg-white">
    <svg class="h-8 w-8 text-black absolute top-0 right-0 "  fill="none" viewBox="0 0 24 24" stroke="currentColor"
    
    onClick={()=>{

      localStorage.setItem("seen", true)
      setSaw(false)
    }}
    >
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
</svg>

        <div class="flex flex-col justify-center px-8 pt-8 my-auto md:justify-start md:pt-0 md:px-24 lg:px-32">
            <p class="text-5xl font-extrabold text-center mb-8">
                Mountain Crafts
            </p>
            <p class="text-3xl text-center mb-2">
                Enjoy 15% off.
            </p>
            <p class="text-sm  text-center">
            Sign up now to receive exclusive updates and offers from Mountain Crafts. Enter your email below and get a 15% off coupon sent directly to your inbox!

By submitting this form, you agree to receive email marketing messages from Mountain Crafts at the provided email address. You can withdraw your consent at any time by following the unsubscribe instructions in any email we send. For inquiries, contact us at mountaincrafts@google.com.            </p>
            <div class="flex flex-col pt-3 md:pt-8">
                <div class="flex flex-col pt-4 mb-12">
                    <div class="flex relative ">
                        <span class=" inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                            <svg width="15" height="15" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z">
                                </path>
                            </svg>
                        </span>
                        <input type="text" id="design-login-email" class=" flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Email"
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                        />
                        </div>
                    </div>
                   
                        <button  class="w-full px-4 py-2 mb-4 text-base font-semibold text-center text-white transition duration-200 ease-in bg-black shadow-md hover:text-black hover:bg-white focus:outline-none focus:ring-2"
                        onClick={()=>{PostData()}}
                        >
                            <span class="w-full">
                                Sign Up
                            </span>
                        </button>
                    </div>
                    {/* <div class="pt-12 pb-12 text-center">
                        <p>
                            Don&#x27;t have an account?
                            <a href="#" class="font-semibold underline">
                                Register here.
                            </a>
                        </p>
                    </div> */}
                </div>
            </div>
           
        </div>
        </div>

</div>

:<></>}


    <div className='grid md:grid-cols-2 grid-cols-1 h-screen p-2 gap-2 mt-6'>
      {/* First Div */}
      <div id="wrapper-women" className="relative md:h-screen md:w-full h-full">
        {/* Background image */}
        <div
          className="absolute inset-0 bg-cover bg-top"
          style={{ backgroundImage: `url(/bbg.png)` }}
        >
          {/* Overlay */}
          <div className="absolute inset-0 bg-black opacity-10"></div>
        </div>
        {/* Content */}
        <div className="relative z-10 p-4 text-white">
          <h1 className="text-3xl font-bold md:text-center">Women</h1>
        </div>
        {/* Floating Button */}
       
       <Link to={`/productcategory/women`}> 
        <button
className={`absolute left-1/2 md:w-auto w-11/12 transform -translate-x-1/2 px-4 py-2 bg-black border-2 border-white text-white rounded shadow-lg z-20 transition-all duration-300 ease-in-out hover:border-4 hover:shadow-inner`}
style={{
            top: scrollYB > (wrapperWomenRef.current?.offsetHeight || 0) * 0.66 ? 'auto' : '66%',
            bottom: scrollYB > (wrapperWomenRef.current?.offsetHeight || 0) * 0.66 ? '0' : 'auto',
          }}

          
        >
          Explore Our Womens Collection
        </button>  
             </Link>

      </div>

      {/* Second Div */}
      <div id="wrapper-men" className="relative md:h-screen md:w-full  h-full">
        {/* Background image */}
        <div
          className="absolute inset-0 bg-cover bg-top"
          style={{ backgroundImage: `url(/bbm.png)` }}
        >
          {/* Overlay */}
          <div className="absolute inset-0 bg-black opacity-10"></div>
        </div>
        {/* Content */}
        <div className="relative z-10 p-4 text-white">
          <h1 className="text-3xl font-bold md:text-center">Men</h1>
        </div>
        {/* Floating Button */}

        <Link to={`/productcategory/men`}> 

        <button
className={`absolute left-1/2 md:w-auto w-11/12 transform -translate-x-1/2 px-4 py-2 bg-black border-2 border-white text-white rounded shadow-lg z-20 transition-all duration-300 ease-in-out hover:border-4 hover:shadow-inner`}
style={{
            top: scrollYB > (wrapperMenRef.current?.offsetHeight || 0) * 0.66 ? 'auto' : '66%',
            bottom: scrollYB > (wrapperMenRef.current?.offsetHeight || 0) * 0.66 ? '0' : 'auto',
          }}
        >
          Explore Our Mens Collection
        </button>
        </Link>
      </div>
    </div>

    <div className='grid md:grid-cols-2 grid-cols-1 h-screen p-2 gap-2 md:mt-6'>
      {/* First Div */}
      <div id="wrapper-women" className="relative md:h-screen md:w-full h-full">
        {/* Background image */}
        <div
          className="absolute inset-0 bg-contain bg-center bg-no-repeat"
          style={{ backgroundImage: `url(/bp.png)` }}
        >
          {/* Overlay */}
          <div className="absolute inset-0 bg-black opacity-20"></div>
        </div>
        {/* Content */}
        <div className="relative z-10 p-4 text-white">
          <h1 className="text-3xl font-bold text-center">Bags</h1>
        </div>
        {/* Floating Button */}

        <Link to={`/productcategory/bag`}> 

        <button
className={`absolute left-1/2 w-11/12 md:w-auto transform -translate-x-1/2 px-4 py-2 bg-black border-2 border-white text-white rounded shadow-lg z-20 transition-all duration-300 ease-in-out hover:border-4 hover:shadow-inner`}
style={{
            top: scrollYB > (wrapperWomenRef.current?.offsetHeight || 0) * 0.66 ? 'auto' : '66%',
            bottom: scrollYB > (wrapperWomenRef.current?.offsetHeight || 0) * 0.66 ? '0' : 'auto',
          }}
        >
          Explore our Exotic Bag collection
        </button>

        </Link>
      </div>

      {/* Second Div */}
      <div id="wrapper-men" className="relative md:h-screen md:w-full h-full">
        {/* Background image */}
        <div
          className="absolute inset-0 bg-cover bg-top"
          style={{ backgroundImage: `url(/decore.png)` }}
        >
          {/* Overlay */}
          <div className="absolute inset-0 bg-black opacity-20"></div>
        </div>
        {/* Content */}
        <div className="relative z-10 p-4 text-white">
          <h1 className="text-3xl font-bold text-center">Home Decore</h1>
        </div>
        {/* Floating Button */}
        <Link to={`/productcategory/decore`}> 

        <button
className={`absolute left-1/2 md:w-auto w-11/12 transform -translate-x-1/2 px-4 py-2 bg-black border-2 border-white text-white rounded shadow-lg z-20 transition-all duration-300 ease-in-out hover:border-4 hover:shadow-inner`}
style={{
            top: scrollYB > (wrapperMenRef.current?.offsetHeight || 0) * 0.66 ? 'auto' : '66%',
            bottom: scrollYB > (wrapperMenRef.current?.offsetHeight || 0) * 0.66 ? '0' : 'auto',
          }}
        >
          Explore Our Home Decore Collection
        </button>
        </Link>
      </div>
    </div>





 

{/* <div className=" inset-x-0  top-1/2 flex items-center justify-center ">
    <div className=" md:w-1/2 w-full ">
      
<div class="relative w-full overflow-hidden bg-white  shadow-xl  rounded-t-lg">

      <div class="z-100 grid md:grid-cols-4 gap-2 grid-cols-3 justify-items-center place-items-center  px-4  py-6 ">

      <Link to="/productcategory/Coats">
<span class="px-4 py-2 flex items-center text-base rounded-full text-white  bg-lime-600 ">
<svg class="h-6 w-6 text-white"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M15 4l6 2v5h-3v8a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1v-8h-3v-5l6 -2a3 3 0 0 0 6 0" /></svg>
Coats
</span>

          </Link>   


          <Link to="/productcategory/Bags">

<span class="px-4 py-2 flex items-center text-base rounded-full text-white  bg-lime-600  ">
<svg class="h-6 w-6 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"/>
</svg>

  Bags
</span>
</Link>
<Link to="/productcategory/Home Decore">

<span class="px-4 py-2 flex items-center text-base rounded-full text-white  bg-lime-600  ">
<svg class="h-6 w-6 text-white mr-1"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <rect x="2" y="7" width="20" height="15" rx="2" ry="2" />  <polyline points="17 2 12 7 7 2" /></svg>


  Decore
</span>
</Link>

<Link to="/productcategory/Shawl">

<span class="px-4 md:ml-0 ml-12 py-2 flex items-center text-base rounded-full text-white  bg-lime-600 cursor-pointer   "

>
<svg class="h-6 w-6 text-white mr-1"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z" /></svg>

Shawl/Stole
</span>

</Link>
          
      </div>
     
  
</div>

    <div className="w-full bg-white shadow-xl  border-none rounded-b-lg h-16 bg-opacity-100 hover:bg-opacity-100 p-4  focus:outline-none focus:border-white active:outline-none flex focus:outline-none justify-between items-center relative">
<input type="text" name="search" id="search" placeholder={  'Search'} 
    className=" 
    rounded-lg border-lg flex-1 mb-6 appearance-none border border-gray-200 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lime-600 focus:border-transparen
    w-full bg-white border-lg ring-lime-600 rounded-lg h-16 bg-opacity-100 hover:bg-opacity-100 p-4  focus:outline-none focus:border-none active:outline-none"
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
    // value= {search} onChange={(e)=>fetchPost(e.target.value)}
    />
<button type="submit" className="ml-1 mb-6 outline-none border-none focus:border-none  active:outline-none  ">

<svg class="h-8 w-8 text-lime-600  transition-transform duration-300 hover:rotate-12"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="11" cy="11" r="8" />  <line x1="21" y1="21" x2="16.65" y2="16.65" /></svg>
</button>


</div>
<div class="container flex flex-col items-center justify-center w-full mx-auto bg-white rounded-lg shadow-lg ">
    <ul class="flex flex-col divide-y divide">
    {results.map((result) => {
            return(
          
            <li class="flex flex-row">
                             <a href={`/singleproduct/${result._id}`} class="relative block">
   <div class="flex items-center flex-1 p-4 cursor-pointer select-none">
                <div class="flex flex-col items-center justify-center w-10 h-10 mr-4">
                        <img alt="profil" src={result.pic[0]} class="mx-auto object-cover rounded-sm h-10 w-10 "/>
                </div>
                <div class="flex-1 pl-1 mr-16">
                    <div class="font-medium ">
                     {result.name}
                    </div>
                    <div class="text-sm text-gray-600 ">
                        {result.description}
                    </div>
                </div>
                <div class="text-xs text-gray-600 ">
                    {result.category}
                </div>
            </div>                    </a>

        </li>
            )
        }
        )}
      
      
       
    </ul>
</div>

    </div>
  </div>
        

    <div class="flex flex-row items-center m-6 justify-center">
 
 <p className='font-sans subpixel-antialiased font-bold md:text-5xl text-3xl text-lime-600 '> 
 
     Explore Our Collection 
     
        </p> 

    </div>
    <div class="flex flex-row items-center justify-center mb-1">
 
    <img class="md:w-2/5 rounded-lg shadow-lg  h-56 mr-1 hover:brightness-75  transition-transform duration-300 transform hover:scale-95" width="200" src="womban.png" alt="1"/>
    <img class="md:w-2/5 rounded-lg shadow-lg  h-56 mr-1 hover:brightness-75  transition-transform duration-300 transform hover:scale-95 " width="200" src="menban.png" alt="1"/>


    </div>
    <div class="flex flex-row items-center justify-center">
 
    <img class="md:w-2/5 rounded-lg shadow-lg  h-56 mr-1 hover:brightness-75  transition-transform duration-300 transform hover:scale-95" width="200" src="hdban.png" alt="1"/>
    <img class="md:w-2/5 rounded-lg shadow-lg  h-56 mr-1 hover:brightness-75  transition-transform duration-300 transform hover:scale-95" width="200" src="bagban.png" alt="1"/>


    </div>
 */}




{/* 
    <div class="w-full  bg-white mt-2">
    <div class=" mb-12 ">
  
     
        </div>
       
        <div className="grid  grid-cols-2  md:grid-cols-5 gap-4   mt-4    ">

        {data?data.slice(0,8).map(Item=>{

return(


  <div class="md:w-64 w-48 m-auto  bg-white   rounded-2xl">
      <Link to={`/singleproduct/${Item._id}`}>

    <div className="flex justify-center items-center ">

   
<img className='h-56 w-52 ' src={Item.pic[0]}/>


    </div>

  <div class="p-1 ml-5 bg-white rounded-lg">
      <p class="text-lg font-bold text-black ">
      {Item.name}
      </p>
    
      <div class="flex items-center justify-between ">
          <p class="text-black">
          Nu. {Item.rate}          </p>
          
        
      
        


       
      </div>
  </div>
  </Link>

</div>




)
}):<></>}




</div>

        <div class="mt-6  flex justify-center items-center">
 <button onClick={()=>history('/shop')} type="button" class="py-2 md:w-1/3 w-full h-14 px-4 flex justify-center items-center border-lime-300  bg-none hover:bg-lime-500 focus:ring-lime-600 focus:ring-offset-lime-200 text-lime-600 hover:text-white  transition ease-in duration-200 text-center text-base  border-2 border-lime-500 font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
  
     Explore More Products
     <svg class="h-8 w-8 "  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polyline points="13 17 18 12 13 7" />  <polyline points="6 17 11 12 6 7" /></svg>
 </button>
 </div>
    </div>
 */}

  





{/* <div class="relative max-w-screen-xl p-4 px-4 mx-auto bg-white  sm:px-6 lg:px-8 py-26 lg:mt-20">
    <div class="relative">
        <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div class="ml-auto lg:col-start-2 lg:max-w-2xl">
              
                <h4 class="mt-2 text-2xl font-extrabold leading-8 text-gray-900  sm:text-3xl sm:leading-9">
                What makes our products unique?
                </h4>
                <p class="mt-4 text-lg leading-6 text-gray-500 ">
                Mountain Crafts is a platform for showcasing and marketing exotic Bhutanese products handcrafted by rural communities in Bhutan.

</p>
                <ul class="gap-6 mt-8 md:grid md:grid-cols-2">
                    <li class="mt-6 lg:mt-0">
                        <div class="flex">
                            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full  ">
                                <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                            <span class="ml-4 text-base font-medium leading-6 text-gray-500 ">
                            Composed of natural fibers and fabrics 
                            </span>
                        </div>
                    </li>
                    <li class="mt-6 lg:mt-0">
                        <div class="flex">
                            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full  ">
                                <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                            <span class="ml-4 text-base font-medium leading-6 text-gray-500 ">
                            Produced in an environmentally friendly manner
                            </span>
                        </div>
                    </li>
                    <li class="mt-6 lg:mt-0">
                        <div class="flex">
                            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full  ">
                                <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                            <span class="ml-4 text-base font-medium leading-6 text-gray-500 ">
                            Includes use of natural colors and dyes (plant-based)                            </span>
                        </div>
                    </li>
                    <li class="mt-6 lg:mt-0">
                        <div class="flex">
                            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full  ">
                                <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                            <span class="ml-4 text-base font-medium leading-6 text-gray-500 ">
                            Woven by rural/indigenous women based on multi-generational traditions                            </span>
                        </div>
                    </li>
                    <li class="mt-6 lg:mt-0">
                        <div class="flex">
                            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full  ">
                                <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                            <span class="ml-4 text-base font-medium leading-6 text-gray-500 ">
                            Provides income for rural communities                            
                                                      </span>
                        </div>
                    </li>
                    <li class="mt-6 lg:mt-0">
                        <div class="flex">
                            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full  ">
                                <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                            <span class="ml-4 text-base font-medium leading-6 text-gray-500 ">
                            Supports research and documentation of indigenous crafts production                            
                                                  </span>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="relative mt-10 lg:-mx-4 relative-20 lg:mt-0 lg:col-start-1">
                <div class="relative space-y-4">
                    <div class="flex items-end justify-center space-x-4 lg:justify-start">
                        <img data-aos="fade-in" data-aos-duration="1500" class="w-32 rounded-lg shadow-lg md:w-56" width="200" src="https://mountaincrafts.bt/wp-content/uploads/2022/03/IMG_7110-scaled-300x525.jpg" alt="1"/>
                        <img data-aos="fade-in" data-aos-duration="1500" class="w-40 h-64 rounded-lg shadow-lg md:w-64" width="260" src="https://mountaincrafts.bt/wp-content/uploads/2022/03/Bumthang-wool-rug-scaled-300x525.jpeg" alt="2"/>
                    </div>
                    <div class="flex items-start justify-center ml-12 space-x-4 lg:justify-start">
                        <img data-aos="fade-in" data-aos-duration="1500" class="w-24 rounded-lg shadow-lg md:w-40" width="170" src=" https://mountaincrafts.bt/wp-content/uploads/2022/03/IMG-20220304-WA0000-300x525.jpg" alt="3"/>
                        <img data-aos="fade-in" data-aos-duration="1500" class="w-32 rounded-lg shadow-lg md:w-56" width="200" src="https://mountaincrafts.bt/wp-content/uploads/2022/03/IMG_7141-scaled-300x525.jpg" alt="4"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  */}






{/* <h4 class="mt-8 text-2xl text-center font-extrabold leading-8 text-gray-900  sm:text-3xl sm:leading-9">
                What makes our products unique?
                </h4>
                <p class="mt-4 text-lg text-center leading-6 text-gray-500 ">
                Mountain Crafts is a platform for showcasing and marketing exotic Bhutanese products handcrafted by rural communities in Bhutan.

</p>


<div className=" w-full p-4 mx-auto shadow-lg rounded-2xl">
      <div className="relative h-96 overflow-hidden">
        {features.map((feature, index) => (
          <div
            key={index}
            className={`absolute top-0 left-0 w-full h-full transition-opacity duration-500 ease-in-out ${
              index === currentIndex ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <img
              src={feature.image}
              alt={feature.title}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 text-white">
              <h2 className="text-2xl font-bold">{feature.title}</h2>
              <p className="text-lg">{feature.description}</p>
            </div>
          </div>
        ))}

        <button
          className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r-full z-10"
          onClick={prevSlide}
        >
          &#10094;
        </button>

        <button
          className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l-full z-10"
          onClick={nextSlide}
        >
          &#10095;
        </button>
      </div>

      <div className="flex justify-center mt-4">
        {features.map((_, index) => (
          <div
            key={index}
            className={`h-2 w-2 rounded-full mx-1 cursor-pointer ${
              currentIndex === index ? 'bg-blue-500' : 'bg-gray-300'
            }`}
            onClick={() => setCurrentIndex(index)}
          ></div>
        ))}
      </div>
    </div> */}







<div class="container p-6 px-6 mx-auto bg-white ">
    <div class="mb-16 text-center">
    <h4 class="mt-8 text-2xl font-extrabold leading-8 text-gray-900  sm:text-3xl sm:leading-9">
                What makes our products unique?
                </h4>
                <p class="mt-4 text-lg leading-6 text-gray-500 ">
                Mountain Crafts is a platform for showcasing and marketing exotic Bhutanese products handcrafted by rural communities in Bhutan.

</p>
    </div>
    <div class="flex flex-wrap my-12 ">
        <div class="w-full p-8 border-b md:w-1/2 lg:w-1/3 lg:border-r">
            <div class="flex items-center mb-6 ">
            <span class="flex  items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full  ">
                                <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                <div class="ml-4 text-xl">
                Composed of natural fibers 

                </div>
            </div>
            {/* <p class="leading-loose text-gray-500  text-md">
                Receive more sales by selling across multple sales channels instead of just having a single point of entry.
            </p> */}
        </div>
        <div class="w-full p-8 border-b md:w-1/2 lg:w-1/3 lg:border-r">
            <div class="flex items-center mb-6">
            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full  ">
                                <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                <div class="ml-4 text-xl">
                Includes use of natural colors and dyes (plant-based)
                </div>
            </div>
            {/* <p class="leading-loose text-gray-500  text-md">
                Apply beautiful overlays to every product image distributed through our platform. A visual touch.
            </p> */}
        </div>
        <div class="w-full p-8 border-b md:w-1/2 md:border-r lg:w-1/3 lg:border-r-0">
            <div class="flex items-center mb-6">
            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full  ">
                                <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                <div class="ml-4 text-xl">
                Provides income for rural communities
                </div>
            </div>
            {/* <p class="leading-loose text-gray-500  text-md">
                Apply filters and control which products to sell on each sales channel. E.g. exclude products with low margins.
            </p> */}
        </div>
        <div class="w-full p-8 border-b md:w-1/2 lg:w-1/3 lg:border-r lg:border-b-0">
            <div class="flex items-center mb-6">
            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full  ">
                                <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                <div class="ml-4 text-xl">
                Produced in an environmentally friendly manner
                </div>
            </div>
            {/* <p class="leading-loose text-gray-500  text-md">
                Map product categories with each sales channels&#x27; own categories and achieve better results and lower costs.
            </p> */}
        </div>
        <div class="w-full p-8 border-b md:w-1/2 md:border-r md:border-b-0 lg:w-1/3 lg:border-b-0">
            <div class="flex items-center mb-6">
            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full  ">
                                <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                <div class="ml-4 text-xl">
                Woven by rural/indigenous women based on multi-generational traditions
                </div>
            </div>
            {/* <p class="leading-loose text-gray-500  text-md">
                Modify products with extra properties and achieve the maximum output for each installed sales channel.
            </p> */}
        </div>
        <div class="w-full p-8 md:w-1/2 lg:w-1/3">
            <div class="flex items-center mb-6">
            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full  ">
                                <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                <div class="ml-4 text-xl">
                Supports research and documentation of indigenous crafts production
                </div>
            </div>
            {/* <p class="leading-loose text-gray-500  text-md">
                Build unique product titles and descriptions instead of spending days manually editing each product.
            </p> */}
        </div>
    </div>
</div>

{/* https://cdn.pixabay.com/photo/2021/11/15/05/25/boutique-6796399_1280.jpg */}
<div class="relative h-5/6 overflow-hidden bg-stone-200">
    <img src="https://media.vogue.co.uk/photos/620fdcef581cc0e0c9de0fe1/16:9/w_6190,h_3482,c_limit/sustainable_fashion_1802.jpg
" class="absolute object-cover w-full h-full"/>
    <div className="absolute inset-0 bg-black opacity-30"></div>

  
    <div class="container relative z-10 flex items-center  px-6 py-32 mx-auto md:px-12 xl:py-64">
        <div class="relative z-10 flex flex-col items-center w-full">
            <h1 class="mt-4 font-extrabold uppercase leading-tight text-center text-gray-200 text-3xl ">
            Sustainable luxury for all
            </h1>
            <h1 class="mt-4  leading-tight text-center text-gray-100 text-xl ">
            Here’s how we bring you high-quality products with minimal environmental impact.

</h1>
            <a href="/sustainable" class="block rounded-lg px-4 py-3 mt-24 text-lg font-bold text-white uppercase bg-orange-500 hover:bg-orange-600">
                Sustainability
            </a>
        </div>
    </div>
</div>

<div className="bg-[#E8E8D8] py-32">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 text-center divide-black md:divide-x">
        {/* Item 1 */}
        <div className="flex flex-col items-center">
        <svg class="h-16 w-16 "  width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="7" cy="17" r="2" />  <circle cx="17" cy="17" r="2" />  <path d="M5 17h-2v-4m-1 -8h11v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5" />  <line x1="3" y1="9" x2="7" y2="9" /></svg>
          <p className="text-md font-sm">
            GLOBAL SHIPPING AND 30-DAY EASY RETURNS.
          </p>
        </div>

        {/* Item 2 */}
        <div className="flex flex-col items-center">
        <svg class="h-16 w-16 "  width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17.905 13.057c2.208 1.756 3.436 3.308 3.012 4.035-.67 1.146-5.204-.204-10.129-3.016-4.924-2.812-8.374-6.022-7.705-7.168.418-.716 2.347-.458 4.936.524" />  <circle cx="12" cy="12" r="6" /></svg>
          <p className="text-md font-sm">
            SUSTAINABLE MATERIALS AND ETHICAL PRACTICES.
          </p>
        </div>

        {/* Item 3 */}
        <div className="flex flex-col items-center">
        <svg class="h-16 w-16 "  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"/>
</svg>
       
          <p className="text-md font-sm">
            OUR MODEL MINIMIZES WASTE TO REDUCE COST.
          </p>
        </div>
      </div>
    </div>

<div id="about" data-aos="fade-up" data-aos-duration="800" class="p-6 m-6 bg-white  flex flex-col md:flex-row overflow-hidden shadow-lg rounded-2xl "
>


    <div class="text-center md:w-full py-12 px-4 px-6 lg:py-16 lg:px-8 z-20 ">
        <h2 class="text-3xl font-extrabold text-gray-800  text-2xl">
            <span class="block">
                Our Mission
            </span>
            {/* <span class="block text-indigo-500">
            Crafting excellence with every cut: your one-stop woodworking solution
            </span> */}
        </h2>
        <p class="text-xl  mt-4 text-gray-600">
        Mountain Crafts seeks to provide rural comunities in Bhutan access to regional and international markets and clients around the world. Its overall mission is to promote cultural preservation, stimulate passion for nature-based products and support sustainable business that benefits first producer communities by preserving Bhutanese indigenous art and crafts while at the same time, providing global access to eco-friendly and exotic natural products from the highlands of the Himalayas.


        
          </p>
        <div class="lg:mt-0 lg:flex-shrink-0">
            <div class="mt-12 inline-flex rounded-md shadow">
                <a href="/contact">

                   <button type="button" class="py-4 px-6  bg-orange-500 hover:bg-orange-600 focus:ring-orange-500 focus:ring-offset-orange-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                    Contact Us
                </button> 
                </a>
                
            </div>
        </div>
    </div>

    {/* <img src="about.png" class="  h-80 w-1/3 md:ml-14 mt-12"/> */}
    {/* <img src="/left.png" alt="btc logo" class="absolute  h-full    -right-24 md:-right-6"/>
    <img src="/right.png" alt="btc logo" class="absolute w-46 h-full    -left-24 md:-left-6"/> */}

</div>
{/* p-6 m-6 bg-white  flex flex-col md:flex-row overflow-hidden  */}


<div class="p-8 bg-white rounded-lg shadow ">
    <p class="text-3xl font-bold text-center text-gray-800 ">
    Testimonials
    </p>
    <p class="mb-12 text-xl font-normal text-center text-gray-500 ">
              Listen what our customers has to say
   </p>
    
<div class="flex flex-col items-center w-full gap-4 p-8 mb-8 md:flex-row md:mb-0 flex-between">
    <div class="bg-white  w-72 shadow-lg mx-auto rounded-xl p-4">
        <p class="text-gray-600 ">
            <span class="text-lg font-bold text-indigo-500">
                “
            </span>
            Finally, I own a good design Nettle plant product which I wanted for a long time!
            <span class="text-lg font-bold text-indigo-500">
                ”
            </span>
        </p>
        <div class="flex items-center mt-4">
            <a href="#" class="relative block">
                <img alt="profil" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToK4qEfbnd-RN82wdL2awn_PMviy_pelocqQ&s" class="mx-auto object-cover rounded-full h-10 w-10 "/>
            </a>
            <div class="flex flex-col justify-between ml-2">
                <span class="text-sm font-semibold text-indigo-500">
                Chartlada Sangakji
                </span>
                <span class="flex items-center text-xs dark:text-gray-400">
                Thailand
                </span>
            </div>
        </div>
    </div>
    <div class="bg-white  w-72 shadow-lg mx-auto rounded-xl p-4">
        <p class="text-gray-600 ">
            <span class="text-lg font-bold text-indigo-500">
                “
            </span>
            The nicest designs I have seen integrating traditional fabric, designs and skill into modern clothes and more
            <span class="text-lg font-bold text-indigo-500">
                ”
            </span>
        </p>
        <div class="flex items-center mt-4">
            <a href="#" class="relative block">
                <img alt="profil" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToK4qEfbnd-RN82wdL2awn_PMviy_pelocqQ&s" class="mx-auto object-cover rounded-full h-10 w-10 "/>
            </a>
            <div class="flex flex-col justify-between ml-2">
                <span class="text-sm font-semibold text-indigo-500">
                Neil Buhne
                </span>
                <span class="flex items-center text-xs dark:text-gray-400">
                Switzerland
                </span>
            </div>
        </div>
    </div>
    <div class="bg-white  w-72 shadow-lg mx-auto rounded-xl p-4">
        <p class="text-gray-600 ">
            <span class="text-lg font-bold text-indigo-500">
                “
            </span>
            The Mountain Crafts jackets are a powerhouse of warmth and style! The craftsmanship is top-notch, and it kept me warm and comfortable during my recent trip to Europe. The unique design turns heads everywhere I go. If you're looking for style and functionality, look no further!                        <span class="text-lg font-bold text-indigo-500">
                ”
            </span>
        </p>
        <div class="flex items-center mt-4">
            <a href="#" class="relative block">
                <img alt="profil" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToK4qEfbnd-RN82wdL2awn_PMviy_pelocqQ&s" class="mx-auto object-cover rounded-full h-10 w-10 "/>
            </a>
            <div class="flex flex-col justify-between ml-2">
                <span class="text-sm font-semibold text-indigo-500">
                Tandin Bidha, Bhutanese actor, storyteller and a filmmaker            </span>
                
                <span class="flex items-center text-xs dark:text-gray-400">
                Bhutan
                </span>
            </div>
        </div>
    </div>
</div>

</div>

  
<div class="w-full shadow-2xl">
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3867.83499931718!2d89.64822947569262!3d27.453029036662045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e19315028b9fd5%3A0xfe77d0bace8a1647!2sMountain%20Crafts%20Bhutan!5e1!3m2!1sen!2sbt!4v1725992548100!5m2!1sen!2sbt"              
                  height="350"
                  class="object-contain w-full"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                />             </div>





<Footer page="home"></Footer>
</main>


);


  
}


export default HomePageCustomer