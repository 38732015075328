
import React,{useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import Header from './Header';

import {UserContex} from '../App'
import Footer from './Footer';


const Team = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[skills,setSkills]=useState([])
  const[docs,setDocs]=useState([])
  const[salary,setSalary]= useState("")
  const [dob,setDob]= useState(new Date())
  const [url,setUrl] = useState("")  

  const PostData =()=>{

   
//     fetch("/signinemployee",{
//       method:"post",
//       headers:{
//           "Content-Type":"application/json"
//       },
//       body: JSON.stringify({
        
//           password,
//           email:email
//       })
      
//   }).then(res=>res.json())
//   .then(data=> {
     
//       if(data.error){
//         toast.error(data.error)

//       }
//       else{
//         console.log("Login Successful")
//           localStorage.setItem("jwt", data.token)
//           localStorage.setItem("user", JSON.stringify( data.user))
//           dispatch({type:"USER", payload:data.user})
//           history.push('/dashboard')
//       }
//   })
//   .catch(err=>{
//       console.log(err)
//   })

       }

    return(


<>
<Header></Header>


<div class="p-4">
 
<div class="flex flex-wrap w-full">
    
   
    

  
    



            </div>



            <h1 id='team' class=" text-2xl mt-20 text-center font-black leading-none text-gray-800 uppercase font-bebas-neue  ">
    Our Team
    </h1>


<div class="px-4 py-10 bg-lightblue">
    <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
    <img alt="profil" src="/seet.jpeg" class="mx-auto object-cover rounded-lg h-72 w-72  border-2 border-white "/>

        <dl class="w-full md:w-2/3">
            <dt class="mb-4">
                <h3 class="text-xl font-semibold">
                Seeta Giri, Founder and Creative Director                 </h3>
            </dt>
            <dd class="mb-16">
                <p>
                As the founder of Mountain Crafts Bhutan, Seeta Giri is a visionary entrepreneur dedicated to preserving Bhutanese indigenous arts and crafts. With a passion for sustainable development, she empowers rural communities by connecting artisans to global markets. Seeta's innovative approach combines cultural preservation with eco-friendly practices, showcasing Bhutan's exquisite handcrafted products to the world. 
                </p>
            </dd>
           
        </dl>
    </div>
</div>
<div class="px-4 py-10  bg-lightblue">
    <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
    <img alt="profil" src="/bini.jpeg" class="mx-auto object-cover rounded-lg h-72 w-72  border-2 border-white "/>

        <dl class="w-full md:w-2/3">
            <dt class="mb-4">
                <h3 class="text-xl font-semibold">
                Binisha Giri, Global Sales and Marketing Director                 </h3>
            </dt>
            <dd class="mb-16">
                <p>
                Binisha spearheads international expansion of handcrafted Bhutanese products. With a passion for cultural preservation and women's empowerment, she drives sustainable business practices that benefit rural communities. Binisha's leadership has successfully launched Mountain Crafts' products onto the global stage, bridging traditional craftsmanship with modern markets.
                </p>
            </dd>
           
        </dl>
    </div>
</div>
<div class="px-4 py-10  bg-lightblue">
    <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
    <img alt="profil" src="/rimi.jpeg" class="mx-auto object-cover rounded-lg h-72 w-72  border-2 border-white "/>

        <dl class="w-full md:w-2/3">
            <dt class="mb-4">
                <h3 class="text-xl font-semibold">
                Rimmy Parijeet Kaur, Partner                 </h3>
            </dt>
            <dd class="mb-16">
                <p>
                A dynamic partner at Mountain Crafts Bhutan, brings her expertise as a Costume & Art Designer from New Delhi to the realm of Bhutanese handicrafts. Specializing in handbag design and production, she blends contemporary aesthetics with traditional Bhutanese artistry. Rimmy's innovative approach helps bridge cultural preservation with modern market demands, enhancing Mountain Crafts' mission to empower rural artisans and showcase Bhutan's exquisite craftsmanship globally.
                </p>
            </dd>
           
        </dl>
    </div>
</div>
<div id="about"   class="p-6 m-6 bg-white  flex flex-col md:flex-row overflow-hidden shadow-lg rounded-2xl "
>


    <div class="text-center md:w-full py-12 px-4 px-6 lg:py-16 lg:px-8 z-20 ">
        <h2 class="text-3xl font-extrabold text-gray-800  text-2xl">
            <span class="block">
            Local Community Partners            </span>
            
        </h2>
        <p class="text-xl  mt-4 text-gray-600">
        At Mountain Crafts, we proudly source our materials from various regions in Bhutan, ensuring authenticity and quality. Our Himalayan Nettle fabric is harvested from Trongsa, Lhuntse, and Samdrup Jongkhar, showcasing the strength and versatility of this unique fiber. The highland sheep and yak wool used in our products comes from Bumthang, Trongsa, Trashigang, and Samtse, providing warmth and sustainability through traditional animal husbandry practices. Additionally, our silk and cotton fabrics are sourced from Lhuntse, Trashigang, Pemagatshel, and Thimphu, supporting local artisans and preserving Bhutanese craftsmanship. Each material reflects our commitment to eco-friendly practices and cultural heritage.


        
          </p>
    
    </div>

  
</div>


<div class="p-8 bg-white rounded-lg  ">
    <p class="text-3xl font-bold text-center text-gray-800 ">
        Studio Team
    </p>
   
<div class="p-4">
    
    <p class="mb-32 text-xl font-normal text-center text-gray-500">
        Meet our dedicated studio team
    </p>
    <div class="grid md:grid-cols-3 grid-cols-1 items-center space-y-24 md:space-y-0 md:flex-row justify evenly">
        <div class="relative p-4">
            <div class="absolute mb-4 text-center transform translate-x-1/2 -top-16 right-1/2">
                <a href="#" class="relative block">
                    <img alt="profil" src="https://res.cloudinary.com/daehmzcuc/image/upload/v1722225475/pdf/uploadimage-1722225474874.jpeg.jpg" class="mx-auto object-cover rounded-lg h-40 w-40  border-2 border-white "/>
                </a>
            </div>
            <div class="px-8 py-4 pt-24 bg-white rounded-lg shadow ">
                <div class="text-center">
                    <p class="text-2xl text-gray-800 ">
                    Tshering Dolkar


                    </p>
                    <p class="text-xl font-light text-gray-500 ">
                    Store Manager 
                    </p>
                    
                </div>
               
            </div>
        </div>
        <div class="relative p-4">
            <div class="absolute mb-4 text-center transform translate-x-1/2 -top-16 right-1/2">
                <a href="#" class="relative block">
                    <img alt="profil" src="https://res.cloudinary.com/daehmzcuc/image/upload/v1722225137/pdf/uploadimage-1722225136356.jpeg.jpg" class="mx-auto object-cover rounded-lg h-40 w-40  border-2 border-white "/>
                </a>
            </div>
            <div class="px-8 py-4 pt-24 bg-white rounded-lg shadow ">
                <div class="text-center">
                    <p class="text-2xl text-gray-800 ">
                    Pema Yangchen


                    </p>
                    <p class="text-xl font-light text-gray-500 ">
                    Tailor
                    </p>
                    
                </div>
             
            </div>
        </div>
        <div class="relative p-4">
            <div class="absolute mb-4 text-center transform translate-x-1/2 -top-16 right-1/2">
                <a href="#" class="relative block">
                    <img alt="profil" src="https://res.cloudinary.com/dthdfdatk/image/upload/v1724907529/pdf/uploadimage-1724907527734.jpeg.jpg" class="mx-auto object-cover rounded-lg h-40 w-40  border-2 border-white "/>
                </a>
            </div>
            <div class="px-8 py-4 pt-24 bg-white rounded-lg shadow ">
                <div class="text-center">
                    <p class="text-2xl text-gray-800 ">
                    Partiman Darjee
                    </p>
                    <p class="text-xl font-light text-gray-500 ">
                    Tailor
                    </p>
                   
                </div>
              
            </div>
        </div>
    </div>
</div>

</div>


<div className='bg-gray-50'>

<div class="container p-6 px-6 mx-auto ">
    <div class="mb-16 text-center">
       
        <p class=" text-3xl font-extrabold leading-8 tracking-tight text-gray-900  sm:text-4xl">
        Partners of Mountain Crafts:
        </p>
    </div>
    <div class="flex  my-12 ">

        <div className='w-full  border-b md:w-1/2 md:border-r'>
        <h1 className='text-center text-xl font-extrabold p-4'>
        SABAH Bhutan
        </h1>
        <p className='text-center p-4'>
        
        Established in 2010, is an NGO dedicated to uplifting home-based weavers in Bhutan. Their mission focuses on enhancing and marketing traditional skills, effectively reviving ancient art forms and designs. By providing a platform for these artisans, SABAH Bhutan not only preserves Bhutanese cultural heritage but also empowers local communities economically. Their partnership with Mountain Crafts Bhutan amplifies the reach and impact of both organizations in promoting sustainable, artisanal craftsmanship.

</p>
        </div>
        <div className='w-full  border-b md:w-1/2 md:border-r'>
        <h1 className='text-center text-xl font-extrabold p-4'>
        Tribal Trust Foundation (TTF)        </h1>
        <p className='text-center p-4'>
        Founded in 1996, is committed to supporting and preserving indigenous cultures worldwide. Their approach involves identifying and nurturing sustainable grassroots cultural preservation projects. TTF's strength lies in its strategic partnerships with local organizations that have deep-rooted connections with specific tribes. This collaborative model ensures long-term sustainability of initiatives aimed at preserving living arts and traditional wisdom. Through their partnership with Mountain Crafts Bhutan, TTF extends its mission to the unique cultural landscape of Bhutan.


        </p>
        </div>
        {/* <div class="w-full p-8 border-b md:w-1/2 md:border-r lg:w-1/3">
            <div class="flex items-center mb-6">
                <svg width="20" height="20" fill="currentColor" class="w-6 h-6 text-indigo-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                    </path>
                </svg>
                <div class="ml-4 text-xl">
                    Increase sales
                </div>
            </div>
            <p class="leading-loose text-gray-500 dark:text-gray-200 text-md">
                Receive more sales by selling across multple sales channels instead of just having a single point of entry.
            </p>
        </div> */}
        {/* <div class="w-full p-8 border-b md:w-1/2 lg:w-1/3 lg:border-r">
            <div class="flex items-center mb-6">
                <svg width="20" height="20" fill="currentColor" class="w-6 h-6 text-indigo-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                    </path>
                </svg>
                <div class="ml-4 text-xl">
                    Overlays
                </div>
            </div>
            <p class="leading-loose text-gray-500 dark:text-gray-200 text-md">
                Apply beautiful overlays to every product image distributed through our platform. A visual touch.
            </p>
        </div> */}
     
    </div>
</div>
</div>



</div>

<Footer></Footer>


</>
  


    );
}

export default Team;