
import React,{useContext, useEffect, useState} from 'react'
import {Link, useN, useNavigate} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import Header from './Header';
import 'react-toastify/dist/ReactToastify.css';

import {UserContex} from '../App'
import Footer from './Footer';

import { loadStripe } from '@stripe/stripe-js';

const shippingData = {
    ems: 
    [
        {
          "destination": "India",
          "rates": {
            "0.25kg": {"NU": 2465, "usd": 30},
            "0.5kg": {"NU": 2595, "usd": 32},
            "1kg": {"NU": 2855, "usd": 35},
            "2kg": {"NU": 3375, "usd": 41},
            "3kg": {"NU": 3895, "usd": 48},
            "4kg": {"NU": 4415, "usd": 54},
            "5kg": {"NU": 4935, "usd": 60}
          }
        },
        {
          "destination": "Nepal",
          "rates": {
            "0.25kg": {"NU": 2305, "usd": 28},
            "0.5kg": {"NU": 2385, "usd": 29},
            "1kg": {"NU": 2545, "usd": 31},
            "2kg": {"NU": 2865, "usd": 35},
            "3kg": {"NU": 3185, "usd": 39},
            "4kg": {"NU": 3505, "usd": 43},
            "5kg": {"NU": 3825, "usd": 47}
          }
        },
        {
          "destination": "Bangladesh",
          "rates": {
            "0.25kg": {"NU": 2535, "usd": 31},
            "0.5kg": {"NU": 2785, "usd": 34},
            "1kg": {"NU": 3285, "usd": 40},
            "2kg": {"NU": 4285, "usd": 52},
            "3kg": {"NU": 5285, "usd": 64},
            "4kg": {"NU": 6285, "usd": 77},
            "5kg": {"NU": 7285, "usd": 89}
          }
        },
        {
          "destination": "Sri Lanka",
          "rates": {
            "0.25kg": {"NU": 2530, "usd": 31},
            "0.5kg": {"NU": 2780, "usd": 34},
            "1kg": {"NU": 3280, "usd": 40},
            "2kg": {"NU": 4280, "usd": 52},
            "3kg": {"NU": 5280, "usd": 64},
            "4kg": {"NU": 6280, "usd": 77},
            "5kg": {"NU": 7280, "usd": 89}
          }
        },
        {
          "destination": "Maldives",
          "rates": {
            "0.25kg": {"NU": 2665, "usd": 33},
            "0.5kg": {"NU": 2985, "usd": 36},
            "1kg": {"NU": 3625, "usd": 44},
            "2kg": {"NU": 4905, "usd": 60},
            "3kg": {"NU": 6185, "usd": 75},
            "4kg": {"NU": 7465, "usd": 91},
            "5kg": {"NU": 8745, "usd": 107}
          }
        },
        {
          "destination": "Thailand",
          "rates": {
            "0.25kg": {"NU": 2415, "usd": 29},
            "0.5kg": {"NU": 2515, "usd": 31},
            "1kg": {"NU": 2715, "usd": 33},
            "2kg": {"NU": 3115, "usd": 38},
            "3kg": {"NU": 3515, "usd": 43},
            "4kg": {"NU": 3915, "usd": 48},
            "5kg": {"NU": 4315, "usd": 53}
          }
        },
        {
          "destination": "Singapore",
          "rates": {
            "0.25kg": {"NU": 2645, "usd": 32},
            "0.5kg": {"NU": 2815, "usd": 34},
            "1kg": {"NU": 3155, "usd": 38},
            "2kg": {"NU": 3835, "usd": 47},
            "3kg": {"NU": 4515, "usd": 55},
            "4kg": {"NU": 5195, "usd": 63},
            "5kg": {"NU": 5875, "usd": 72}
          }
        },
        {
          "destination": "Malaysia",
          "rates": {
            "0.25kg": {"NU": 2745, "usd": 33},
            "0.5kg": {"NU": 2920, "usd": 36},
            "1kg": {"NU": 3270, "usd": 40},
            "2kg": {"NU": 3970, "usd": 48},
            "3kg": {"NU": 4670, "usd": 57},
            "4kg": {"NU": 5370, "usd": 65},
            "5kg": {"NU": 6070, "usd": 74}
          }
        },
        {
          "destination": "Saudi Arabia",
          "rates": {
            "0.25kg": {"NU": 2955, "usd": 36},
            "0.5kg": {"NU": 3310, "usd": 40},
            "1kg": {"NU": 4020, "usd": 49},
            "2kg": {"NU": 5440, "usd": 66},
            "3kg": {"NU": 6860, "usd": 84},
            "4kg": {"NU": 8280, "usd": 101},
            "5kg": {"NU": 9700, "usd": 118}
          }
        },
        {
          "destination": "Kenya",
          "rates": {
            "0.25kg": {"NU": 2910, "usd": 35},
            "0.5kg": {"NU": 3345, "usd": 41},
            "1kg": {"NU": 4215, "usd": 51},
            "2kg": {"NU": 5955, "usd": 73},
            "3kg": {"NU": 7695, "usd": 94},
            "4kg": {"NU": 9435, "usd": 115},
            "5kg": {"NU": 11175, "usd": 136}
          }
        },
        {
          "destination": "Switzerland",
          "rates": {
            "0.25kg": {"NU": 2955, "usd": 36},
            "0.5kg": {"NU": 3215, "usd": 39},
            "1kg": {"NU": 3735, "usd": 46},
            "2kg": {"NU": 4775, "usd": 58},
            "3kg": {"NU": 5815, "usd": 71},
            "4kg": {"NU": 6855, "usd": 84},
            "5kg": {"NU": 7895, "usd": 96}
          }
        },
        {
          "destination": "Germany",
          "rates": {
            "0.25kg": {"NU": 3475, "usd": 42},
            "0.5kg": {"NU": 3970, "usd": 48},
            "1kg": {"NU": 4960, "usd": 60},
            "2kg": {"NU": 6940, "usd": 85},
            "3kg": {"NU": 8920, "usd": 109},
            "4kg": {"NU": 10900, "usd": 133},
            "5kg": {"NU": 12880, "usd": 157}
          }
        },
        {
          "destination": "France",
          "rates": {
            "0.25kg": {"NU": 4710, "usd": 57},
            "0.5kg": {"NU": 5205, "usd": 63},
            "1kg": {"NU": 6195, "usd": 76},
            "2kg": {"NU": 8175, "usd": 100},
            "3kg": {"NU": 10155, "usd": 124},
            "4kg": {"NU": 12135, "usd": 148},
            "5kg": {"NU": 14115, "usd": 172}
          }
        },
        {
          "destination": "Australia",
          "rates": {
            "0.25kg": {"NU": 3050, "usd": 37},
            "0.5kg": {"NU": 3375, "usd": 41},
            "1kg": {"NU": 4025, "usd": 49},
            "2kg": {"NU": 5325, "usd": 65},
            "3kg": {"NU": 6625, "usd": 81},
            "4kg": {"NU": 7925, "usd": 97},
            "5kg": {"NU": 9225, "usd": 113}
          }
        },
        {
          "destination": "USA",
          "rates": {
            "0.25kg": {"NU": 4095, "usd": 50},
            "0.5kg": {"NU": 4485, "usd": 55},
            "1kg": {"NU": 5265, "usd": 64},
            "2kg": {"NU": 6825, "usd": 83},
            "3kg": {"NU": 8385, "usd": 102},
            "4kg": {"NU": 9945, "usd": 121},
            "5kg": {"NU": 11505, "usd": 140}
          }
        },
        {
          "destination": "Canada",
          "rates": {
            "0.25kg": {"NU": 2620, "usd": 32},
            "0.5kg": {"NU": 3000, "usd": 37},
            "1kg": {"NU": 3760, "usd": 46},
            "2kg": {"NU": 5280, "usd": 64},
            "3kg": {"NU": 6800, "usd": 83},
            "4kg": {"NU": 8320, "usd": 101},
            "5kg": {"NU": 9840, "usd": 120}
          }
        },
        {
          "destination": "Others",
          "rates": {
            "0.25kg": {"NU": 3000, "usd": 37},
            "0.5kg": {"NU": 3200, "usd": 39},
            "1kg": {"NU": 3800, "usd": 46},
            "2kg": {"NU": 5000, "usd": 61},
            "3kg": {"NU": 6100, "usd": 74},
            "4kg": {"NU": 7200, "usd": 88},
            "5kg": {"NU": 8400, "usd": 102}
          }
        }
      ]
      
      ,
    postal: 
    [
        {
          "destination": "India",
          "rates": {
            "1kg": {"NU": 1395, "usd": 17.01},
            "0.5kg": {"NU": 697.5, "usd": 8.5},
            "2kg": {"NU": 2790, "usd": 34.02},
            "3kg": {"NU": 4185, "usd": 51.03}
          }
        },
        {
          "destination": "Nepal",
          "rates": {
            "1kg": {"NU": 1100, "usd": 13.41},
            "0.5kg": {"NU": 550, "usd": 6.87},
            "2kg": {"NU": 2200, "usd": 26.82},
            "3kg": {"NU": 3300, "usd": 40.24}
          }
        },
        {
          "destination": "Bangladesh",
          "rates": {
            "1kg": {"NU": 1065, "usd": 12.98},
            "0.5kg": {"NU": 532.5, "usd": 6.49},
            "2kg": {"NU": 2130, "usd": 25.97},
            "3kg": {"NU": 3195, "usd": 38.69}
          }
        },
        {
          "destination": "Malaysia",
          "rates": {
            "1kg": {"NU": 1295, "usd": 15.79},
            "0.5kg": {"NU": 647.5, "usd": 7.89},
            "2kg": {"NU": 2590, "usd": 31.58},
            "3kg": {"NU": 3885, "usd": 47.37}
          }
        },
        {
          "destination": "Singapore",
          "rates": {
            "1kg": {"NU": 1330, "usd": 16.21},
            "0.5kg": {"NU": 665, "usd": 8.1},
            "2kg": {"NU": 2660, "usd": 32.43},
            "3kg": {"NU": 3990, "usd": 48.65}
          }
        },
        {
          "destination": "Sri Lanka",
          "rates": {
            "1kg": {"NU": 1270, "usd": 15.48},
            "0.5kg": {"NU": 635, "usd": 7.74},
            "2kg": {"NU": 2540, "usd": 30.97},
            "3kg": {"NU": 3810, "usd": 46.46}
          }
        },
        {
          "destination": "Maldives",
          "rates": {
            "1kg": {"NU": 1745, "usd": 21.28},
            "0.5kg": {"NU": 872.5, "usd": 10.64},
            "2kg": {"NU": 3490, "usd": 42.56},
            "3kg": {"NU": 5235, "usd": 63.84}
          }
        },
        {
          "destination": "Thailand",
          "rates": {
            "1kg": {"NU": 700, "usd": 8.53},
            "0.5kg": {"NU": 450, "usd": 5.48},
            "2kg": {"NU": 1400, "usd": 17.5},
            "3kg": {"NU": 2100, "usd": 25.6}
          }
        },
        {
          "destination": "Europe",
          "rates": {
            "1kg": {"NU": null, "usd": null},
            "0.5kg": {"NU": null, "usd": null},
            "2kg": {"NU": null, "usd": null},
            "3kg": {"NU": null, "usd": null}
          }
        },
        {
          "destination": "USA",
          "rates": {
            "1kg": {"NU": null, "usd": null},
            "0.5kg": {"NU": null, "usd": null},
            "2kg": {"NU": null, "usd": null},
            "3kg": {"NU": null, "usd": null}
          }
        },
        {
          "destination": "Canada",
          "rates": {
            "1kg": {"NU": 2215, "usd": 27.07},
            "0.5kg": {"NU": 1107.5, "usd": 13.5},
            "2kg": {"NU": 4430, "usd": 54.02},
            "3kg": {"NU": 6645, "usd": 81.03}
          }
        },
        {
          "destination": "Switzerland",
          "rates": {
            "1kg": {"NU": 1915, "usd": 23.35},
            "0.5kg": {"NU": 957.5, "usd": 11.67},
            "2kg": {"NU": 3830, "usd": 46.7},
            "3kg": {"NU": 5745, "usd": 70.06}
          }
        },
        {
          "destination": "Middle East",
          "rates": {
            "1kg": {"NU": 1520, "usd": 18.53},
            "0.5kg": {"NU": 760, "usd": 9.26},
            "2kg": {"NU": 3040, "usd": 37.07},
            "3kg": {"NU": 4560, "usd": 55.6}
          }
        },
        {
          "destination": "Africa",
          "rates": {
            "1kg": {"NU": 1890, "usd": 23.04},
            "0.5kg": {"NU": 945, "usd": 11.52},
            "2kg": {"NU": 3780, "usd": 46.09},
            "3kg": {"NU": 5670, "usd": 69.14}
          }
        },
        {
          "destination": "Australia",
          "rates": {
            "1kg": {"NU": 1790, "usd": 21.82},
            "0.5kg": {"NU": 895, "usd": 10.91},
            "2kg": {"NU": 3580, "usd": 43.65},
            "3kg": {"NU": 5370, "usd": 65.48}
          }
        }
      ]
      
      ,
  };



const Cart = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const [checkOut,setCheckOut]= useState(false)
  const [discount,setDiscount]= useState(false)
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[coupon,setCoupon]= useState("")
  const[allAddress,setAllAddress]= useState([])

  const[email,setEmail]= useState("")
  const[cid,setCID]= useState("")
  const[orderItem,setOrderItem]=useState([])
  const[docs,setDocs]=useState([])
  const[salary,setSalary]= useState("")
  const [date,setDate]= useState(new Date())
  const [url,setUrl] = useState("")  
  const [data,setData] = useState([])  
  const [total,setTotal] = useState()  
  const [service, setService] = useState("ems");
  const [destination, setDestination] = useState("");
  const [weight, setWeight] = useState("");
  const [price, setPrice] = useState(null);
  var totalWeight = 0


  const [selectedCurrency, setSelectedCurrency] = useState(() => {
    const savedCurrency = localStorage.getItem('currency');
    return savedCurrency ? JSON.parse(savedCurrency) : { value: 'USD', label: 'USD - US Dollar', conv: 1 };
  });


  useEffect(  ()=>{

    
    
    const cartp =    JSON.parse( localStorage.getItem("cart"))

    // console.log("SSS",cartp)
    

//     fetch('/getmycart',{
//       headers:{
//           Authorization: "Bearer " +localStorage.getItem("jwt"),
//           "Content-Type":"application/json"

          
//       }
//   }).then(res=>res.json())
//   .then(result=>{
      setData(cartp)

    

      let sum=0

      if(cartp!=null){
    totalWeight= cartp.reduce((sum, item) => {
            return sum + (item.product.weight || 0) * item.qty;
          }, 0)


    setWeight(totalWeight)
    
        cartp.map(item=>{
            sum+=(item.product.rate*item.qty)
          })

      }

      
    
      
      setTotal(sum)
//   })


//   fetch(`/getconsumerprofile`,{
//     method:"get",
//     headers:{
//         Authorization: "Bearer " +localStorage.getItem("jwt"),
//         "Content-Type":"application/json"

        
//     },
 
// }).then(res=>res.json())
// .then(result=>{

//   console.log("DSF",result.consumer[0].address)
//     // setOrderHistoryData(result.orders)
//     // setData(result.consumer)
//     setAllAddress(result.consumer[0].address)
// })

  },[])

  const updateQtyMinus =(index,increment)=>{

    let cart = JSON.parse(localStorage.getItem('cart')) || [];

    // Check if the index is valid
    if (index !== -1 && index < cart.length) {
        // Update the quantity
        cart[index].qty += increment;
    
        // Ensure quantity doesn't go below 1
        if (cart[index].qty < 1) {
            cart[index].qty = 1;
        }
    
        // Update the local storage with the modified array
        localStorage.setItem('cart', JSON.stringify(cart));

        const totalWeight = cart.reduce((sum, item) => {
            return sum + (item.product.weight || 0) * item.qty;
          }, 0);
    
    
          setWeight(totalWeight)
        // Update the state to reflect the changes
        setData(cart);
    }
    



       }



  const updateQty =(index,increment)=>{

   
//     fetch("/updatecartqty",{
//       method:"post",
//       headers:{
//           "Content-Type":"application/json",
//           Authorization: "Bearer " +localStorage.getItem("jwt")

//       },
//       body: JSON.stringify({
        
//           id:selected
//       })
      
//   }).then(res=>res.json())
//   .then(data=> {
     
//       if(data.error){
//         toast.error(data.error)

//       }
//       else{
//      toast.success("Updated")
//      history('/cart')
//       }

      
//   })
//   .catch(err=>{
//       console.log(err)
//   })


let cart = JSON.parse(localStorage.getItem('cart')) || [];

// Check if the index is valid
if (index !== -1 && index < cart.length) {
    // Update the quantity
    cart[index].qty += increment;

    // Ensure quantity doesn't go below 1
    if (cart[index].qty < 1) {
        cart[index].qty = 1;
    }

    // Update the local storage with the modified array
    localStorage.setItem('cart', JSON.stringify(cart));


    const totalWeight = cart.reduce((sum, item) => {
        return sum + (item.product.weight || 0) * item.qty;
      }, 0);


      setWeight(totalWeight)
    // Update the state to reflect the changes
    setData(cart);
}

       }



       const  deleteorder = async (item,index)=>{

        // const order = await fetch('/deletecartitem',{
      
        //   method:'delete',
        //   headers:{
        //     Authorization:"Bearer "+localStorage.getItem("jwt"),
        //     "Content-Type":"application/json"
        //   },
        //   body:JSON.stringify({
        //     id:item._id
        //   })
      
          
      
        // })
      
       
        // if (order) {
        console.log("IFFT",item)
            
            let myArray = JSON.parse(localStorage.getItem('cart'));
            let myArray2 = JSON.parse(localStorage.getItem('cartid'));

            if (index !== -1 && index < myArray.length) {
                myArray.splice(index, 1);
                myArray2.splice(index, 1);
            }
            localStorage.setItem('cart', JSON.stringify(myArray));
            localStorage.setItem('cartid', JSON.stringify(myArray2));


            // const index = myArray.indexOf(order._id);
            // let index = -1;
            // for (let i = 0; i < myArray.length; i++) {
                
            //   if (myArray[i] == item.product._id) {
            //     console.log(myArray[i])
            //     index = i;
            //     break;
            //   }
            // }
            
            // if (index !== -1) {
            //     // Remove item from array
            //     myArray.splice(index, 1);
              
            //     // Store updated array in local storage
            //     localStorage.setItem('cart', JSON.stringify(myArray));
            window.location.reload();

            //   }

        
      
      
      
      }


      const addorderlist = (items)=>{

        items.map(item=>{
            setOrderItem(old=>[...old,{product:item.product._id,qty:item.qty,size:item.size}])

        })
      }



      const postOrder = ()=>{
       


        fetch("/addOrder",{
            method:"post",
            headers:{
                Authorization:"Bearer "+localStorage.getItem("jwt"),
                "Content-Type":"application/json"
            },
            body: JSON.stringify({
                orderItem,address,name,email,phone,date
                
            })
            
        }).then(res=>res.json())
        .then(data=> {
           
            if(data.error){
              toast.error(data.error)
      
            }
            else{
                localStorage.removeItem("cart")
                localStorage.removeItem("cartid")
           toast.success("Order Placed")
           history('/cart')
            }
        })
        .catch(err=>{
            console.log(err)
        })
      

        


      }


      const CheckCoupon = ()=>{
       


        fetch("/check-coupons",{
            method:"post",
            headers:{
                Authorization:"Bearer "+localStorage.getItem("jwt"),
                "Content-Type":"application/json"
            },
            body: JSON.stringify({
                email,coupon
                
            })
            
        }).then(res=>res.json())
        .then(data=> {
           
            if(data.error){
              toast.error(data.error)
      
            }
            else{
              toast.success(data.message)
           setDiscount(true)
            }
        })
        .catch(err=>{
            console.log(err)
        })
      

        


      }






// const stripePromise = loadStripe('pk_live_51Q1xd9FJbupNFmMle6vyUhcW4YrljO03P1K2cFbKMAbBgsSD0upVy3YvMRoID6hkrkF94EUhe9VXvENjuPI7n8OH00EZI06cYW');

const handleCheckout = async () => {
  const stripe = await loadStripe('pk_test_51PjqiDRpDdf8cb18uURQxoP9p5XLdrh9sFF7EjBGegPvwkrnILmI0N7uAwznL3S3Mw4rrO2V97QykNdqlLJZy6pj00HTZPvqLg');

    localStorage.setItem('details', JSON.stringify({name:name,email:email,address:address}));
    localStorage.setItem('coupon', JSON.stringify({email:email,coupon:coupon}));
//    localStorage.setItem('email', JSON.stringify(email));
//     localStorage.setItem('address', JSON.stringify(address));



  const response = await fetch('/create-checkout-session', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ cartItems:data,deliveryFee:price,currency:selectedCurrency.value, convo:selectedCurrency.conv }), // Send the cart items to the backend
  });

  const session = await response.json();
  // const stripe = await stripePromise;


  await stripe.redirectToCheckout({ sessionId: session.id });
  setCheckOut(false)
};



// const handleCalculate = () => {

//   const selectedService = shippingData[service];
//   const destinationData = selectedService.find((d) => d.destination === destination);
  
// //   console.log("LOGG", destinationData.rates[`${weight}kg`])

//   if (destinationData && destinationData.rates[`${weight}kg`]) {

//     setPrice(destinationData.rates[`${weight}kg`].usd); // Set the price in USD (or change to NU if needed)
//   } else {
//     setPrice(null);
//   }
// };



const handleCalculate = () => {
  const selectedService = shippingData[service];
  const destinationData = selectedService.find((d) => d.destination === destination);

  if (destinationData) {
    // Check if the exact weight exists
    if (destinationData.rates[`${weight}kg`]) {
      setPrice(destinationData.rates[`${weight}kg`].usd); // Set the price for the exact weight
    } else {
      // Find the available weights as numbers
      const availableWeights = Object.keys(destinationData.rates).map((key) => parseFloat(key.replace('kg', '')));

      // Calculate the next highest weight (ceiling)
      const nextWeight = availableWeights.find((w) => w >= Math.ceil(weight));

      if (nextWeight) {
        setPrice(destinationData.rates[`${nextWeight}kg`].usd); // Set the price for the next highest weight in USD
      } else {
        setPrice(null); // If no suitable weight is found
      }
    }
  } else {
    setPrice(null); // If the destination or service is not found
  }
};


      
    return(


<>
<Header></Header>











<div class="flex flex-wrap w-full mt-16">
  




<div class="container max-w-3xl px-4 mx-auto sm:px-8">
    <div class="py-8">
        <div class="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
            <div class="inline-block min-w-full overflow-hidden rounded-lg shadow">
                <table class="min-w-full leading-normal">
                    <thead>
                        <tr>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Product
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Quantity
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Price
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Action
                            </th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {data?data.map((item,index)=>{

                                // console.log(item.product.pic.length)

                            return(


<>
                          
                          <tr>
                            <td class=" py-5 text-sm bg-white border-b border-gray-200 ">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 md:w-full w-24">
                                        <a href={`/singleproduct/${item.product?item.product._id:""}`} class="relative block">
                                            <img alt="pic" src={item.product.pic.length>0? item.product.pic[0]:"/pcm.png"} class=" object-fit  h-24 w-24 "/>
                                        </a><p class="text-gray-900 ">
                                            {
                                              item.product?item.product.name:""
                                            }
                          
                                        </p>
                                    </div>
                                  
                                </div>
                            </td>
                            <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                <p class="text-gray-900 whitespace-no-wrap">
                                    
                                <div class="flex items-center">
                                                  <button type="button" class="w-full p-2 text-base text-gray-600 bg-white border rounded-l-xl hover:bg-gray-100"
                                               onClick={()=>{updateQtyMinus(index,-1)}}

                                                  >
                                                  {/* <svg width="9" fill="currentColor" height="8" class="" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z">
                                                  </path>
                                                  </svg> */}
                                                  -
                                                  </button>
                          
                                                  <button type="button" class="w-full px-4 py-2 text-base text-gray-600 bg-white border hover:bg-gray-100">
                                                  {item.qty}
                                                  </button>
                          
                          
                                                  <button type="button" class="w-full p-2 text-base text-gray-600 bg-white border-t border-b border-r rounded-r-xl hover:bg-gray-100"
                                                  
                                                  onClick={()=>{updateQty(index,1)}}
                                                  >
                                                  {/* <svg width="9" fill="currentColor" height="8" class="" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z">
                                                  </path>
                                                  </svg> */}
                                                  +
                                                  </button>
                                                  </div>
                                </p>
                            </td>
                            <td class="px-2 py-5 text-sm bg-white border-b border-gray-200">
                                <p class="text-gray-900 whitespace-no-wrap">
                                    {selectedCurrency.value} {item.product.rate * selectedCurrency.conv}
                                </p>
                            </td>
                            <td class=" py-5 text-sm bg-white border-b border-gray-200 ">

                            <p
                                                                              onClick={()=>{if(window.confirm('Are sure you want to DELETE it?')) deleteorder(item,index)}}

                                >

                                    
                            <svg class=" cursor-pointer h-8 w-8 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"

                            
                            />  <line x1="4" y1="7" x2="20" y2="7" />  <line x1="10" y1="11" x2="10" y2="17" />  <line x1="14" y1="11" x2="14" y2="17" />  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
                                                            </p>

                            </td>
                            
                          
                          </tr>
                          </>
                            )

                                  

                        }):<></>}
                       
                       
                       
                       
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>




<div className="py-5 rounded-t md:mt-12 md:p-12 p-4">
  <div className="overflow-auto bg-white shadow  sm:rounded-md">
    <h1 className='text-center'>Check Out</h1>
    <ul className="divide-y divide-gray-200 m-4">
   

        <li>


        <div className="py-1">
<span className="px-1 text-sm text-gray-600">Name</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Name"
value={name}
onChange={(e)=>setName(e.target.value)}
>

</input>
</div>
        <div className="py-1">
<span className="px-1 text-sm text-gray-600">Email</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Email"
value={email}
onChange={(e)=>setEmail(e.target.value)}
>

</input>
</div>
        <div className="py-1">
<span className="px-1 text-sm text-gray-600">Address</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Address"
value={address}
onChange={(e)=>setAddress(e.target.value)}
>

</input>
</div>

<div className='grid grid-cols-2 '>
  <div className="py-1">
<span className="px-1 text-sm text-gray-600">Have a Coupon?</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Coupon"
value={coupon}
onChange={(e)=>setCoupon(e.target.value)}
>

</input>


</div>

<button
     type="button"
     className=" m-4 mt-6 bg-purple-500 hover:bg-purple-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-11/12 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
     onClick={() => {
      CheckCoupon()
     }}
   >
     Validate
   </button>
    
</div>
        

            
        </li>
      {/* <li>
        <div className="mb-4">
          <label className="block text-gray-700">Shipping Service</label>
          <select
            className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
            value={service}
            onChange={(e) => setService(e.target.value)}
          >
            <option value="ems">EMS</option>
            <option value="postal">Postal </option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Destination</label>
          <select
            className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
          >
            <option value="">Select a destination</option>
            {shippingData[service].map((d) => (
              <option key={d.destination} value={d.destination}>
                {d.destination}
              </option>
            ))}
          </select>
        </div>

        <button
          className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700"
          onClick={handleCalculate}
        >
          Confirm Shipping Selection
        </button>

        {price !== null && (
          <div className="mt-4 text-lg text-gray-800 text-center">
            Shipping Price: {selectedCurrency.value} {price *selectedCurrency.conv}
          </div>
        )}
      </li> */}

      <li>
        <a className="block hover:bg-gray-50 ">
          <div className="px-4 py-4 sm:px-6">
            <div className="flex items-center justify-between">
              <p className="text-red-700 text-md  md:truncate">
                Grand Total after Delivery Charges and Taxes
              </p>
            </div>
            <div className="mt-2 flex flex-wrap justify-between sm:flex-nowrap">
              <div>
                <p className='text-purple-500 font-bold'>
                                {discount?"Discount: 15% Off":""}

                </p>
                <p className="flex items-center font-bold text-green-500 text-md ">
                 Total: {selectedCurrency.value } 
                 
                 {discount?<>
                  <span><p className='line-through m-2 text-red-500'>{((price+total)*selectedCurrency.conv )}</p></span> {data ? (((price+total)*selectedCurrency.conv )-((price+total)*selectedCurrency.conv*0.15)) : 0}

                 </>:<>
                  {data ? (((price+total)*selectedCurrency.conv )) : 0}

                 
                 </>
                 
                }
                </p>
              </div>
            </div>
          </div>
        </a>
      </li>
    </ul>

    <div className="w-full p-4 mx-auto sm:w-3/4 md:w-1/2">
    
     <button
     type="button"
     className={`py-2 px-4 bg-green-500 hover:bg-green-600 ${!email||!name||!address?"cursor-not-allowed	":"cursor-pointer"} focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg`}     onClick={() => {
       setCheckOut(true);
       addorderlist(data);
       handleCheckout();
     }}
     disabled={!email}
   >
     Check Out
   </button>
    
     

      <ToastContainer />
    </div>
  </div>
</div>





        </div>









        {
checkOut? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 relative flex flex-col w-full bg-none outline-none focus:outline-none">
          {/*header*/}


          <svg class="h-16 w-16 text-lime-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
          {/* <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setCheckOut(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div> */}
          {/*body*/}
{/*            
          <div className="py-1 px-2">
          <span className="px-1 text-sm text-gray-600">Address</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Address"
            value={address}
            onChange={(e)=>setAddress(e.target.value)}
            >

            </input>
          </div> */}



{/* <label class="text-gray-700 font-bold text-2xl p-4" >
    Address
    </label>

    <select id="animals" class=" text-md block p-6 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" name="animals"
          
          value={address}
          onChange={(e)=>setAddress(e.target.value)}
          >
        <option value="">
            Select Address
        </option>

                <option 
                >
                USA
            </option>
        
      
       
    </select>
    <div className="flex -mx-3">
              <div className="w-full px-3 mb-5">
                <label htmlFor className="text-md text-gray-600 font-semibold px-1">Name</label>
                <div className="flex">
                  <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg" /></div>
                  <input type="email" required 
                  className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
                  placeholder="Name"
                  value={name}
                  onChange={(e)=>setName(e.target.value)}
                  />
                </div>
              </div>
            </div>


        
          

            <div className="flex -mx-3">
              <div className="w-full px-3 mb-5">
                <label htmlFor className="text-md text-gray-600 font-semibold px-1">Email</label>
                <div className="flex">
                  <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg" /></div>
                  <input type="email" required 
                  className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
                  placeholder="youremail@example.com"
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="flex -mx-3">
              <div className="w-full px-3 mb-5">
                <label htmlFor className="text-md text-gray-600 font-semibold px-1">Phone</label>
                <div className="flex">
                  <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg" /></div>
                  <input type="email" required 
                  className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
                  placeholder="youremail@example.com"
                  value={phone}
                  onChange={(e)=>setPhone(e.target.value)}
                  />
                </div>
              </div>
            </div>
          


          <div class="py-5 rounded-t sm:px-6">
    <div class="overflow-auto bg-white shadow  sm:rounded-md">
        <ul class="divide-y divide-gray-200">
            <li>
                <a class="block hover:bg-gray-50 ">
                    <div class="px-4 py-4 sm:px-6">
                        <div class="flex items-center justify-between">
                            <p class="text-gray-700 text-md  md:truncate">
                                Total Amount
                            </p>
                           
                        </div>
                        <div class="mt-2 sm:flex sm:justify-between">
                            <div class="sm:flex">
                                <p class="flex items-center font-light text-gray-500 text-md ">
                                    Nu. {total}
                                </p>
                            </div>
                        </div>
                    </div>
                </a>
            </li>
            <li>
                <a class="block hover:bg-gray-50 ">
                    <div class="px-4 py-4 sm:px-6">
                        <div class="flex items-center justify-between">
                            <p class="text-gray-700 text-md  md:truncate">
                                Delivery
                            </p>
                          
                        </div>
                        <div class="mt-2 sm:flex sm:justify-between">
                            <div class="sm:flex">
                                <p class="flex items-center font-light text-gray-500 text-md ">
                                    Nu. 250
                                </p>
                            </div>
                        </div>
                    </div>
                </a>
            </li>
            <li>
                <a class="block hover:bg-gray-50 ">
                    <div class="px-4 py-4 sm:px-6">
                        <div class="flex items-center justify-between">
                            <p class="text-gray-700 text-md  md:truncate">
                               Grand Total after Delivery charges and taxes  
                            </p>
                           
                        </div>
                        <div class="mt-2 sm:flex sm:justify-between">
                            <div class="sm:flex">
                                <p class="flex items-center font-light text-gray-500 text-md ">
                                    Nu. {total+250}
                                </p>
                            </div>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
        <div class="w-full p-4 mx-auto md:w-1/2">
            <button type="button" class="py-2 px-4  bg-red-500 hover:bg-red-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            
            onClick={()=>{

                postOrder(data)
                
              
              }}
            >
                Check Out
            </button>


        </div>
    </div>
</div> */}




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}







{/* 
<div>


<p class="mt-2 mb-4 text-xl font-extrabold leading-8 tracking-tight text-gray-900  sm:text-2xl text-center">
            Recommended for you
        </p>


</div> */}
 



<Footer page="cart"></Footer>


</>
  


    );
}

export default Cart;






