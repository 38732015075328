
import React,{useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import Header from './Header';
import 'react-toastify/dist/ReactToastify.css';

import {UserContex} from '../App'
import Footer from './Footer';


const ReturnPolicy = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[skills,setSkills]=useState([])
  const[docs,setDocs]=useState()
  const[salary,setSalary]= useState("")
  const [dob,setDob]= useState(new Date())
  const [url,setUrl] = useState("")  

  const PostData =()=>{
    toast.success("Message Sent Sucessfully")

   
    fetch("/sendfeedback",{
      method:"post",
      headers:{
          "Content-Type":"application/json"
      },
      body: JSON.stringify({
        
          name:name,
          email:email,
          message:"Interested in Joining Partnership Program"
      })
      
  }).then(res=>res.json())
  .then(data=> {
     
    
       toast.success("Message Sent Sucessfully")
      
  })
  .catch(err=>{
      console.log(err)
  })

       }

    return(


<>
<Header></Header>

<div class="relative h-1/2 overflow-hidden bg-indigo-900">
    <img src="https://images.unsplash.com/photo-1579444741963-5ae219cfe27c?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" class="absolute object-cover w-full h-full"/>
    <div className="absolute inset-0 bg-black opacity-20"></div>

  
    <div class="container relative z-10 flex items-center px-6 py-32 mx-auto md:px-12 xl:py-40">
        <div class="relative z-10 flex flex-col items-center w-full">
            <h1 class="mt-4 font-extrabold leading-tight text-center text-white text-6xl ">
             Return and Exchange policy 

            </h1>
            {/* <h1 class="mt-4 font-extrabold leading-tight text-center text-gray-100 text-xl ">
            At Mountain Crafts Bhutan, we invite tour guides and travel agencies to become part of our mission to preserve Bhutan’s rich heritage and empower its rural artisans. By partnering with us, you can offer your clients an authentic cultural experience while earning commissions through every visit to our store. Together, we can support sustainable tourism, promote fair trade, and create lasting connections between travelers and the vibrant crafts of Bhutan. Join us in shaping a future where tourism uplifts local communities.            </h1>
            <a href="#partner" class="block px-4 py-3 mt-24 text-lg font-bold text-white uppercase bg-gray-800 hover:bg-gray-900">
                Start Now
            </a> */}
        </div>
    </div>
</div>



<div class="container p-6 px-6 mx-auto bg-white ">
    <div class="mb-16 text-center">
        <h2 class="text-3xl">
        What's our return policy?

        </h2>

                <p class="mt-4 text-lg leading-6 text-gray-500 ">
                We gladly accept returns of unworn and unwashed items with tags attached within 30 days of your purchase through our online store. Here’s a brief overview of our return and exchange policy:

</p>
    </div>
  
<section>
    <div class="container  bg-white max-w-7xl  dark:bg-gray-800">
        <div class="flex flex-wrap ">
            
            <div class="w-full px-8 lg:w-1/2">
                <ul class="space-y-12">
                    <li class="flex -mx-4">
                        <div class="px-4">
                            <span class="flex items-center justify-center w-16 h-16 mx-auto text-2xl font-bold text-blue-600 rounded-full font-heading bg-blue-50">
                                1
                            </span>
                        </div>
                        <div class="px-4">
                            <h3 class="my-4 text-xl font-semibold dark:text-white">
                            Original shipping fees are non-refundable.
                            </h3>
                          
                        </div>
                    </li>
                    <li class="flex -mx-4">
                        <div class="px-4">
                            <span class="flex items-center justify-center w-16 h-16 mx-auto text-2xl font-bold text-blue-600 rounded-full font-heading bg-blue-50">
                                2
                            </span>
                        </div>
                        <div class="px-4">
                            <h3 class="my-4 text-xl font-semibold dark:text-white">
                            Refunds will be processed to your original payment method.
                            </h3>
                         
                        </div>
                    </li>
                    <li class="flex -mx-4">
                        <div class="px-4">
                            <span class="flex items-center justify-center w-16 h-16 mx-auto text-2xl font-bold text-blue-600 rounded-full font-heading bg-blue-50">
                                3
                            </span>
                        </div>
                        <div class="px-4">
                            <h3 class="my-4 text-xl font-semibold dark:text-white">
                            Orders made with store credit can only be refunded as store credit.
                            </h3>
                         
                        </div>
                    </li>
                    <li class="flex -mx-4">
                        <div class="px-4">
                            <span class="flex items-center justify-center w-16 h-16 mx-auto text-2xl font-bold text-blue-600 rounded-full font-heading bg-blue-50">
                                4
                            </span>
                        </div>
                        <div class="px-4">
                            <h3 class="my-4 text-xl font-semibold dark:text-white">
                            Please use the provided return label and send it back with the designated carrier.
                            </h3>
                            
                        </div>
                    </li>
                </ul>
            </div>
            <div class="w-full px-8 lg:w-1/2">
                <ul class="space-y-12">
                  
                    <li class="flex -mx-4">
                        <div class="px-4">
                            <span class="flex items-center justify-center w-16 h-16 mx-auto text-2xl font-bold text-blue-600 rounded-full font-heading bg-blue-50">
                                5
                            </span>
                        </div>
                        <div class="px-4">
                            <h3 class="my-4 text-xl font-semibold dark:text-white">
                            A USD$... label fee will be deducted from your refund for returns (exchanges will include a prepaid label with no fee).
                            </h3>
                          
                        </div>
                    </li>
                    <li class="flex -mx-4">
                        <div class="px-4">
                            <span class="flex items-center justify-center w-16 h-16 mx-auto text-2xl font-bold text-blue-600 rounded-full font-heading bg-blue-50">
                                6
                            </span>
                        </div>
                        <div class="px-4">
                            <h3 class="my-4 text-xl font-semibold dark:text-white">
                            Exchanges can be for the same item in a different size or for a different style or color; if the new item costs more, you’ll need to enter your payment details.
                            </h3>
                           
                        </div>
                    </li>
                    <li class="flex -mx-4">
                        <div class="px-4">
                            <span class="flex items-center justify-center w-16 h-16 mx-auto text-2xl font-bold text-blue-600 rounded-full font-heading bg-blue-50">
                                7
                            </span>
                        </div>
                        <div class="px-4">
                            <h3 class="my-4 text-xl font-semibold dark:text-white">
                            All final sale items are not eligible for returns or exchanges, as noted on product pages and in your order confirmation.
                            </h3>
                           
                        </div>
                    </li>
                    <li class="flex -mx-4">
                        <div class="px-4">
                            <span class="flex items-center justify-center w-16 h-16 mx-auto text-2xl font-bold text-blue-600 rounded-full font-heading bg-blue-50">
                                8
                            </span>
                        </div>
                        <div class="px-4">
                            <h3 class="my-4 text-xl font-semibold dark:text-white">
                            If your item is damaged, please contact us at mountaincraftsbtn@gmail.com for assistance from our Customer Care team.
                            </h3>
                           
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

</div>


{/* <div class="bg-white dark:bg-gray-800 mt-20 flex flex-cols h-screen">
<img class="object-cover w-1/4 mx-auto " src="https://img.freepik.com/free-vector/coworkers-cartoon-characters-effective-collaboration-coworkers-cooperation-teamwork-colleagues-discussing-solution-successful-interaction_335657-2309.jpg?t=st=1729104671~exp=1729108271~hmac=6677f2d54f60de4be310c176d7c3a92f70e210301bb509dff4127661a8b9ce0d&w=1380" alt="shopping item"/>

    <div class="relative px-4 py-6 overflow-hidden sm:px-6 sm:py-8 lg:p-12 xl:p-16">
        <h2 class="text-2xl font-semibold font-display text-black dark:text-white sm:text-3xl">
            Join us, Be our Partner...
        </h2>
        <p class="mt-2 max-w-xl text-base text-gray-400">
            Want to partner with us? Sign up for our partnership program and help us sell products.
        </p>
            <div class="mt-6 sm:flex jusitfy-start">
                <div class="flex flex-col justify-center w-3/4 max-w-sm space-y-3 md:flex-row md:w-full md:space-x-3 md:space-y-0">
                    <div class=" relative ">
                        <input type="text" id="&quot;form-subscribe-Subscribe" class=" rounded-lg border-purple-50 flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Email"/>
                        </div>
                        <button class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200" type="submit"
                        onClick={()=>{PostData()}}
                        >
                            Send a Query
                        </button>
                    </div>
                </div>
           
                </div>
            </div> */}


<Footer></Footer>
</>
  


    );
}

export default ReturnPolicy;