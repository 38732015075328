
import React,{useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import Header from './Header';
import 'react-toastify/dist/ReactToastify.css';

import {UserContex} from '../App'
import Footer from './Footer';


const PartnerWithUs = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[skills,setSkills]=useState([])
  const[docs,setDocs]=useState()
  const[salary,setSalary]= useState("")
  const [dob,setDob]= useState(new Date())
  const [url,setUrl] = useState("")  

  const PostData =()=>{
    toast.success("Message Sent Sucessfully")

   
    fetch("/sendfeedback",{
      method:"post",
      headers:{
          "Content-Type":"application/json"
      },
      body: JSON.stringify({
        
          name:name,
          email:email,
          message:"Interested in Joining Partnership Program"
      })
      
  }).then(res=>res.json())
  .then(data=> {
     
    
       toast.success("Message Sent Sucessfully")
      
  })
  .catch(err=>{
      console.log(err)
  })

       }

    return(


<>
<Header></Header>

<div class="relative h-1/2 overflow-hidden bg-indigo-900">
    <img src="https://www.raymond.in/static/media/Partner%20with%20us%20Banner.51f1d47662a478520cad.jpg" class="absolute object-cover w-full h-full"/>
    <div className="absolute inset-0 bg-black opacity-20"></div>

  
    <div class="container relative z-10 flex items-center px-6 py-32 mx-auto md:px-12 xl:py-40">
        <div class="relative z-10 flex flex-col items-center w-full">
            <h1 class="mt-4 font-extrabold leading-tight text-center text-gray-200 text-3xl ">
            Partner with Mountain Crafts Bhutan: Empower Your Tours, Support Local Artisans

            </h1>
            <h1 class="mt-4 font-extrabold leading-tight text-center text-gray-100 text-xl ">
            At Mountain Crafts Bhutan, we invite tour guides and travel agencies to become part of our mission to preserve Bhutan’s rich heritage and empower its rural artisans. By partnering with us, you can offer your clients an authentic cultural experience while earning commissions through every visit to our store. Together, we can support sustainable tourism, promote fair trade, and create lasting connections between travelers and the vibrant crafts of Bhutan. Join us in shaping a future where tourism uplifts local communities.            </h1>
            <a href="#partner" class="block px-4 py-3 mt-24 text-lg font-bold text-white uppercase bg-gray-800 hover:bg-gray-900">
                Start Now
            </a>
        </div>
    </div>
</div>



<div class="relative p-4 bg-white dark:bg-gray-800 mt-6">
    <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
        <div class="lg:col-start-2 md:pl-20">
            <h4 class="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 dark:text-white sm:leading-9">
            How It Works ?

            </h4>
            <ul class="mt-10">
                <li>
                    <div class="flex">
                        <div class="flex-shrink-0">
                        <div class="px-4">
                            <span class="flex items-center justify-center w-16 h-16 mx-auto text-2xl font-bold text-blue-600 rounded-full font-heading bg-blue-50">
                                1
                            </span>
                        </div>
                        </div>
                        <div class="ml-4">
                            <h5 class="text-lg font-bold leading-6 text-gray-900 dark:text-white">
                            Sign Up as a Partner

                            </h5>
                            <p class="mt-2 text-base leading-6 text-gray-500 dark:text-gray-300">
                            Join our network of tour guides and travel agencies by filling out a simple partnership form. Our team will provide you with all the resources and information you need to introduce Mountain Crafts Bhutan to your clients.

</p>
                        </div>
                    </div>
                </li>
                <li class="mt-10">
                    <div class="flex">
                        <div class="flex-shrink-0">
                        <div class="px-4">
                            <span class="flex items-center justify-center w-16 h-16 mx-auto text-2xl font-bold text-blue-600 rounded-full font-heading bg-blue-50">
                                2
                            </span>
                        </div>
                        </div>
                        <div class="ml-4">
                            <h5 class="text-lg font-bold leading-6 text-gray-900 dark:text-white">
                            Bring Clients to Our Store
                            </h5>
                            <p class="mt-2 text-base leading-6 text-gray-500 dark:text-gray-300">
                            Include a visit to Mountain Crafts Bhutan in your travel itineraries. We’re conveniently located in the heart of Bhutan’s cultural hubs, offering a perfect stop for tourists to explore and shop.

</p>
                        </div>
                    </div>
                </li>
                <li class="mt-10">
                    <div class="flex">
                        <div class="flex-shrink-0">
                        <div class="px-4">
                            <span class="flex items-center justify-center w-16 h-16 mx-auto text-2xl font-bold text-blue-600 rounded-full font-heading bg-blue-50">
                                3
                            </span>
                        </div>
                        </div>
                        <div class="ml-4">
                            <h5 class="text-lg font-bold leading-6 text-gray-900 dark:text-white">
                            Earn Commissions
                            </h5>
                            <p class="mt-2 text-base leading-6 text-gray-500 dark:text-gray-300">
                            For every purchase made by the clients you bring to us, you’ll earn a commission. Track your earnings easily through our partner dashboard, and receive timely payments.

</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="relative mt-10 -mx-4 md:-mx-12 lg:mt-0 lg:col-start-1">
            <img src="https://img.freepik.com/free-vector/hand-drawn-business-planning-concept_52683-76429.jpg" alt="illustration" class="relative w-auto mx-auto rounded shadow-lg"/>
        </div>
    </div>
</div>


<div className="min-w-screen min-h-screen bg-gray-50 flex items-center justify-center px-5 ">
    <div id='partner' className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden" style={{maxWidth: 1000}}>
      <div className="md:flex w-full">
      <div className="hidden md:block w-1/2  bg-white flex justify-center items-center">
  <img src='https://img.freepik.com/free-vector/international-business-cooperation-businesswoman-businessman-shaking-hands-global-collaboration-agreement-international-partnership-concept-illustration_335657-2033.jpg?t=st=1729959903~exp=1729963503~hmac=ac9b3a086ebf350e26ffefac34e9d0175b262747e5086b4f96d4dd0f8988a4e7&w=1380' alt="Logo" className="object-center mt-8" />
</div>
        <div className="w-full md:w-1/2 py-2 px-5 md:px-10">
          
          <div>
       

          <div class="relative px-4 py-6 overflow-hidden sm:px-6 sm:py-8 lg:p-12 xl:p-16">
        <h2 class="text-2xl font-semibold font-display text-black dark:text-white sm:text-3xl">
            Join us, Be our Partner...
        </h2>
        <p class="mt-2 max-w-xl text-base text-gray-400">
            Want to partner with us? Sign up for our partnership program and help us sell products.
        </p>
            <div class="mt-6 sm:flex jusitfy-start">
                <div class="flex flex-col justify-center   md:flex-row  md:space-x-3 md:space-y-0">
                    <div class=" relative ">
                        <input type="text" id="&quot;form-subscribe-Subscribe" class=" rounded-lg border-purple-50 flex-1 appearance-none border border-gray-300 w-64 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Email"/>
                        </div>
                        <button class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200" type="submit"
                        onClick={()=>{PostData()}}
                        >
                            Send 
                        </button>
                    </div>
                </div>
           
                </div>


        
          

      
         
       

           
              
    </div>
</div>

        
          </div>
        </div>
      </div>


{/* <div class="bg-white dark:bg-gray-800 mt-20 flex flex-cols h-screen">
<img class="object-cover w-1/4 mx-auto " src="https://img.freepik.com/free-vector/coworkers-cartoon-characters-effective-collaboration-coworkers-cooperation-teamwork-colleagues-discussing-solution-successful-interaction_335657-2309.jpg?t=st=1729104671~exp=1729108271~hmac=6677f2d54f60de4be310c176d7c3a92f70e210301bb509dff4127661a8b9ce0d&w=1380" alt="shopping item"/>

    <div class="relative px-4 py-6 overflow-hidden sm:px-6 sm:py-8 lg:p-12 xl:p-16">
        <h2 class="text-2xl font-semibold font-display text-black dark:text-white sm:text-3xl">
            Join us, Be our Partner...
        </h2>
        <p class="mt-2 max-w-xl text-base text-gray-400">
            Want to partner with us? Sign up for our partnership program and help us sell products.
        </p>
            <div class="mt-6 sm:flex jusitfy-start">
                <div class="flex flex-col justify-center w-3/4 max-w-sm space-y-3 md:flex-row md:w-full md:space-x-3 md:space-y-0">
                    <div class=" relative ">
                        <input type="text" id="&quot;form-subscribe-Subscribe" class=" rounded-lg border-purple-50 flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Email"/>
                        </div>
                        <button class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200" type="submit"
                        onClick={()=>{PostData()}}
                        >
                            Send a Query
                        </button>
                    </div>
                </div>
           
                </div>
            </div> */}


<Footer></Footer>
</>
  


    );
}

export default PartnerWithUs;