
import React,{useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {UserContex} from '../App'


const Signup = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[confirmpassword,setConfirmPassword]= useState("")
  const[cid,setCID]= useState("")
  const[skills,setSkills]=useState([])
  const[docs,setDocs]=useState([])
  const[salary,setSalary]= useState("")
  const [dob,setDob]= useState(new Date())
  const [url,setUrl] = useState("")  

  const PostData =()=>{

   
    fetch("/signupConsumer",{
      method:"post",
      headers:{
          "Content-Type":"application/json"
      },
      body: JSON.stringify({
        
          password,
          email:email,
          address,
          name,
          phone
      })
      
  }).then(res=>res.json())
  .then(data=> {
     
      if(data.error){
        toast.error(data.error)

      }
      else{
        console.log("Login Successful")
        
          history('/consumerlogin')
      }
  })
  .catch(err=>{
      console.log(err)
  })

       }

    return(


<>
  {/* component */}
  <style dangerouslySetInnerHTML={{__html: "@import url('https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.min.css')" }} />
  <div className="min-w-screen min-h-screen bg-gray-50 flex items-center justify-center px-5 py-5">
    <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-y-scroll" style={{maxWidth: 1000}}>
      <div className="md:flex w-full">
        <div className="hidden md:block w-1/2 bg-blue-200 py-10 px-10">

            <img  src='/employee.png'></img>
        </div>
        <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
          <div className="text-center mb-10">
            <h1 className="font-bold text-3xl text-gray-900">Sign Up </h1>
          </div>
          <div>
          <div class="grid max-w-xl grid-cols-2 gap-4 m-auto">

       
<div className="py-1">
  <span className="px-1 text-sm text-gray-600">Name</span>
    <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
  bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
  
    placeholder="Name"
    value={name}
    onChange={(e)=>setName(e.target.value)}
    >

    </input>
  </div>

  <div className="py-1">
  <span className="px-1 text-sm text-gray-600">Phone</span>
    <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
  bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
  
    placeholder="Phone No."
    value={phone}
    onChange={(e)=>setPhone(e.target.value)}
    >

    </input>
  </div>
  </div>

            <div className="flex -mx-3">
              <div className="w-full px-3 mb-5">
                <label htmlFor className="text-md text-gray-600 font-semibold px-1">Email</label>
                <div className="flex">
                  <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg" /></div>
                  <input type="email" required 
                  className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
                  placeholder="youremail@example.com"
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="flex -mx-3">
              <div className="w-full px-3 mb-5">
                <label htmlFor className="text-md text-gray-600 font-semibold px-1">Address</label>
                <div className="flex">
                  <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                  <svg class="h-6 w-6 text-gray-400"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
                    
                    </div>
                  <input type="text" required 
                  className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
                  placeholder="Your Address"
                  value={address}
                  onChange={(e)=>setAddress(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="flex -mx-3">
              <div className="w-full px-3 mb-5">
                <label htmlFor className="text-md  text-gray-600 font-semibold px-1">Password</label>
                <div className="flex">
                  <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-account-outline text-gray-400 text-lg" /></div>
                  <input type="password"  required
                  className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
                  placeholder="Password"
                  value={password}
                  onChange={(e)=>setPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="flex -mx-3">
              <div className="w-full px-3 mb-5">
                <label htmlFor className="text-md  text-gray-600 font-semibold px-1">Confirm Password</label>
                <div className="flex">
                  <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-account-outline text-gray-400 text-lg" /></div>
                  <input type="password"  required
                  className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
                  placeholder="Password"
                  value={confirmpassword}
                  onChange={(e)=>setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>
           
           

            <label class="flex items-center mb-3 space-x-3">
            <input type='checkbox' class="w-4 h-4">
            </input>
                                        <span class="font-normal text-gray-700 ">
                                            I agree to terms and contition 
                                        </span>
                                    </label> 


            {/* <label class="flex items-center mb-3 space-x-3">
                                    <input type="checkbox"  class="form-tick appearance-none bg-white bg-check h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none"/>
                                        <span class="font-normal text-gray-700 ">
                                            I agree to terms and contition 
                                        </span>
                                    </label> */}
            <div className="w-full px-3 mb-5">
              <Link to='/login'>
                <button className="block w-full max-w-xs mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold"
                
                onClick={()=> PostData()}
                >Sign up</button>
                              </Link>

               <ToastContainer/>

              </div>


            <div className=" px-12 mb-2 object-center"> 
              <a href="/reset">
                  Forgot Password?
              </a>
              <br></br>
              <a href="/signup">
              Don't have an account? Sign Up
              </a>
            </div>
              
    </div>
</div>

        
          </div>
        </div>
      </div>

</>
  


    );
}

export default Signup;