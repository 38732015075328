import React,{useContext, useState} from 'react'

import { Outlet, Navigate } from 'react-router-dom'


import {UserContex} from '../App'

const ProtectedRoutesUser = () => {
    const {state, dispatch }= useContext(UserContex)


    const user = JSON.parse( localStorage.getItem("consumer"))
    

    return(
        user ? <Outlet/> : <Navigate to="/consumerlogin"/>);
}

export default ProtectedRoutesUser