
import React,{useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import Header from './Header';
import 'react-toastify/dist/ReactToastify.css';

import {UserContex} from '../App'
import Footer from './Footer';


const ContactUs = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[skills,setSkills]=useState([])
  const[docs,setDocs]=useState()
  const[salary,setSalary]= useState("")
  const [dob,setDob]= useState(new Date())
  const [url,setUrl] = useState("")  

  const PostData =()=>{
    toast.success("Message Sent Sucessfully")

   
    fetch("/sendfeedback",{
      method:"post",
      headers:{
          "Content-Type":"application/json"
      },
      body: JSON.stringify({
        
          name:name,
          email:email,
          message:docs
      })
      
  }).then(res=>res.json())
  .then(data=> {
     
    
       toast.success("Message Sent Sucessfully")
      
  })
  .catch(err=>{
      console.log(err)
  })

       }

    return(


<>
<Header></Header>

<>
  {/* component */}
  <style dangerouslySetInnerHTML={{__html: "@import url('https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.min.css')" }} />
  <div className="min-w-screen min-h-screen bg-gray-50 flex items-center justify-center px-5 py-5">
    <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden" style={{maxWidth: 1000}}>
      <div className="md:flex w-full">
      <div className="hidden md:block w-1/2  bg-blue-200 flex justify-center items-center">
  <img src='https://cdni.iconscout.com/illustration/premium/thumb/contact-us-illustration-download-in-svg-png-gif-file-formats--customer-support-service-representative-call-pack-business-illustrations-5059493.png?f=webp' alt="Logo" className="object-center mt-8" />
</div>
        <div className="w-full md:w-1/2 py-2 px-5 md:px-10">
          <div className="text-center mb-10">
            <h1 className="font-bold text-3xl text-gray-900">Contact Us </h1>
          </div>
          <div>
       

            <div className="flex -mx-3">
              <div className="w-full px-3 mb-5">
                <label htmlFor className="text-md text-gray-600 font-semibold px-1">Name</label>
                <div className="flex">
                  <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg" /></div>
                  <input type="email" required 
                  className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
                  placeholder="Name"
                  value={name}
                  onChange={(e)=>setName(e.target.value)}
                  />
                </div>
              </div>
            </div>


        
          

            <div className="flex -mx-3">
              <div className="w-full px-3 mb-5">
                <label htmlFor className="text-md text-gray-600 font-semibold px-1">Email</label>
                <div className="flex">
                  <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg" /></div>
                  <input type="email" required 
                  className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
                  placeholder="youremail@example.com"
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
          
         
           
            <div className="flex -mx-3">
              <div className="w-full px-3 mb-5">
                <label htmlFor className="text-md text-gray-600 font-semibold px-1">Comment</label>
                <div className="flex">
                  <div className="w-10 h-40 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg" /></div>
                  <input type="email" required 
                  className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
                  placeholder="Your Message"
                  value={docs}
                  onChange={(e)=>setDocs(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="w-full px-3 mb-5">
                <button className="block w-full max-w-xs mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold"
                
                onClick={()=> PostData()}
                >Submit</button>
               <ToastContainer/>

              </div>

           
              
    </div>
</div>

        
          </div>
        </div>
      </div>
      <div class="max-w-screen-xl  mx-auto -mt-20 ">
    <h1 class=" text-2xl text-center font-black leading-none text-gray-800 uppercase font-bebas-neue  ">
    Business hours
</h1>
        <ul class="flex flex-wrap items-center gap-8">
            <li class="w-full">
               
                <p class="mt-2">
                    <p class="text-base text-center  text-gray-500 ">
                    Monday - Saturday 10:00AM to 6:00PM BTT
                                              </p>
                                              
                </p>
            </li>
            <li class="w-full">
               
                <p class="-mt-6">
                    <p class="text-base leading-6 text-gray-500 text-center">
                    Sunday - closed                                                 </p>
                                              
                </p>
            </li>
          
       
        </ul>
    </div>
      <div class="max-w-screen-xl  mx-auto m-6">
    <h1 class=" text-2xl text-center font-black leading-none text-gray-800 uppercase font-bebas-neue mb-2 ">
    Store Address 
</h1>
<div class="w-full shadow-2xl">
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3867.83499931718!2d89.64822947569262!3d27.453029036662045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e19315028b9fd5%3A0xfe77d0bace8a1647!2sMountain%20Crafts%20Bhutan!5e1!3m2!1sen!2sbt!4v1725992548100!5m2!1sen!2sbt"              
                  height="350"
                  class="object-contain w-full"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                />             </div>



    </div>
</>

{/* <div class="flex flex-wrap w-full">
    <div class="flex flex-col w-full md:w-1/2">
       
        <div class="flex flex-col justify-center px-8 pt-8 my-auto md:justify-start md:pt-0 md:px-24 lg:px-32">
           
        
<form class="flex w-full max-w-sm space-x-3">
    <div class="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-white rounded-lg shadow ">
        <div class="mb-6 text-3xl font-light text-center text-gray-800 ">
            Contact us !
        </div>
        <div class="grid max-w-xl grid-cols-2 gap-4 m-auto">
            <div class="col-span-2 lg:col-span-1">
                <div class=" relative ">
                    <input type="text" id="contact-form-name" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Name"/>
                    </div>
                </div>
                <div class="col-span-2 lg:col-span-1">
                    <div class=" relative ">
                        <input type="text" id="contact-form-email" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="email"/>
                        </div>
                    </div>
                    <div class="col-span-2">
                        <label class="text-gray-700" for="name">
                            <textarea class="flex-1 w-full px-4 py-2 text-base text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" id="comment" placeholder="Enter your comment" name="comment" rows="5" cols="40">
                            </textarea>
                        </label>
                    </div>
                    <div class="col-span-2 text-right">
                        <button type="submit" class="py-2 px-4  bg-blue-500 hover:bg-blue-600 focus:ring-blue-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </form>

                  
                </div>
            </div>
            <div class="w-1/2 shadow-2xl">
                <img class=" object-fit w-full h-screen " src="/contact.jpeg"/>
            </div>
        </div> */}

<Footer></Footer>
</>
  


    );
}

export default ContactUs;