
import React,{useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import Header from './Header';

import {UserContex} from '../App'
import Footer from './Footer';


const Mission = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[skills,setSkills]=useState([])
  const[docs,setDocs]=useState([])
  const[salary,setSalary]= useState("")
  const [dob,setDob]= useState(new Date())
  const [url,setUrl] = useState("")  

  const PostData =()=>{

   
//     fetch("/signinemployee",{
//       method:"post",
//       headers:{
//           "Content-Type":"application/json"
//       },
//       body: JSON.stringify({
        
//           password,
//           email:email
//       })
      
//   }).then(res=>res.json())
//   .then(data=> {
     
//       if(data.error){
//         toast.error(data.error)

//       }
//       else{
//         console.log("Login Successful")
//           localStorage.setItem("jwt", data.token)
//           localStorage.setItem("user", JSON.stringify( data.user))
//           dispatch({type:"USER", payload:data.user})
//           history.push('/dashboard')
//       }
//   })
//   .catch(err=>{
//       console.log(err)
//   })

       }

    return(


<>
<Header></Header>


<div class=" mt-20 p-4">
 
<div class="flex flex-wrap w-full">
    
   
    
    <div class="max-w-screen-xl  mx-auto ">
    <h1 class=" text-2xl text-center font-black leading-none text-gray-800 uppercase font-bebas-neue  ">
    Our Mission
</h1>
        <ul class="flex flex-wrap items-center gap-8">
            <li class="w-full">
               
                <p class="mt-2">
                    <p class="text-base leading-6 text-gray-500 text-justify">
                    Mountain Crafts is dedicated to preserving Bhutan's indigenous heritage and empowering rural artisans through sustainable, fair trade practices. We showcase exquisite, handcrafted Bhutanese textiles and handicrafts, connecting traditional artisans with global markets. Our mission is to promote cultural preservation, stimulate passion for eco-friendly, nature-based products, and support sustainable businesses. By investing profits back into producer communities, we foster economic growth and well-being. Mountain Crafts is committed to sharing Bhutan's rich cultural legacy while ensuring a sustainable future for its artisans and traditional crafts.                            </p>
                </p>
            </li>
          
       
        </ul>
    </div>
    <div class="max-w-screen-xl  mx-auto mt-8">
    <h1 class=" text-2xl text-center font-black leading-none text-gray-800 uppercase font-bebas-neue  ">
    Preserving Heritage, Empowering Communities, Protecting Nature
    </h1>
    
        <ul class="flex flex-wrap items-center gap-8">
            <li class="w-full">
               
                <p class="mt-2">
                    <p class="text-base leading-6 text-gray-500 text-justify mb-2">
                    At Mountain Crafts, we believe that sustainable change begins with conscious choices. Whether it's opting for a handwoven Himalayan Nettle bag or adorning yourself with a Kishuthara silk garment, every purchase you make with us is a step towards preserving Bhutan's rich cultural heritage and supporting sustainable practices.
For each item sold, we ensure that 50% of the proceeds go directly back to our artisans, fostering economic growth in rural Bhutanese communities and preserving indigenous crafts. Our products are created with an Earth-First approach, crafted in fair, safe working conditions, and constructed using only sustainably sourced materials.
           
           </p>
                    <p class="text-base leading-6 text-gray-500 text-justify mb-2">

    At Mountain Crafts, we source only the most sustainable and culturally significant textiles from the Himalayan region. Our products, made from materials like Himalayan Nettle, organic cotton, Kishuthara silk, and Yathra wool, significantly reduce environmental impact while preserving traditional crafts. Each item tells a story of cultural richness and artisanal skill. By choosing our eco-friendly products, you join us in preserving Bhutanese heritage, empowering local communities, and protecting our planet - one beautiful, handcrafted piece at a time.“Learn More”   
           </p>
                    <p class="text-base leading-6 text-gray-500 text-justify">

                    The rural communities we partner with are the backbone of Bhutanese craftsmanship. We ensure that all our artisans work in safe environments, receive fair compensation, and have equal opportunities regardless of gender. Our commitment extends beyond fair wages - we're dedicated to preserving and promoting the unique skills and traditions that have been passed down through generations.
Before any collaboration begins, we require all our partners to adhere to strict ethical guidelines. Regular audits ensure ongoing commitment to our code of conduct, guaranteeing that everyone involved with Mountain Crafts is respected, valued, and empowered. “Learn More”
           </p>
                </p>
            </li>
          
       
        </ul>
    </div>

    



            </div>

            <div class="container p-6 px-6 mx-auto bg-white ">
    <div class="mb-16 text-center">
    <h4 class="mt-8 text-2xl font-extrabold leading-8 text-gray-900  sm:text-3xl sm:leading-9">
                What makes our products unique?
                </h4>
                <p class="mt-4 text-lg leading-6 text-gray-500 ">
                Mountain Crafts is a platform for showcasing and marketing exotic Bhutanese products handcrafted by rural communities in Bhutan.

</p>
    </div>
  
<section>
    <div class="container  bg-white max-w-7xl  dark:bg-gray-800">
        <div class="flex flex-wrap ">
            
            <div class="w-full px-8 lg:w-1/2">
                <ul class="space-y-12">
                    <li class="flex -mx-4">
                        <div class="px-4">
                            <span class="flex items-center justify-center w-16 h-16 mx-auto text-2xl font-bold text-blue-600 rounded-full font-heading bg-blue-50">
                                1
                            </span>
                        </div>
                        <div class="px-4">
                            <h3 class="my-4 text-xl font-semibold dark:text-white">
                            Eco-Friendly Craftsmanship                            </h3>
                            <p class="leading-loose text-gray-500 dark:text-gray-300">
                            Our products are crafted using environmentally friendly methods and non-toxic, natural fibers and fabrics.
                            </p>
                        </div>
                    </li>
                    <li class="flex -mx-4">
                        <div class="px-4">
                            <span class="flex items-center justify-center w-16 h-16 mx-auto text-2xl font-bold text-blue-600 rounded-full font-heading bg-blue-50">
                                2
                            </span>
                        </div>
                        <div class="px-4">
                            <h3 class="my-4 text-xl font-semibold dark:text-white">
                            Natural Dyes                            </h3>
                            <p class="leading-loose text-gray-500 dark:text-gray-300">
                            We exclusively use plant-based colors and dyes, ensuring our products are both beautiful and sustainable.
                            </p>
                        </div>
                    </li>
                    <li class="flex -mx-4">
                        <div class="px-4">
                            <span class="flex items-center justify-center w-16 h-16 mx-auto text-2xl font-bold text-blue-600 rounded-full font-heading bg-blue-50">
                                3
                            </span>
                        </div>
                        <div class="px-4">
                            <h3 class="my-4 text-xl font-semibold dark:text-white">
                            Empowering Rural Artisans                            </h3>
                            <p class="leading-loose text-gray-500 dark:text-gray-300">
                            Our crafts provide vital income to rural communities, supporting local economies and traditional livelihoods.
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="w-full px-8 lg:w-1/2">
                <ul class="space-y-12">
                    <li class="flex -mx-4">
                        <div class="px-4">
                            <span class="flex items-center justify-center w-16 h-16 mx-auto text-2xl font-bold text-blue-600 rounded-full font-heading bg-blue-50">
                                4
                            </span>
                        </div>
                        <div class="px-4">
                            <h3 class="my-4 text-xl font-semibold dark:text-white">
                            Preserving Ancient Traditions
                            </h3>
                            <p class="leading-loose text-gray-500 dark:text-gray-300">
                            Our products are woven by indigenous women, drawing on multi-generational techniques and cultural heritage.
                            </p>
                        </div>
                    </li>
                    <li class="flex -mx-4">
                        <div class="px-4">
                            <span class="flex items-center justify-center w-16 h-16 mx-auto text-2xl font-bold text-blue-600 rounded-full font-heading bg-blue-50">
                                5
                            </span>
                        </div>
                        <div class="px-4">
                            <h3 class="my-4 text-xl font-semibold dark:text-white">
                            Cultural Documentation                             </h3>
                            <p class="leading-loose text-gray-500 dark:text-gray-300">
                            We actively support research and documentation of indigenous crafts, helping to preserve Bhutan's rich artistic legacy.
                            </p>
                        </div>
                    </li>
                    <li class="flex -mx-4">
                        <div class="px-4">
                            <span class="flex items-center justify-center w-16 h-16 mx-auto text-2xl font-bold text-blue-600 rounded-full font-heading bg-blue-50">
                                6
                            </span>
                        </div>
                        <div class="px-4">
                            <h3 class="my-4 text-xl font-semibold dark:text-white">
                            Authentic Bhutanese Artistry                            </h3>
                            <p class="leading-loose text-gray-500 dark:text-gray-300">
                            Mountain Crafts serves as a platform for showcasing and marketing exquisite, handcrafted products from rural Bhutan.
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

</div>


     


</div>

<Footer></Footer>


</>
  


    );
}

export default Mission;