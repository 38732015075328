
import React,{useContext, useEffect, useState} from 'react'
import {Link, useN, useNavigate} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import Header from './Header';
import 'react-toastify/dist/ReactToastify.css';

import {UserContex} from '../App'
import Footer from './Footer';

import { loadStripe } from '@stripe/stripe-js';



const Success = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const [checkOut,setCheckOut]= useState(false)
  const[id,setId] = useState("")  
//   const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[media,setMedia]= useState("")
  const[searchWord,setSearchWord]= useState("")
  const[found,setFound]= useState("")
  const[lookingfor,setLookingFor]= useState("")
//   const[address,setAddress]= useState("")
  const[allAddress,setAllAddress]= useState([])

//   const[email,setEmail]= useState("")

//   useEffect(  async()=>{

//       name =await JSON.parse( localStorage.getItem("name"))

//      email = await JSON.parse( localStorage.getItem("email"))
//    address = await JSON.parse( localStorage.getItem("address"))
    
//     const cartp =   await JSON.parse( localStorage.getItem("cart"))

//     const response = await fetch("/addOrder",{
//         method:"post",
//         headers:{
//             Authorization:"Bearer "+localStorage.getItem("jwt"),
//             "Content-Type":"application/json"
//         },
//         body: JSON.stringify({
//             orderItem:cartp,address,name,email,date
            
//         })
        
//     })
    
//     const data = response.json()

    
//     // .then(res=>res.json())
//     // .then(data=> {
       
//         if(data.error){
//           toast.error(data.error)
  
//         }
//         else{
//             localStorage.removeItem("cart")
//             localStorage.removeItem("cartid")
//        toast.success("Order Placed")
//     //    history('/cart')
//         }
//     // })
//     // .catch(err=>{
//     //     console.log(err)
//     // })
  

//   },[])



useEffect(() => {
    const placeOrder = async () => {
      try {
        const details = JSON.parse(localStorage.getItem("details"));
        const coupon = JSON.parse(localStorage.getItem("coupon"));
        // const email = JSON.parse(localStorage.getItem("email"));
        // const address = JSON.parse(localStorage.getItem("address"));
        const cartp = JSON.parse(localStorage.getItem("cart"));
        const date = new Date().toISOString();


 
  
        const response = await fetch("/addOrder", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            orderItem: cartp,
            address:details.address,
            name:details.name,
            email:details.email,
            date
          })
        });
  
        const data = await response.json();
  
        if (data.error) {
          toast.error(data.error);
        } else {
          localStorage.removeItem("cart");
          localStorage.removeItem("cartid");
          localStorage.removeItem("coupon");
          toast.success("Order Placed");
          // history('/cart'); // Uncomment and use your routing mechanism here
        }


        if(coupon){
          const couresponse = await fetch("/disable-coupons", {
            method: "POST",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwt"),
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
            
              email:coupon.email,
              coupon:coupon.coupon,
            })
          });

          const coup = await couresponse.json();

          console.lof(coup)

        }
      } catch (err) {
        console.error(err);
        toast.error("An error occurred while placing the order.");
      }
    };
  
    placeOrder();
  }, []);






const postFeedback = ()=>{


    fetch('/addFeedback',{

      method:"post",
      headers:{
        "Content-Type":"application/json",

      },
      body: JSON.stringify({

          media,found,searchWord,lookingfor

      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        toast.error("Error")
      }

      else{
toast.success(data.message)
history('/')
      }
    })
    .catch(err=>{
      console.log(err)
    })

  


}



      
    return(


<>
<Header></Header>





<ToastContainer/>





<div className="flex items-center justify-center min-h-screen bg-gray-100">
  


{/* <div class="relative overflow-hidden bg-white rounded-lg shadow w-60 md:w-72 mt-24 object-center">
    <img src="/logo.png" alt="btc logo" class="absolute w-32 h-32 rounded-full opacity-50 -top-6 -right-6 md:-right-4"/>
    <div class="px-4 py-5 sm:p-6">
        <dl>
            <dt class="text-sm font-medium leading-5 text-gray-500 truncate">
                Thank You
            </dt>
            <dd class="mt-1 text-3xl font-semibold leading-9 text-gray-900">
            Your order is being placed SUCESSFULLY
            </dd>
            <dd class="font-semibold text-gray-500">
                <span>
                  
                    We have mailed you the order summary
                </span>
            </dd>
        </dl>
    </div>
</div>
 */}

<section class=" bg-gray-100/50">
    <div class="container max-w-2xl mx-auto shadow-md ">
        <div class="p-4 border-t-2 border-green-400 rounded-lg bg-gray-100/5 ">
            <div class="max-w-sm mx-auto md:w-full md:mx-0">
                <div class="inline-flex items-center space-x-4">
               
                    <h1 class="text-green-600">
                    Your order is being placed SUCESSFULLY
                    </h1>
                </div>
            </div>
        </div>
        <div class="space-y-6 bg-white">
          <h1 className='text-center'>
          Help us with your feedback:

          </h1>

            <div class="items-center w-full p-4 space-y-4 text-gray-500  md:space-y-0">
                <h2 class="max-w-sm mx-auto ">
                How did you hear about us? 
                </h2>
                <div class="max-w-sm mx-auto ">
                    <div class=" relative ">
                        <select type="text" id="user-info-email" class=" rounded-lg border-black flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Email"
                          value={media}
                          onChange={(e)=>setMedia(e.target.value)}
                        >

                            <option>Select how did you hear about us</option>
                            <option>Facebook</option>
                            <option>Instagram</option>
                            <option>TikTok</option>
                            <option>Friends</option>
                            <option> Search</option>
                          </select>
                        </div>
                    </div>

                    {media=="Search"?<>
                    
                      <h2 class="max-w-sm mx-auto ">
                What did you search? 
                </h2>
                     <div class="max-w-sm mx-auto ">
                     <div class=" relative ">
                         <select type="text" id="user-info-email" class=" rounded-lg border-black flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Email"
                           value={searchWord}
                           onChange={(e)=>setSearchWord(e.target.value)}
                         >
 
                            
                           </select>
                         </div>
                     </div>
                    </>
                    
                    :<></>
                  
                  }
               
                </div>
                <hr/>
            <div class="items-center w-full p-4 space-y-4 text-gray-500  md:space-y-0">
                <h2 class="max-w-sm mx-auto ">
                Did they find what they were looking for?  
                </h2>
                <div class="max-w-sm mx-auto ">
                    <div class=" relative ">
                        <select type="text" id="user-info-email" class=" rounded-lg border-black flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Email"
                        
                        value={found}
                        onChange={(e)=>setFound(e.target.value)}
                        >

                            <option>Yes</option>
                            <option>No</option>
                           
                          </select>
                        </div>
                    </div>


                    {found=="No"?<>
                    
                    <h2 class="max-w-sm mx-auto ">
                     What were you looking for ?             </h2>
                   <div class="max-w-sm mx-auto ">
                   <div class=" relative ">
                       <select type="text" id="user-info-email" class=" rounded-lg border-black flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Email"
                         value={lookingfor}
                         onChange={(e)=>setLookingFor(e.target.value)}
                       >

                          
                         </select>
                       </div>
                   </div>
                  </>
                  
                  :<></>
                
                }
             
                </div>
             
                        <hr/>
                       
                            <hr/>
                            <div class="w-full px-4 pb-4 ml-auto text-gray-500 ">
                                <button  class="py-2 px-4  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                onClick={()=>{postFeedback()}}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </section>




        </div>



<Footer></Footer>





    









</>
  


    );
}

export default Success;






