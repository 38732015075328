
import React,{useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import Header from './Header';

import {UserContex} from '../App'
import Footer from './Footer';


const OurMaterials = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[skills,setSkills]=useState([])
  const[docs,setDocs]=useState([])
  const[salary,setSalary]= useState("")
  const [dob,setDob]= useState(new Date())
  const [url,setUrl] = useState("")  

  const PostData =()=>{

   
//     fetch("/signinemployee",{
//       method:"post",
//       headers:{
//           "Content-Type":"application/json"
//       },
//       body: JSON.stringify({
        
//           password,
//           email:email
//       })
      
//   }).then(res=>res.json())
//   .then(data=> {
     
//       if(data.error){
//         toast.error(data.error)

//       }
//       else{
//         console.log("Login Successful")
//           localStorage.setItem("jwt", data.token)
//           localStorage.setItem("user", JSON.stringify( data.user))
//           dispatch({type:"USER", payload:data.user})
//           history.push('/dashboard')
//       }
//   })
//   .catch(err=>{
//       console.log(err)
//   })

       }

    return(


<>
<Header></Header>

<div class="relative md:block lg:block hidden mt-12">

<img src="Cotton.jpg" class="absolute h-full w-full object-cover"/>
{/* <div class="absolute inset-0 bg-purple-500 opacity-50"></div> */}
<div class="absolute inset-0 overflow-hidden">
    <div class="absolute inset-0 bg-gradient-to-r from-black to-transparent"></div>
  </div>


 <div class="container mx-auto px-6 md:px-12 relative z-10 flex items-center p-32">
        <div class=" flex flex-col  relative z-10">
            <span class="font-bold uppercase text-white md:animate-bounce repeat-1 duration-1000">
                ____________________
            </span>
            <h1 class=" font-bold text-4xl md:text-6xl text-white text-center leading-tight mt-2  ">
            Our Materials 
               
                
            
            </h1>
           
        </div>
    </div>
   
   
    </div>

<div class="p-4">



 
<div class="flex flex-wrap w-full">
    
   
  
    <div class="max-w-screen-xl  mx-auto ">
  
        <ul class="flex flex-wrap items-center gap-8">
            <li class="w-full">
               
                <p class="mt-2">
                    <p class=" font-bebas-neue leading-6 text-gray-700 text-justify text-lg ">
                    At Mountain Crafts, we prioritize sustainability and cultural heritage in every aspect of our work. Our mission is to showcase the exquisite craftsmanship of Bhutanese artisans while promoting eco-friendly practices. Discover how our unique materials reflect our dedication to the environment and local communities.                            </p>
                </p>
            </li>
          
       
        </ul>
    </div>
 
  
    



            </div>

            <h1 class=" text-2xl mt-10 text-center font-black leading-none text-gray-800 uppercase font-bebas-neue  ">
    Our Commitment to Sustainability
    </h1>


<div class="flex-wrap items-center justify-center gap-8 text-center sm:flex">
    <div class="w-full px-4 py-4 mt-6 bg-white rounded-lg shadow-lg sm:w-1/2 md:w-1/2 lg:w-1/4 ">
        <div class="flex-shrink-0">
            <div class="flex items-center justify-center w-12 h-12 mx-auto text-white bg-emerald-500 rounded-md">
            {/* <svg class="h-8 w-8 text-white"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17.905 13.057c2.208 1.756 3.436 3.308 3.012 4.035-.67 1.146-5.204-.204-10.129-3.016-4.924-2.812-8.374-6.022-7.705-7.168.418-.716 2.347-.458 4.936.524" />  <circle cx="12" cy="12" r="6" /></svg> */}
            <svg class="h-8 w-8 text-white"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="4 19 8 13 12 15 16 10 20 14 20 19 4 19" />  <polyline points="4 12 7 8 11 10 16 4 20 8" /></svg>
            </div>
        </div>
        <h3 class="py-4 text-2xl font-semibold text-gray-700 sm:text-xl ">
        Our Approach
                </h3>
        <p class="py-4 text-gray-500 text-md ">
        We create beautiful, handcrafted products that celebrate Bhutan's rich textile traditions. By using materials like Himalayan Nettle, organic cotton, Kishuthara silk, Yathra wool, and natural dyes, we ensure that each item is not only unique but also sustainably sourced and ethically produced.        </p>
    </div>
    <div class="w-full px-4 py-4 mt-6 bg-white rounded-lg shadow-lg sm:w-1/2 md:w-1/2 lg:w-1/4 sm:mt-16 md:mt-20 lg:mt-24 ">
        <div class="flex-shrink-0">
            <div class="flex items-center justify-center w-12 h-12 mx-auto text-white bg-emerald-500 rounded-md">
            {/* <svg class="h-8 w-8 text-white"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 12l2 2l4 -4" />  <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" /></svg> */}
            <svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"/>
</svg>

            </div>
        </div>
        <h3 class="py-4 text-2xl font-semibold text-gray-700 sm:text-xl ">
        Our Vision
        </h3>
        <p class="py-4 text-gray-500 text-md ">
        The fashion industry faces significant challenges, from environmental degradation to cultural erosion. At Mountain Crafts, we believe in providing an alternative that prioritizes sustainability and authenticity. By choosing our products, you support local artisans and help preserve traditional crafts that are at risk of disappearing.        </p>
    </div>
    <div class="w-full px-4 py-4 mt-6 bg-white rounded-lg shadow-lg sm:w-1/2 md:w-1/2 lg:w-1/4 ">
        <div class="flex-shrink-0">
            <div class="flex items-center justify-center w-12 h-12 mx-auto text-white bg-emerald-500 rounded-md">
            <svg class="h-8 w-8 text-white"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="12" cy="12" r="1" />  <circle cx="12" cy="12" r="5" />  <circle cx="12" cy="12" r="9" /></svg>
            </div>
        </div>
        <h3 class="py-4 text-2xl font-semibold text-gray-700 sm:text-xl ">
        Our Goals
        </h3>
        <p class="py-4 text-gray-500 text-md ">
        We are committed to empowering rural communities in Bhutan by providing access to global markets while ensuring that 50% of all proceeds go back to our artisans. We strive for transparency in our supply chain and continuously seek innovative solutions to enhance sustainability and support local economies.        </p>
    </div>
</div>


    

<h1 class=" text-2xl mt-20 text-center font-black leading-none text-gray-800 uppercase font-bebas-neue  ">
    Explore Our Fabric
    </h1>
<div class="w-full p-12 bg-white">
   
        <div class="md:px-6 md:py-6   md:rounded-lg md:bg-gray-50 md:shadow-lg">

<div class="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3 place-items-center">
    <div class="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="/nettle" class="block w-full h-full">
                    <img alt="blog photo" src="/Nettle.jpg" class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white ">
                      
                        <p class="mb-2 text-xl font-medium text-gray-800 ">
                        Himalayan Nettle
                        </p>
                        <p class="font-light text-gray-500  text-md">
                        "Embrace the strength of Himalayan Nettle—sustainably woven, naturally beautiful."                        </p>
                       
                    </div>
                </a>
            </div>
            <div class="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="/cotton" class="block w-full h-full">
                    <img alt="blog photo" src="/OrganicCotton.jpg" class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white ">
                     
                        <p class="mb-2 text-xl font-medium text-gray-800 ">
                        Organic Cotton
                        </p>
                        <p class="font-light text-gray-500  text-md">
                        "Experience comfort with conscience: our organic cotton is good for communities, your skin, and the planet."                        </p>
                       
                    </div>
                </a>
            </div>
            <div class="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="/kishuthara" class="block w-full h-full">
                    <img alt="blog photo" src="/kishu.jpeg" class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white ">
                        
                        <p class="mb-2 text-xl font-medium text-gray-800 ">
                        Kishuthara Silk
                        </p>
                        <p class="font-light text-gray-500  text-md">
                        "Indulge in the elegance of Kishuthara Silk—ethically harvested and woven with ancient artistry for a sustainable future."                        </p>
                    
                    </div>
                </a>
            </div>
            <div class="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="/silk-on-cotton" class="block w-full h-full">
                    <img alt="blog photo" src="/SilkOnCotton.jpeg" class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white ">
                        
                        <p class="mb-2 text-xl font-medium text-gray-800 ">
                        Silk on Cotton
                        </p>
                        <p class="font-light text-gray-500  text-md">
                        "Experience the luxury of Silk on Cotton—where ethical elegance meets sustainable comfort."                        </p>
                     
                    </div>
                </a>
            </div>
            {/* <div class="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="/yathra" class="block w-full h-full">
                    <img alt="blog photo" src="/Yathra.jpeg" class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white ">
                        
                        <p class="mb-2 text-xl font-medium text-gray-800 ">
                        Yathra                        </p>
                        <p class="font-light text-gray-500  text-md">
                        "Stay warm and connected to tradition with Yathra—crafted from nature's finest wool, preserving culture and community."
                        </p>
                     
                    </div>
                </a>
            </div> */}
            <div class="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="/yathra" class="block w-full h-full">
                    <img alt="blog photo" src="/Yathra.jpeg" class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white ">
                      
                        <p class="mb-2 text-xl font-medium text-gray-800 ">
                        Yathra
                        </p>
                        <p class="font-light text-gray-500  text-md">
                        
                    "Stay warm and connected to tradition with Yathra—crafted from nature's finest wool, preserving culture and community."
                        </p>
                        
                    </div>
                </a>
            </div>
            <div class="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="/natural-dyes" class="block w-full h-full">
                    <img alt="blog photo" src="/natural.jpeg" class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white ">
                       
                        <p class="mb-2 text-xl font-medium text-gray-800 ">
                        Natural Dyes
                        </p>
                        <p class="font-light text-gray-500  text-md">
                        "Color your world naturally—our plant-based dyes are safe for artisans, wearers, and the environment."
                        </p>
                      
                    </div>
                </a>
            </div>
        </div>
        </div>
    </div>


</div>

<Footer></Footer>


</>
  


    );
}

export default OurMaterials;