
import React,{useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import Header from './Header';

import {UserContex} from '../App'
import Footer from './Footer';


const Sustainable = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[skills,setSkills]=useState([])
  const[docs,setDocs]=useState([])
  const[salary,setSalary]= useState("")
  const [dob,setDob]= useState(new Date())
  const [url,setUrl] = useState("")  

  const PostData =()=>{

   
//     fetch("/signinemployee",{
//       method:"post",
//       headers:{
//           "Content-Type":"application/json"
//       },
//       body: JSON.stringify({
        
//           password,
//           email:email
//       })
      
//   }).then(res=>res.json())
//   .then(data=> {
     
//       if(data.error){
//         toast.error(data.error)

//       }
//       else{
//         console.log("Login Successful")
//           localStorage.setItem("jwt", data.token)
//           localStorage.setItem("user", JSON.stringify( data.user))
//           dispatch({type:"USER", payload:data.user})
//           history.push('/dashboard')
//       }
//   })
//   .catch(err=>{
//       console.log(err)
//   })

       }

    return(


<>
<Header></Header>

<div className="relative h-screen overflow-hidden scroll-smooth bg-indigo-900 md:block ">

<img src="https://cdn.pixabay.com/photo/2017/08/06/06/34/woman-2589513_1280.jpg" className="absolute object-cover  w-full h-full" alt="Background" />
{/* <video autoPlay loop muted playbackRate={2.0}  class=" absolute h-1/2 w-full object-fill md:h-full md:w-full md:object-cover">
  <source src="/bgvid.mp4" type="video/mp4" />
     </video> */}



<div className="absolute inset-0 bg-black opacity-25"></div>


 
<div className="relative z-10 flex flex-col items-center justify-center h-full w-full text-center max-w-lg mx-auto ">
  <div className="flex items-center justify-center w-full h-full">
    <h1 
      className=" font-serif  leading-tight text-white text-6xl whitespace-nowrap transition-transform transition-opacity duration-[200ms] ease-in-out"
     
    >
We believe quality products 
<br></br>

can elevate your life

</h1>

  </div>
  <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-4 animate-pulse"
  
  >
    <svg className="h-14 w-14 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <polyline points="7 13 12 18 17 13" />
      <polyline points="7 6 12 11 17 6" />
    </svg>
  </div>
  
 
 
</div>
</div>


<div className='bg-stone-200'>
    <div className='p-24 w-11/12' >


<hr className='border-black'/>
<div class="bg-stone-200 overflow-hidden flex  ">
    <div class="text-start  py-2 px-4 z-20">
        <h2 class="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
            <span class="block">
            Materials

            </span>
           
        </h2>
        <p class="text-xl mt-4 text-gray-400">
        We're working hard to use sustainably produced materials when possible, like our Organic Percale Sheets, Organic Cotton Towels and Organic Linen Collection.             </p>
        <div class="lg:mt-0 lg:flex-shrink-0">
            <div class="mt-12 inline-flex rounded-md shadow">
                <a href='/materials'  class="py-4 px-6  bg-stone-600 hover:bg-stone-700 focus:ring-stone-500 focus:ring-offset-stone-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                    Learn More
                </a>
            </div>
        </div>
    </div>
    <img src="https://res.cloudinary.com/daehmzcuc/image/upload/v1734111681/WhatsApp_Image_2024-12-13_at_11.37.24_PM_obfl1k.jpg" class="  h-1/3 w-1/3  "/>
</div>

</div>
<div className='p-24 w-11/12' >


<hr className='border-black'/>
<div class="bg-stone-200 overflow-hidden flex  ">
<img src="https://res.cloudinary.com/daehmzcuc/image/upload/v1734111893/WhatsApp_Image_2024-12-13_at_11.42.16_PM_okvjbf.jpg" class="  h-1/3 w-1/3  "/>

    <div class="text-center  py-2 px-4 z-20">
        <h2 class="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
            <span class="block">
            Manufacturing
            </span>
           
        </h2>
        <p class="text-xl text-center mt-4 text-gray-400">
        We partner with factories who have a commitment to responsible production. This means paying workers fairly and doing their best to produce in a way that reduces damage to the environment.

</p>
        <div class="lg:mt-0 lg:flex-shrink-0">
            <div class="mt-12 inline-flex rounded-md shadow">
                <a href='/materials'  class="py-4 px-6  bg-stone-600 hover:bg-stone-700 focus:ring-stone-500 focus:ring-offset-stone-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                    Learn More
                </a>
            </div>
        </div>
    </div>
</div>

</div>
</div>



<div class="bg-white dark:bg-gray-800 ">
    <div class="text-center w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
        {/* <h2 class="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
            <span class="block">
            Preserving Heritage, Empowering Communities, Protecting Nature            </span>
            
        </h2> */}
        <p class="text-3xl p-12 font text-black">
        Did you know that 85% of clothes end up in the landfill or burned every year? At Mountain Crafts, we are working to combat this through our factory direct model, minimalistic packaging, and timeless, classic styles, so we can all do our part to better the planet in style.


        </p>
        
    </div>
</div>

<div class="bg-stone-200 p-12 ">

    

    <div class="mb-16 text-center">
    <h4 class="mt-8 text-2xl font-extrabold leading-8 text-gray-900  sm:text-3xl sm:leading-9">
    Transparency in Sustainability                </h4>
                <p class="mt-4 text-lg leading-6 text-gray-500 ">
                Mountain Crafts is a platform for showcasing and marketing exotic Bhutanese products handcrafted by rural communities in Bhutan.

</p>
    </div>
    <div class="flex flex-wrap my-12 ">
        <div class="w-full p-8 border-b md:w-1/2 md:border-r border-black lg:w-1/3">
            <div class="flex items-center mb-6">
            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full  ">
                                <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                <div class="ml-4 text-xl">
                Certified Brand Bhutan: 
                </div>
            </div>
            <p class="leading-loose text-gray-500  text-md">
            Majority of our products are 100% made in Bhutan, from raw materials to finished craftsmanship, meeting rigorous Brand Bhutan standards            </p>
        </div>
        <div class="w-full p-8 border-b md:w-1/2 lg:w-1/3 border-black lg:border-r">
            <div class="flex items-center mb-6">
            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full  ">
                                <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                <div class="ml-4 text-xl">
                Fair Trade Practices: 
                </div>
            </div>
            <p class="leading-loose text-gray-500  text-md">
            We ensure fair compensation for artisans, promoting sustainable livelihoods in rural Bhutanese communities.            </p>
        </div>
        <div class="w-full p-8 border-b md:w-1/2 md:border-r border-black lg:w-1/3 lg:border-r-0">
            <div class="flex items-center mb-6">
            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full  ">
                                <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                <div class="ml-4 text-xl">
                Sustainable Sourcing: 
                </div>
            </div>
            <p class="leading-loose text-gray-500  text-md">
            All materials are ethically sourced locally, supporting traditional and sustainable practices.            </p>
        </div>
        <div class="w-full p-8 border-b md:w-1/2 lg:w-1/3  border-black lg:border-r lg:border-b-0">
            <div class="flex items-center mb-6">
            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full  ">
                                <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                <div class="ml-4 text-xl">
                Cultural Preservation: 
                </div>
            </div>
            <p class="leading-loose text-gray-500  text-md">
            We actively preserve Bhutanese cultural heritage by promoting traditional crafts.            </p>
        </div>
        <div class="w-full p-8 border-b md:w-1/2 md:border-r md:border-b-0 border-black lg:w-1/3 lg:border-b-0">
            <div class="flex items-center mb-6">
            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full  ">
                                <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                <div class="ml-4 text-xl">
                Community Empowerment: 
                </div>
            </div>
            <p class="leading-loose text-gray-500  text-md">
            50% of proceeds go directly to artisans, sustaining local economies and traditional skills.            </p>
        </div>
        {/* <div class="w-full p-8 md:w-1/2 lg:w-1/3">
            <div class="flex items-center mb-6">
            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full  ">
                                <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                <div class="ml-4 text-xl">
                Supports research and documentation of indigenous crafts production
                </div>
            </div>
          
        </div> */}
    </div>


</div>





<Footer></Footer>


</>
  


    );
}

export default Sustainable;