
import React,{useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import Header from './Header';
import 'react-toastify/dist/ReactToastify.css';

import {UserContex} from '../App'
import Footer from './Footer';


const PhilanthropicGiving = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[skills,setSkills]=useState([])
  const[docs,setDocs]=useState()
  const[salary,setSalary]= useState("")
  const [dob,setDob]= useState(new Date())
  const [url,setUrl] = useState("")  

  const PostData =()=>{
    toast.success("Message Sent Sucessfully")

   
    fetch("/sendfeedback",{
      method:"post",
      headers:{
          "Content-Type":"application/json"
      },
      body: JSON.stringify({
        
          name:name,
          email:email,
          message:docs
      })
      
  }).then(res=>res.json())
  .then(data=> {
     
    
       toast.success("Message Sent Sucessfully")
      
  })
  .catch(err=>{
      console.log(err)
  })

       }

    return(


<>
<Header></Header>

<>



<div class="relative h-1/2 overflow-hidden bg-indigo-900">
    <img src="/phel.jpeg" class="absolute object-cover w-full h-full"/>
    <div className="absolute inset-0 bg-black opacity-20"></div>

  
    <div class="container relative z-10 flex items-center px-6 py-32 mx-auto md:px-12 xl:py-40">
        <div class="relative z-10 flex flex-col items-center w-full">
            <h1 class="mt-4 font-extrabold leading-tight text-center text-gray-200 text-3xl ">
            Mountain Crafts Bhutan: Preserving Heritage, Empowering Artisans            </h1>
            <h1 class="mt-4 font-extrabold leading-tight text-center text-gray-100 text-xl ">
            At Mountain Crafts Bhutan, we believe in the power of traditional craftsmanship to preserve cultural heritage and transform lives. Our philanthropic giving program offers a unique opportunity to make a lasting impact on Bhutan's rural communities and artisans while supporting sustainable, fair trade practices.
            </h1>
            <a href="#donate" class="block px-4 py-3 mt-24 text-lg font-bold text-white uppercase bg-gray-800 hover:bg-gray-900">
                Start Now
            </a>
        </div>
    </div>
</div>

<a href="#donate">




<button class="fixed  z-50 bottom-10 md:top-96 top-1/2 right-8 bg-orange-500 w-24 md:w-32 h-12 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-orange-600 hover:drop-shadow-2xl hover:animate-bounce duration-300"> 

<div class="p-4 bg-white shadow-lg rounded-2xl w-36 dark:bg-gray-800">
    <div class="flex items-center">
        
        <p class="ml-2 text-gray-700 text-sm text-bold dark:text-gray-50">
            Donate Now
        </p>
    </div>
    <div class="flex flex-col justify-start">
       
    <svg class="h-8 w-8 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="7" y="9" width="14" height="10" rx="2" />  <circle cx="14" cy="14" r="2" />  <path d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2" /></svg>
    </div>
</div>

</button>

</a>
<div class="max-w-screen-xl  mx-auto mt-8">
    <h1 class=" text-2xl text-center font-black leading-none text-gray-800 uppercase font-bebas-neue  ">
    Why Support Mountain Crafts Bhutan?
    </h1>
    
        <ul class="flex flex-wrap items-center gap-8">
            <li class="w-full">
               
                <p class="mt-2">
                    <p class="text-base leading-6 text-gray-500 text-justify mb-2">
                    By contributing to our mission, you're not just donating – you're investing in the future of Bhutan's rich cultural legacy and the livelihoods of its talented artisans. Your support helps us:
           </p>
          
                </p>
            </li>
          
       
        </ul>
        <ul class="w-full mt-6 mb-6 text-sm text-gray-800 ">
        <li class="mb-3 flex items-center ">
            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                </path>
            </svg>Preserve indigenous crafting techniques and cultural traditions
            </li>
     
        <li class="mb-3 flex items-center ">
            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                </path>
            </svg>Provide fair wages and sustainable income for rural artisans
            </li>
     
        <li class="mb-3 flex items-center ">
            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                </path>
            </svg>Promote eco-friendly, nature-based products
            </li>
     
        <li class="mb-3 flex items-center ">
            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                </path>
            </svg>Stimulate economic growth in remote communities
            </li>
     
        <li class="mb-3 flex items-center ">
            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                </path>
            </svg>Connect traditional craftspeople with global markets
            </li>
     
    </ul>
    </div>



    <div id="about" class="p-6 m-6 bg-white  flex flex-col md:flex-row overflow-hidden shadow-lg rounded-2xl "
>


    <div class="text-center md:w-full py-12 px-4 px-6 lg:py-16 lg:px-8 z-20 ">
        <h2 class="text-3xl font-extrabold text-gray-800  text-2xl">
            <span class="block">
            Your Impact

            </span>
            {/* <span class="block text-indigo-500">
            Crafting excellence with every cut: your one-stop woodworking solution
            </span> */}
        </h2>
        <p class="text-xl  mt-4 text-gray-600">
        Your generous contribution directly supports our initiatives:
Artisan Training Programs: $100 funds workshops to teach traditional crafting skills to a new generation of artisans.
Community Development: $500 helps establish sustainable business practices in rural villages, fostering economic growth.
Cultural Preservation: $1,000 supports the documentation and preservation of endangered crafting techniques.
Market Access: $5,000 enables us to expand our reach, connecting more artisans with international buyers.


        
          </p>
      
    </div>

    {/* <img src="about.png" class="  h-80 w-1/3 md:ml-14 mt-12"/> */}
    {/* <img src="/left.png" alt="btc logo" class="absolute  h-full    -right-24 md:-right-6"/>
    <img src="/right.png" alt="btc logo" class="absolute w-46 h-full    -left-24 md:-left-6"/> */}

</div>
  {/* component */}
  <style dangerouslySetInnerHTML={{__html: "@import url('https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.min.css')" }} />

  <div className="min-w-screen min-h-screen bg-gray-50 flex items-center justify-center px-5 ">
    <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden" style={{maxWidth: 1000}}>
      <div className="md:flex w-full">
      <div className="hidden md:block w-1/2  bg-blue-200 flex justify-center items-center">
  <img src='https://cdni.iconscout.com/illustration/premium/thumb/contact-us-illustration-download-in-svg-png-gif-file-formats--customer-support-service-representative-call-pack-business-illustrations-5059493.png?f=webp' alt="Logo" className="object-center mt-8" />
</div>
        <div id='donate' className="w-full md:w-1/2 py-10 px-5 md:px-10">
          <div className="text-center mb-10">
            <h1 className="font-bold text-3xl text-gray-900">Donate With Us </h1>
          </div>
          <div>
       

            <div className="flex -mx-3">
              <div className="w-full px-3 mb-5">
                <label htmlFor className="text-md text-gray-600 font-semibold px-1">Name</label>
                <div className="flex">
                  <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg" /></div>
                  <input type="email" required 
                  className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
                  placeholder="Name"
                  value={name}
                  onChange={(e)=>setName(e.target.value)}
                  />
                </div>
              </div>
            </div>


        
          

            <div className="flex -mx-3">
              <div className="w-full px-3 mb-5">
                <label htmlFor className="text-md text-gray-600 font-semibold px-1">Phone No</label>
                <div className="flex">
                  <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg" /></div>
                  <input type="email" required 
                  className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
                  placeholder="Phone no."
                  value={phone}
                  onChange={(e)=>setPhone(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="flex -mx-3">
              <div className="w-full px-3 mb-5">
                <label htmlFor className="text-md text-gray-600 font-semibold px-1">Email</label>
                <div className="flex">
                  <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg" /></div>
                  <input type="email" required 
                  className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
                  placeholder="youremail@example.com"
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
          
         
           
            <div className="flex -mx-3">
              <div className="w-full px-3 mb-5">
                <label htmlFor className="text-md text-gray-600 font-semibold px-1">Your Message</label>
                <div className="flex">
                  <div className="w-10 h-40 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg" /></div>
                  <input type="text" required 
                  className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
                  placeholder="Your Message"
                  value={docs}
                  onChange={(e)=>setDocs(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="w-full px-3 mb-5">
                <button className="block w-full max-w-xs mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold"
                
                onClick={()=> PostData()}
                >Submit</button>
               <ToastContainer/>

              </div>

           
              
    </div>
</div>

        
          </div>
        </div>
      </div>




<div class="container p-6 px-6 mx-auto bg-white dark:bg-gray-800">
    <div class="mb-16 text-center">
        {/* <h2 class="text-base font-semibold tracking-wide text-indigo-600 uppercase">
            Features
        </h2> */}
        <p class="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 dark:text-white sm:text-4xl">
        Ways to Give
        </p>
    </div>
    <div class="flex flex-wrap my-12 dark:text-white">
        <div class="w-full p-8 border-b md:w-1/2 md:border-r lg:w-1/3">
            <div class="flex items-center mb-6">
                <svg width="20" height="20" fill="currentColor" class="w-6 h-6 text-indigo-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                    </path>
                </svg>
                <div class="ml-4 text-xl">
                One-Time Donation
                </div>
            </div>
            <p class="leading-loose text-gray-500 dark:text-gray-200 text-md">
            Make an immediate impact with a single contribution.
            </p>
        </div>
        <div class="w-full p-8 border-b md:w-1/2 lg:w-1/3 lg:border-r">
            <div class="flex items-center mb-6">
                <svg width="20" height="20" fill="currentColor" class="w-6 h-6 text-indigo-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                    </path>
                </svg>
                <div class="ml-4 text-xl">
                Monthly Giving
                </div>
            </div>
            <p class="leading-loose text-gray-500 dark:text-gray-200 text-md">
            Join our "Guardians of Craft" program with a recurring monthly donation. You need to create a page where people can setup monthly donations.             </p>
        </div>
        <div class="w-full p-8 border-b md:w-1/2 md:border-r lg:w-1/3 lg:border-r-0">
            <div class="flex items-center mb-6">
                <svg width="20" height="20" fill="currentColor" class="w-6 h-6 text-indigo-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                    </path>
                </svg>
                <div class="ml-4 text-xl">
                Corporate Partnerships
                </div>
            </div>
            <p class="leading-loose text-gray-500 dark:text-gray-200 text-md">
            Align your brand with cultural preservation and sustainable development.            </p>
        </div>
      
        <div class="w-full p-8 border-b md:w-1/2 md:border-r md:border-b-0 lg:w-1/3 lg:border-b-0">
            <div class="flex items-center mb-6">
                <svg width="20" height="20" fill="currentColor" class="w-6 h-6 text-indigo-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                    </path>
                </svg>
                <div class="ml-4 text-xl">
                Legacy Giving                </div>
            </div>
            <p class="leading-loose text-gray-500 dark:text-gray-200 text-md">
            Leave a lasting impact by including Mountain Crafts Bhutan in your estate planning.
            </p>
        </div>
      
    </div>
</div>

<div class="text-center md:w-full py-12 px-4 px-6 lg:py-16 lg:px-8 z-20 ">
        <h2 class="text-3xl font-extrabold text-gray-800  text-2xl">
            <span class="block">
            The Mountain Crafts Bhutan Difference

            </span>
            {/* <span class="block text-indigo-500">
            Crafting excellence with every cut: your one-stop woodworking solution
            </span> */}
        </h2>
        <p class="text-xl  mt-4 text-gray-600">
        When you support Mountain Crafts Bhutan, you're not just giving – you're becoming part of a movement. Our commitment to transparency means you'll receive regular updates on how your contribution is making a difference. We invite our donors to visit our partner communities and witness firsthand the positive changes they're helping to create.
        
          </p>
      
    </div>
</>


<Footer></Footer>
</>
  


    );
}

export default PhilanthropicGiving;