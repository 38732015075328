
import React,{useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import Header from './Header';

import {UserContex} from '../App'
import Footer from './Footer';


const Kishu = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[skills,setSkills]=useState([])
  const[docs,setDocs]=useState([])
  const[salary,setSalary]= useState("")
  const [dob,setDob]= useState(new Date())
  const [url,setUrl] = useState("")  

    return(


<>
<Header></Header>

<div class="relative md:block lg:block hidden mt-12">

<img src="kishu.jpeg" class="absolute h-full w-full object-cover"/>
{/* <div class="absolute inset-0 bg-purple-500 opacity-50"></div> */}
<div class="absolute inset-0 overflow-hidden">
    {/* <div class="absolute inset-0 bg-gradient-to-r from-black to-transparent"></div> */}
  </div>


 <div class="container mx-auto px-6 md:px-12 relative z-10 flex items-center p-32">
        <div class=" flex flex-col  relative z-10">
            <span class="font-bold uppercase text-white md:animate-bounce repeat-1 duration-1000">
                ____________________
            </span>
            <h1 class=" font-bold text-4xl md:text-6xl text-white text-center leading-tight mt-2  ">
            Kishuthara Silk 
               
                
            
            </h1>
           
        </div>
    </div>
   
   
    </div>

<div class="p-4">



 
<div class="flex flex-wrap w-full">
    
   
  
  
 
  
    



            </div>

     

    <div class="max-w-screen-xl  mx-auto ">
    <h1 class=" text-2xl text-center font-black leading-none text-gray-800 uppercase font-bebas-neue  ">
    The Problem
</h1>
        <ul class="flex flex-wrap items-center gap-8">
            <li class="w-full">
               
                <p class="mt-2">
                    <p class="text-base leading-6 text-gray-500 text-justify">
                    Unsustainable Silk Production
Mass-produced silk often involves harmful practices that negatively impact both the environment and silkworms. Conventional silk production may use chemical treatments, contribute to deforestation for mulberry cultivation, and often involves boiling silkworms alive to extract silk fibers.
   </p>
                </p>
            </li>
          
       
        </ul>
    </div>
    <div class="max-w-screen-xl  mx-auto mt-8 ">
    <h1 class=" text-2xl text-center font-black leading-none text-gray-800 uppercase font-bebas-neue  ">
    Our Solution
</h1>
        <ul class="flex flex-wrap items-center gap-8">
            <li class="w-full">
               
                <p class="mt-2">
                    <p class="text-base leading-6 text-gray-500 text-justify">
                    Ethical Silk Craftsmanship
Mountain Crafts' Kishuthara Silk represents a sustainable and ethical approach to silk production:

                       </p>
                </p>
            </li>
          
       
        </ul>
        <ul class="w-full mt-6 mb-6 text-sm text-gray-600 ">
        <li class="mb-3 flex items-center ">
            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                </path>
            </svg>
            Utilizes traditional, non-violent methods of silk harvesting       </li>
     
        <li class="mb-3 flex items-center ">
            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                </path>
            </svg>Supports the natural lifecycle of silkworms, allowing them to complete their metamorphosis                 </li>
     
        <li class="mb-3 flex items-center ">
            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                </path>
            </svg>Employs ancient weaving techniques that have been refined over generations                 </li>
     
        <li class="mb-3 flex items-center ">
            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                </path>
            </svg>Each piece takes 6-8 months to create, ensuring unparalleled quality and reducing overproduction             </li>
     
        <li class="mb-3 flex items-center ">
            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                </path>
            </svg>Preserves Bhutan's rich textile heritage and supports skilled artisans in Khoma village                 </li>
     
    </ul>
    <ul class="flex flex-wrap items-center gap-8">
            <li class="w-full">
               
                <p class="mt-2">
                    <p class="text-base leading-6 text-gray-500 text-justify">
                    By choosing Kishuthara Silk, you're not only acquiring a luxurious, one-of-a-kind garment but also supporting sustainable practices that respect both nature and tradition.                                  </p>
                </p>
            </li>
          
       
        </ul>
    
    </div>

    <div class="max-w-screen-xl  mx-auto mt-12">
    <h1 class=" text-2xl text-center font-black leading-none text-gray-800 uppercase font-bebas-neue  ">
    Care instructions:</h1>
        <ul class="flex flex-wrap items-center gap-8">
            <li class="w-full">
               
                <p class="mt-2">
                    <p class="text-base leading-6 text-gray-500 text-justify">
                    Dry clean only to preserve delicate fibers and intricate patterns. If hand washing is necessary, use cold water and specialized silk detergent. Do not wring or twist. Lay flat to dry away from direct sunlight. Iron on low heat if needed.

                           </p>
                </p>
            </li>
          
       
        </ul>
    </div>
  


</div>

<Footer></Footer>


</>
  


    );
}

export default Kishu;