
// import exp from 'constants';
import React, { useState,createContext,useContext,useEffect,useReducer } from 'react';
import {BrowserRouter,Link,Route, Switch} from 'react-router-dom';
import NavBar from './nav'
import {UserContex} from '../App'

const Home = ()=>{


  
    const  [salesData, setSalesData]= useState()
    const  [salesDataToday, setSalesDataToday]= useState()
    const  [stockData, setStockData]= useState()
    const  [saleDataMonth, setStockDataMonth]= useState()
    const  [creditData, setCreditData]= useState([])
    const  [creditDataToday, setCreditDataToday]= useState([])
    const  [ExpenseData, setExpenseData]= useState()
    const  [Employee, setEmployeeData]= useState()
    const  [ExpenseDataMonth, setExpenseDataMonth]= useState()
    const  [data, setData]= useState([])
    const [search,setSearch] = useState("")

    const [date,setDate] = useState(new Date())

    const {state, dispatch}= useContext(UserContex)


var StockAmount = 0;

    //console.log(state.role)
  
    useEffect(  ()=>{
  

        //http://119.2.122.53:5001/data/log/level/anyday
      fetch(`/RevinewSummary`,{
        headers:{
            Authorization: "Bearer " +localStorage.getItem("jwt")
            
        }
    }).then(res=>res.json())
    .then(result=>{

        setData(result.stocks)
    
        let sum = 0
        let expensesum = 0
        let expensesummonth = 0
        let salessum = 0
        let creditsum = 0
        let creditsumtoday = 0
        let salessumtoday = 0
        let salessumMonth = 0

        result.stocks.map(sales=> {

            sum+=(sales.rate*sales.quantity)
        } )
       
       
        result.Expense.map(ecpenses=> {

            expensesum+=ecpenses.amount

            if (date.toISOString().substring(0,7)==ecpenses.date.substring(0,7))  expensesummonth += ecpenses.amount



        } )


        

        //     result.sales.map(sales=> sales.soldItem.map(pro=>{
              

        //         salessum += pro.product.rate*pro.qty

        //     sales.date? date.toISOString().substring(0,10)==sales.date.substring(0,10) ?  salessumtoday += pro.product.rate*pro.qty:<></>:<></>
        //     sales.date? date.toISOString().substring(0,7)==sales.date.substring(0,7)?  salessumMonth += pro.product.rate*pro.qty
        //     :<></>:<></>
                
            
          
        //   }) )
        

   


 
          if(result.credit){

              result.credit.map(sales=> {

                creditsum += sales.amount


                sales.date? date.toISOString().substring(0,10)==sales.date.substring(0,10) ?  creditsumtoday += sales.amount:<></>:<></>


                // if (date.toISOString().substring(0,10)==sales.date.substring(0,10))  creditsumtoday += pro.product.rate*pro.qty
                // if (date.toISOString().substring(0,7)==sales.date.substring(0,7))  cre += pro.product.rate*pro.qty

                //console.log(salessum)
          })

          }
        



        setStockData(sum)
        setSalesData(salessum)
        setSalesDataToday(salessumtoday)
        setStockDataMonth(salessumMonth)
        setCreditData(creditsum)
        setCreditDataToday(creditsumtoday)
        setEmployeeData(result.employee)
        setExpenseData(expensesum)
        setExpenseDataMonth(expensesummonth)
        

        
    })
  

  
  
  
    },[])


    const fetchPost=(query)=>{


        setSearch(query)
    
        fetch('/search-stock',{
    
          method:"post",
          headers:{

            Authorization: "Bearer " +localStorage.getItem("jwt"),
    
            "Content-Type":"application/json"
    
          },
          body:JSON.stringify({
            query
          })
    
        }).then(res=> res.json())
          .then(results=>{
            
            setData(results.stock)
            console.log(results)

          })
    
    
      }
  



    return(
            
<main class="bg-white  h-screen overflow-hidden relative">
    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full md:space-y-4">

            
            <NavBar> </NavBar>


    
            <div class="overflow-auto h-screen pb-24 px-4 md:px-6">
                <h1 class="text-4xl font-semibold text-black">
                    {state?state.name:""}
                </h1>
                <h2 class="text-md text-gray-400">
                    Welcome, Happy Managing!
                </h2>
                {/* <div class="flex my-6  w-full space-y-4 md:space-x-4 md:space-y-0 flex-col md:flex-row"> */}
                    


                    <div class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 my-4  justify-around ">
                        <div class="w-full shadow-lg">
                            <div class="shadow-lg px-4 py-6 w-full bg-white  relative">
                                <p class="text-2xl text-black  font-bold">
                               Nu. {salesDataToday?  salesDataToday : <></> }   
                                </p>
                                <p class="text-gray-400 text-sm">
                                   Todays Sale
                                </p>
                            </div>
                            <div class="w-full h-2 bg-gray-100  drop-shadow-2xl">
                                    <div class="w-full h-full text-center text-xs text-white bg-green-400">
                                    </div>
                                </div>
                        </div>
                       
                       
                        <div class="w-full rounded-lg shadow-lg ">
                            <div class=" shadow-lg px-4 py-6 w-full bg-white  relative">
                                <p class="text-2xl text-black  font-bold">
                               Nu. {saleDataMonth?  saleDataMonth : <></> }   
                                </p>
                                <p class="text-gray-400 text-sm">
                                    Sales This Month
                                </p>
                            </div>
                            <div class="w-full h-2 bg-gray-100">
                                    <div class="w-full h-full text-center text-xs text-white bg-green-400">
                                    </div>
                                </div>
                        </div>



                        <div class="w-full shadow-lg">
                            <div class="shadow-lg px-4 py-6 w-full bg-white  relative">
                                <p class="text-2xl text-black  font-bold">
                               Nu. {creditDataToday?  creditDataToday : <></> }   
                                </p>
                                <p class="text-gray-400 text-sm">
                                    Credit Today
                                </p>
                            </div>
                            <div class="w-full h-2 bg-gray-100">
                                    <div class="w-full h-full text-center text-xs text-white bg-red-500">
                                    </div>
                                </div>
                        </div>


                        <div class="w-full shadow-lg">
                            <div class="shadow-lg px-4 py-6 w-full bg-white  relative">
                                <p class="text-2xl text-black  font-bold">
                                  Nu  {ExpenseDataMonth}
                                </p>
                                <p class="text-gray-400 text-sm">
                                     Expense This Month
                                </p>
                                {/* <span class="rounded-full absolute p-4 bg-purple-500 top-2 right-4">
                                    <svg width="40" fill="currentColor" height="40" class="text-white h-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
                                        </path>
                                    </svg>
                                </span> */}
                            </div>
                            <div class="w-full h-2 bg-gray-100">
                                    <div class="w-full h-full text-center text-xs text-white bg-blue-300">
                                    </div>
                                </div>
                        </div>
                    </div>

                    <br></br>



                {/* </div> */}



                
               
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-4">
                   
                   
                   
                   
                   
                   
                   <Link to="/inventory">
                   
                   
<div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-purple-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polygon points="12 2 2 7 12 12 22 7 12 2" />  <polyline points="2 17 12 22 22 17" />  <polyline points="2 12 12 17 22 12" /></svg>        <p class="text-md text-black  ml-2">
            Inventory
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700  text-4xl text-left font-bold my-4">
            Nu. {stockData}
           
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
                Add and Update Inventory
            </span>
            
        </div>
    </div>
</div>



                   </Link>
                    
                   
                   
                   
                  



                    <Link to="/sales"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="9" cy="7" r="4" />  <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />  <path d="M16 11h6m-3 -3v6" /></svg>
        <p class="text-md text-black  ml-2">
            Walk-In Customer
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700  text-4xl text-left font-bold my-4">
            Nu. {creditData}
            
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
            Add Credit or Sales of customer

            </span>
            
        </div>
    </div>
</div>

                    </Link>
                    {/* <Link to="/allSales"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="12" cy="12" r="9" />  <path d="M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 0 0 0 4h2a2 2 0 0 1 0 4h-2a2 2 0 0 1 -1.8 -1" />  <path d="M12 6v2m0 8v2" /></svg>
            <p class="text-md text-black  ml-2">
            Direct Sales
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700  text-4xl text-left font-bold my-4">
            Nu. {salesData}
            
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
            Add Sales without customer details 

            </span>
            
        </div>
    </div>
</div>

                    </Link> */}



 <Link to="/employee"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="9" cy="7" r="4" />  <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />  <path d="M16 3.13a4 4 0 0 1 0 7.75" />  <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" /></svg>        <p class="text-md text-black  ml-2">
            Employee
        </p>
    </div>
    <div class="flex flex-col justify-start">
    <p class="text-gray-700  text-4xl text-left font-bold my-4">
             {Employee}
            
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
            Manage Employee Information 

            </span>
            
        </div>
    </div>
</div>

                    </Link>


 
                    <Link to="/expenses">
                   
                   <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
        <svg class="h-8 w-8 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="7" y="9" width="14" height="10" rx="2" />  <circle cx="14" cy="14" r="2" />  <path d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2" /></svg>
        <p class="text-md text-black  ml-2">
            Expenses
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700  text-4xl text-left font-bold my-4">
            Nu. {ExpenseData}
           
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
                Add Expenses 
            </span>
            
        </div>
    </div>
</div>


                   </Link>
                    


                     <Link to="/report"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="3 17 9 11 13 15 21 7" />  <polyline points="14 7 21 7 21 14" /></svg>    
     <p class="text-md text-black  ml-2">
            Report
        </p>
    </div>
    <div class="flex flex-col justify-start">
    <p class="text-gray-700  text-4xl text-left font-bold my-4">
             {3}
            
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
            View Report of Sales, Credits, Expense 

            </span>
            
        </div>
    </div>
</div>

                    </Link> 

          
                   




        
<Link to="/orders"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="7" cy="17" r="2" />  <circle cx="17" cy="17" r="2" />  <path d="M5 17h-2v-11a1 1 0 0 1 1 -1h9v6h-5l2 2m0 -4l-2 2" />  <path d="M9 17h6" />  <path d="M13 6h5l3 5v6h-2" /></svg>    
    
      <p class="text-md text-black  ml-2">
            Online Orders
        </p>
    </div>
    <div class="flex flex-col justify-start">
    <p class="text-white  text-4xl text-left font-bold my-4">
              O
            
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
            Manage Online Orders 

            </span>
            
        </div>
    </div>
</div>

                    </Link>
    
{/* <Link to="/onlineconsumer"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"/>
</svg>
    
      <p class="text-md text-black  ml-2">
            Online Customer
        </p>
    </div>
    <div class="flex flex-col justify-start">
    <p class="text-white  text-4xl text-left font-bold my-4">
             Online Customers
            
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
            Check online Customers 

            </span>
            
        </div>
    </div>
</div>

                    </Link> */}
<Link to="/partnerstores"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M8 9l5 5v7h-5v-4m0 4h-5v-7l5 -5m1 1v-6a1 1 0 0 1 1 -1h10a1 1 0 0 1 1 1v17h-8" />  <line x1="13" y1="7" x2="13" y2="7.01" />  <line x1="17" y1="7" x2="17" y2="7.01" />  <line x1="17" y1="11" x2="17" y2="11.01" />  <line x1="17" y1="15" x2="17" y2="15.01" /></svg>
    
      <p class="text-md text-black  ml-2">
            Partner Stores
        </p>
    </div>
    <div class="flex flex-col justify-start">
    <p class="text-white  text-4xl text-left font-bold my-4">
             Partner Stores
            
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
            Check Partner stores 

            </span>
            
        </div>
    </div>
</div>

                    </Link>
    
                    {/* <Link to="/allSales">
                    <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 5H7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2V7a2 2 0 0 0 -2 -2h-2" />  <rect x="9" y="3" width="6" height="4" rx="2" />  <line x1="9" y1="12" x2="9.01" y2="12" />  <line x1="13" y1="12" x2="15" y2="12" />  <line x1="9" y1="16" x2="9.01" y2="16" />  <line x1="13" y1="16" x2="15" y2="16" /></svg>
        <p class="text-md text-black  ml-2">
            Sales
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700  text-4xl text-left font-bold my-4">
            Nu. {salesData}
           
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
                Add Sales without customer
            </span>
            
        </div>
    </div>
</div>


                    </Link> */}
            
                </div>




<br/>

<h3 class="text-2xl font-semibold text-black">
                    Stocks
                </h3>

                
                
                <div className="w-full h-10 pl-3 pr-2 bg-gray-100 border-gray-50 rounded-full  outline-none  border-none flex focus:outline-none justify-between items-center relative">
  <input type="text" name="search" id="search" placeholder="Search" 
         className=" w-full bg-gray-100 border-none rounded-full    focus:outline-none focus:border-white active:outline-none"
         value= {search} onChange={(e)=>fetchPost(e.target.value)}
         />
  <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
         viewBox="0 0 24 24" className="w-6 h-6">
      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
    </svg>
  </button>
</div>


<div className="flex flex-col mt-8 ">
      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 ">
        <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                Name
                  </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                Price                   
                   </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                  
                Qnty
                  
                  </th>
                
              </tr>
            </thead>




 <tbody className="bg-white">
            {data ? data.map(item=>{



                //setStockData(StockAmount)
                

              return(

                
            
              <tr data-href="/profile" key={item._id} style={{cursor:"pointer"}}>

                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                  
                  >
                             
                  <div className="flex items-center">
                                    
                    <div className="ml-4">
                      <div className="text-sm leading-5 font-medium text-gray-900">{item.name}
                      </div>
                    </div>
                  </div>
                </td>


                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  <div className="text-sm leading-5 text-gray-900">{item.rate}</div>
                </td>
               
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  <div className="text-sm leading-5 text-gray-900">{item.quantity}</div>
                </td>             
              </tr> 


              )
            }):<></> }
           
  
             
            </tbody>

          
          </table>
        </div>
      </div>
    </div>






            </div>




















        </div>
    </div>
</main>


        
    )
}


export default Home