
import React,{useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import Header from './Header';

import {UserContex} from '../App'
import Footer from './Footer';


const FAQ = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[skills,setSkills]=useState([])
  const[docs,setDocs]=useState([])
  const[salary,setSalary]= useState("")
  const [dob,setDob]= useState(new Date())
  const [url,setUrl] = useState("")  

  const PostData =()=>{

   
//     fetch("/signinemployee",{
//       method:"post",
//       headers:{
//           "Content-Type":"application/json"
//       },
//       body: JSON.stringify({
        
//           password,
//           email:email
//       })
      
//   }).then(res=>res.json())
//   .then(data=> {
     
//       if(data.error){
//         toast.error(data.error)

//       }
//       else{
//         console.log("Login Successful")
//           localStorage.setItem("jwt", data.token)
//           localStorage.setItem("user", JSON.stringify( data.user))
//           dispatch({type:"USER", payload:data.user})
//           history.push('/dashboard')
//       }
//   })
//   .catch(err=>{
//       console.log(err)
//   })

       }

    return(


<>
<Header></Header>

{/* <div className="relative h-screen overflow-hidden scroll-smooth bg-indigo-900 md:block ">

<img src="https://res.cloudinary.com/daehmzcuc/image/upload/v1723387706/pdf/uploadimage-1723387703508.jpg.jpg" className="absolute object-cover  w-full h-full" alt="Background" />



<div className="absolute inset-0 bg-black opacity-25"></div>


 
<div className="relative z-10 flex flex-col items-center justify-center h-full w-full text-center max-w-lg mx-auto ">
  <div className="flex items-center justify-center w-full h-full">
    <h1 
      className=" font-serif  leading-tight text-white text-6xl whitespace-nowrap transition-transform transition-opacity duration-[200ms] ease-in-out"
     
    >
Have  Questions?
<br></br>


</h1>

  </div>

  
 
  <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-4"
  
  >
    <svg className="h-14 w-14 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <polyline points="7 13 12 18 17 13" />
      <polyline points="7 6 12 11 17 6" />
    </svg>
  </div>
</div>
</div>

    */}


<div class="relative h-1/2 overflow-hidden bg-indigo-900">
    <img src="https://cdn.pixabay.com/photo/2020/03/22/17/47/scrabble-4957948_1280.jpg" class="absolute object-cover w-full h-full"/>
    <div className="absolute inset-0 bg-black opacity-20"></div>

  
    <div class="container relative z-10 flex items-center px-6 py-32 mx-auto md:px-12 xl:py-40">
        <div class="relative z-10 flex flex-col items-center w-full">
            <h1 class="mt-4 font-extrabold leading-tight text-center text-gray-200 text-3xl ">
            Have Questions?            </h1>
          
        </div>
        <div className="absolute bottom-0 left-1/2  transform -translate-x-1/2 mb-4"
   
    >
      <svg className="h-14 w-14 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <polyline points="7 13 12 18 17 13" />
        <polyline points="7 6 12 11 17 6" />
      </svg>
    </div>
    </div>
</div>


<div class="max-w-screen-xl p-8 mx-auto">
    <h2 class="mb-12 text-3xl font-extrabold leading-9 text-gray-900 border-b-2 border-gray-100">
        FAQs
    </h2>
    <ul class="flex flex-wrap items-start gap-20">
        <li class="md:w-2/5">
            <p class="text-lg font-medium leading-6 text-gray-900">
            Can I change my shipping address?
            </p>
            <p class="mt-2">
                <p class="text-base leading-6 text-gray-500">
                When an order is placed, we begin processing it immediately to ship it out as fast as possible! Once your order has been shipped, we are unable to edit your shipping address or redirect your order. Please double check your address at checkout prior to placing your order to ensure it will be delivered to the correct location.
​​<br></br>
​​<br></br>
You can contact our Customer Care team at mountaincraftsbtn@gmail.com for any questions!
                </p>
            </p>
        </li>
        <li class="md:w-2/5">
            <p class="text-lg font-medium leading-6 text-gray-900">
            Which Carriers do you ship with?            </p>
            <p class="mt-2">
                <p class="text-base leading-6 text-gray-500">
                National Post (eg: Bhutan Post, India Post, Canada Post, US Postal Service USPS, etc.) for regular shipping 


Express Mail Service (EMS) for Expedited Shipping 
                </p>
            </p>
        </li>
        <li class="md:w-2/5">
            <p class="text-lg font-medium leading-6 text-gray-900">

            How do I make a return or exchange?            </p>
            <p class="mt-2">
                <p class="text-base leading-6 text-gray-500">
                All returns and exchanges must be processed through our returns portal through the link below using your order number and the email the order was placed with. 
                
                <a href='/return' className='text-blue-400 '> Link the return Portal here </a>               </p>
            </p>
            <p class="mt-2">
                <p class="text-base leading-6 text-gray-500">
                Please ensure you read through our <a href='/return' className='text-blue-400 '> returns policy </a>before beginning your return or exchange request. If you have any difficulties, please email our Customer Care team at mountaincraftsbtn@gmail.com and we would be happy to help!                 
                               </p>
            </p>
        </li>
        <li class="md:w-2/5">
            <p class="text-lg font-medium leading-6 text-gray-900">
                What information do I need to supply for an energy rating to be completed??
            </p>
            <p class="mt-2">
                <p class="text-base leading-6 text-gray-500">
                    The information required to complete a full and comprehensive energy report are the following final working drawings: Site Plan, Floor Plan, Elevations, Sections, Lighting layout and window schedule (including sizes of the existing windows).
                </p>
            </p>
        </li>
        <li class="md:w-2/5">
            <p class="text-lg font-medium leading-6 text-gray-900">
            Is it possible to exchange my item for a different style or colour?            </p>
            <p class="mt-2">
                <p class="text-base leading-6 text-gray-500">
                Yes! You can now either exchange the same item for a different size or exchange the item for another colour or style altogether through our returns portal by selecting “Exchange for other items or return for a refund” on the “How can we resolve it?” page after choosing the items you wish to exchange.
​​
                </p>
            </p>
        </li>
        <li class="md:w-2/5">
            <p class="text-lg font-medium leading-6 text-gray-900">

            What do I do if my item has any damages or defects?
            </p>
            <p class="mt-2">
                <p class="text-base leading-6 text-gray-500">
                Please email mountaincraftsbtn@gmail.com the following information so our Customer Care team can help:
                <ul>
                    <li>1. Photo of the tag for sizing</li>
                    <li>2. Photo of the front of the item for identification </li>
                    <li>3. Photo of the damage </li>
                    <li>4. Your order number
                    </li>
                </ul>
                </p>
            </p>
        </li>
        <li class="md:w-2/5">
            <p class="text-lg font-medium leading-6 text-gray-900">

            Can I cancel my order?            </p>
            <p class="mt-2">
                <p class="text-base leading-6 text-gray-500">
                Once an order has shipped, we are unfortunately unable to cancel it.
​
If your order hasn't shipped yet, cancellations are still hard to facilitate as we have hundreds of orders flowing through our warehouse every day and your invoice is sent directly to our warehouse as soon as it's been placed; so pulling a single order from the floor may not be possible.
​
With that being said, we still want to help in any way we can so please email mountaincraftsbtn@gmail.com if you have questions or need assistance!
               
                </p>
            </p>
        </li>
        <li class="md:w-2/5">
            <p class="text-lg font-medium leading-6 text-gray-900">

            Can I edit my order?           </p>
            <p class="mt-2">
                <p class="text-base leading-6 text-gray-500">
                Unfortunately, we’re not able to make adjustments to anything on the order such as the products, sizes, colours, or quantities once an order has been placed.
​
However, we may be able to cancel your order completely so you can place a new order with the correct items after, as long as it hasn't shipped yet.
​
Please feel free to contact us at mountaincraftsbtn@gmail.com and we'll be happy to help in any way we can!

                </p>
            </p>
        </li>
        <li class="md:w-2/5">
            <p class="text-lg font-medium leading-6 text-gray-900">
            Why haven't I received an order confirmation email yet?          </p>
            <p class="mt-2">
                <p class="text-base leading-6 text-gray-500">
                If you don't see your order confirmation in your regular inbox, please check your junk and spam folders as sometimes these confirmation emails can be directed there instead.
​
If you're still unable to find your order confirmation after checking all of your folders, please email mountaincraftsbtn@gmail.com so one of our Customer Care agents can look into it further for you!


                </p>
            </p>
        </li>
        <li class="md:w-2/5">
            <p class="text-lg font-medium leading-6 text-gray-900">
            What do I do if an item was missing from my order that was delivered?         </p>
            <p class="mt-2">
                <p class="text-base leading-6 text-gray-500">
                There can sometimes be an inventory discrepancy or other factors that cause orders to be sent out in separate shipments. We do our best to reach out to our customers to inform them of this as soon as we're aware.
​
If you've received a partial order and haven't been contacted by us yet, please email mountaincraftsbtn@gmail.com so one of our Customer Care agents can look into it further for you!
​
Please note that any missing items must be brought to our attention within 30 days of the order being delivered.


                </p>
            </p>
        </li>
        <li class="md:w-2/5">
            <p class="text-lg font-medium leading-6 text-gray-900">
          
How do I know which size will fit me best?
        </p>
            <p class="mt-2">
                <p class="text-base leading-6 text-gray-500">
                Our products generally fit "true to size" meaning that if you typically wear a size medium in other brands, you would likely enjoy the fit of a size medium in tentree as well.
​
We highly recommend reviewing the sizing chart so you can measure yourself to figure out what size will fit best.

If you have questions or need specific measurements of a product, please email mountaincraftsbtn@gmail.com and we would be happy to help!


                </p>
            </p>
        </li>
        <li class="md:w-2/5">
            <p class="text-lg font-medium leading-6 text-gray-900">
          
            Where can I find the care instructions for products?        </p>
            <p class="mt-2">
                <p class="text-base leading-6 text-gray-500">
                Care instructions can be found on both the Purchase tag of the items and under the "Materials & Care" section of the product pages on our website. Additionally, specific care information for each product is also available directly on its respective product page.
If you're unable to find the care instructions, please email mountaincraftsbtn@gmail.com, and we'll be happy to provide you with the correct information! Let me know if you need any further adjustments or have other questions!



                </p>
            </p>
        </li>
        <li class="md:w-2/5">
            <p class="text-lg font-medium leading-6 text-gray-900">
          
            How do I know if or when a specific product will be restocked?        </p>
            <p class="mt-2">
                <p class="text-base leading-6 text-gray-500">
                We’re not always able to say with certainty if or when an item will be restocked, but you can sign up for a restock notification and we will send you an email if it does!



                </p>
            </p>
        </li>
        <li class="md:w-2/5">
            <p class="text-lg font-medium leading-6 text-gray-900">
          
            Where can I try on your products?
            </p>
            <p class="mt-2">
                <p class="text-base leading-6 text-gray-500">
                You can try out our products at Changbandu...Additionally, we have some of our items available in partner hotels like Six Senses Bhutan, enter all our ertail partners names here.
We unfortunately don't have access to the inventory systems of all our retailers because they carry other brands too, not just tentree. We strongly recommend giving the location a call before making a visit to see if they carry the item you're looking for.




                </p>
            </p>
        </li>
        <li class="md:w-2/5">
            <p class="text-lg font-medium leading-6 text-gray-900">
          
            How do I leave a product review?            </p>
            <p class="mt-2">
                <p class="text-base leading-6 text-gray-500">
                To write a review for one of our products, you can visit that product's page on our website, scroll down to the Reviews and Questions section and click the "Write a Review" button. You can even add photos of your new tentree gear in action!


                </p>
            </p>
        </li>
        <li class="md:w-2/5">
            <p class="text-lg font-medium leading-6 text-gray-900">
          
            Do you have a holiday shipping cutoff date?
           </p>
            <p class="mt-2">
                <p class="text-base leading-6 text-gray-500">
                To receive your order in time for the holidays, we recommend placing your order by November 30th for standard shipping. If you're selecting express shipping, we recommend ordering by December 4th.
​​
Please note that if you place your order after these dates, it may not arrive before December 25th. Shipping timelines are not guaranteed, but we do our best to ensure your holiday orders are sent out and delivered as fast as possible.


                </p>
            </p>
        </li>
        <li class="md:w-2/5">
            <p class="text-lg font-medium leading-6 text-gray-900">
          
            How can I collaborate with Mountain Crafts Bhutan?           </p>
            <p class="mt-2">
                <p class="text-base leading-6 text-gray-500">
                If you're interested in a collaboration with tentree, please send your request to mountaincraftsbtn@gmail.com.
The team will be happy to pass the request along to our brand partnership team!

If you're a content creator looking to collaborate, please feel free to also send your Media Kit.


                </p>
            </p>
        </li>
        <li class="md:w-2/5">
            <p class="text-lg font-medium leading-6 text-gray-900">
          
            How can I become a retailer of Mountain Crafts Bhutan products?          </p>
            <p class="mt-2">
                <p class="text-base leading-6 text-gray-500">
                If you're interested in selling our products through your retail business, please send your request to mountaincraftsbtn@gmail.com.
The team will be happy to pass the request along to our brand partnership team.


                </p>
            </p>
        </li>
    </ul>
</div>




<Footer></Footer>


</>
  


    );
}

export default FAQ;