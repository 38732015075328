import React,{ useEffect, useState,useContext } from "react";
import {Link, useNavigate} from 'react-router-dom'
import {UserContex} from '../App'
import { ToastContainer, toast } from 'react-toastify';
import { Carousel } from 'react-responsive-carousel';

import 'react-toastify/dist/ReactToastify.css';
import Header from "./Header";
import Footer from "./Footer";

const Profile = ()=>{
  const {state, dispatch}= useContext(UserContex)

  const history = useNavigate();
   const [name, setName] = useState("")
   const [email, setEmail] = useState("")
   const [newAddress, setNewAddress] = useState("")
   const [registration, setRegistration] = useState("")
   const [address,setAddress] = useState([])
   const [department,setDepartment] = useState("")
   const [orderHistory,setOrderHistory] = useState(true) 
   const [prescriptions,setShowPrescriptions] = useState(false) 
   const [edit,setEdit] = useState(false) 
   const [showimage,setShowImage] = useState(false) 
   const [zoomimage,setZoomImage] = useState("") 
   const [orderHistoryData,setOrderHistoryData] = useState([]) 
   const [data,setData] = useState([]) 

  //  console.log("STATE",state)
      

   const monthNames = ["January", "February", "March", "April", "May", "June",
   "July", "August", "September", "October", "November", "December"
 ];
 
 var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
 
 const d = new Date();
 
   


// console.log("ADD",address)

 useEffect(  ()=>{

  


    fetch('/getconsumerorder',{
      method:"get",
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt"),
          "Content-Type":"application/json"
  
          
      },
     
  }).then(res=>res.json())
  .then(result=>{
      setOrderHistoryData(result.orders)
      
  })


    fetch(`/getconsumerprofile`,{
      method:"get",
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt"),
          "Content-Type":"application/json"
  
          
      },
   
  }).then(res=>res.json())
  .then(result=>{

    // console.log("DSF")
      // setOrderHistoryData(result.orders)
      setData(result.consumer)
      setAddress(result.consumer[0].address)
  })

},[])


const addAddress = (newaddress)=>{

        // console.log(newaddress)
      setAddress(old=>[...old, newaddress])

}

const updateProfile =()=>{

  fetch("/updateConsumerAddress",{
    method:"put",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"
    },
    body: JSON.stringify({
      
        address:address
    })
    
}).then(res=>res.json())
.then(data=> {
   
    if(data.error){
      toast.error(data.error)

    }
    else{
   toast.success("Updated")
  //  history.go('/cart')

  setEdit(false)
    }
})
.catch(err=>{
    console.log(err)
})

}

// console.log("DATA",data[0].address)


    return(
<>
<Header></Header>
<main className="profile-page">
        <section className="relative block" style={{ height: "500px" }}>
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('/bgimg.png')"
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-16 bg-gray-300">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                      <img
                        alt="..."
                        src="https://i.pinimg.com/originals/3a/52/97/3a52978c16c9b0e1a2d19a0b16b94f1b.jpg"
                        className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16"
                        style={{ maxWidth: "150px" }}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                    {/* <div className="py-6 px-3 mt-32 sm:mt-0">
                      <button
                        className="bg-pink-500 active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                      >
                        Connect
                      </button>
                    </div> */}
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-1">
                    <div className="flex justify-center py-4 lg:pt-4 pt-8">
                      <div className="mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                          {orderHistoryData.length}
                        </span>
                        <span className="text-sm text-gray-500">Orders</span>
                      </div>
                      {/* <div className="mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                          10
                        </span>
                        <span className="text-sm text-gray-500">Consultations</span>
                      </div> */}
                      {/* <div className="lg:mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                          89
                        </span>
                        <span className="text-sm text-gray-500">Comments</span>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-gray-800 mb-2">
                    {state?state.name:""}
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
                    <i className="fas fa-map-marker-alt mr-2 text-lg text-gray-500"></i>{" "}
                    {state?state.email:""}
                  </div>
                 
                  {/* <div className="mb-2 text-gray-700 ">
                  <div class="relative w-full item-center  m-auto   p-2 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 dark:bg-gray-800">
    <div class="flex items-center justify-between  w-full mb-3">
        <p class="text-xl font-medium items-center justify-between mt-2 text-gray-800 dark:text-white">
            Address
        </p>

        <button class="flex items-center text-gray-800 border-0 hover:text-black dark:text-gray-50 dark:hover:text-white focus:outline-none"
        onClick={()=>{
          setEdit(true)
        }}
        >
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z">
                </path>
            </svg>
        </button>
    </div>

        {address.length>0 ?address.map(item=>{

          return(
            <div class="flex items-center justify-between p-3 mb-2 bg-green-100 rounded">
        <span class="p-2 bg-white rounded-lg">
        <svg class="h-8 w-8 text-green-300"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="18" y1="6" x2="18" y2="6.01" />  <path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5" />  <polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15" />  <line x1="9" y1="4" x2="9" y2="17" />  <line x1="15" y1="15" x2="15" y2="20" /></svg>
        </span>
        <div class="flex items-center justify-between w-full ml-2">
            <p>
                {item}
            </p>
            <p>
            </p>
        </div>
    </div>

          )


        }):<></>}

    


   
</div>
         
                    <i className="fas fa-briefcase m-2 text-lg text-gray-500"></i>
                     
         </div> */}



{/* 
                  <div class="flex flex-col bg-white m-auto p-auto">

<div
  class="flex overflow-x-scroll pb-10  hide-scroll-bar"
>
  <div
    class="flex flex-nowrap h-24 lg:ml-80 md:ml-24 ml-2 "
  >
    <div class="h-16 w-45 px-3 cursor-pointer">
      <div
        class="w-50 h-16 flex flex-row max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
     onClick={()=>{setOrderHistory(!orderHistory);setShowPrescriptions(false)}}
     >

      <img src="/orderHistory.png"/>
        <h3 class="pt-4 px-2">Orders</h3>
    </div>
    </div>
  
    <div class="h-16 w-45 px-3 cursor-pointer"
         onClick={()=>{setShowPrescriptions(!prescriptions);setOrderHistory(false)}}

    >
      <div
        class="w-50 h-16 flex flex-row max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
      >

      <img src="/myPrescriptions.png"/>
        <h3 class="pt-4 px-2 invisible lg:visible">Prescriptions</h3>
    </div>
    </div>
    <div class="h-16 w-45 px-3 cursor-pointer"
    
    onClick={() => {setEdit(true);setName(state.name);setEmail(state.email)}}

    >
      <div
        class="w-50 h-16 flex flex-row max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
      >

      <img src="/profileEdit.png"/>
        <h3 class="pt-4 px-2">Edit Profile</h3>
    </div>
    </div>


  </div>
</div>
</div>
  */}
                </div>


  {orderHistory?
            
<div class="container max-w-4xl mx-auto sm:px-8">
    <div class="">
        <div class="px-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
            <div class="inline-block min-w-full overflow-hidden rounded-lg shadow">
                <table class="min-w-full leading-normal">
                    <thead>
                        <tr>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Product
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Quantity
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Price
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Status
                            </th>
                            
                        </tr>
                    </thead>
                    <tbody>

                        {orderHistoryData?orderHistoryData.map(item=>{

                            return(
                              <>
                                { item.orderItem?item.orderItem.map(order=>{
                                                      return(

                            <tr>
                            <td class="px-5 py-5 text-sm bg-white border-b border-gray-200 h-8">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0">

                                        <a href={`/singleproduct/${order.product._id}`} class="relative block">
                                            <img alt="medicine" src={order.product && order.product.pic? order.product.pic[0]:"/pcm.png"} class="mx-auto object-content  h-24 w-32 "/>
                                        </a><p class="text-gray-900 whitespace-no-wrap">
                                            {
                                              order.product.name
                                            }

                                        </p>
                                    </div>
                                    <div class="ml-3">
                                        
                                    </div>
                                </div>
                            </td>
                            <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                <p class="text-gray-900 whitespace-no-wrap">
                                    
<div class="flex items-center">
    
   
        {order.qty}
    
  
   
    
</div>
                                </p>
                            </td>
                            <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                <p class="text-gray-900 whitespace-no-wrap">
                                    Nu. {order.product.rate}
                                </p>
                            </td>
                            <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                <p class="text-gray-900 whitespace-no-wrap">
                                    {item.status}
                                </p>
                            </td>
                          
                        </tr>
                          )
                          }):<></>}

                              </>
                            )

                       

                        }):<></>}

                       
                       
                       
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>:<></>
  }

  {prescriptions?<>
  
    <div className="grid  grid-cols-1  md:grid-cols-4  md:gap-4      ">


      {orderHistoryData?orderHistoryData.map(item=>{

//         if(item.prescription!=""){

//           return(
      
// <div class="w-64 m-auto overflow-hidden shadow-lg rounded-2xl h-90">
//     <img alt="Prescription" src={item.prescription} class="rounded-t-lg"/>
   
// </div>

//           )
//         }

return(

<>

<Carousel showArrows={true}  showThumbs={false}

>
         
         
{item.prescription ? item.prescription.map(pic=>{
 return(

<div  
>
<span class="px-14   py-4  text-base rounded-full text-green-600  bg-green-200 "

>
        {item.status}
    </span>
   <img  src={pic} alt="medicine" class=" h-96" />
  

    </div>



   

 )
}):<></> }         



</Carousel>

</>
)
      }):<></>}



</div>

  </>:<></>}



  

   
   {
showimage? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setShowImage(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           


              
<div class="p-4 mx-auto text-center bg-white border-t-4 border-indigo-500 rounded shadow w-72 dark:bg-gray-800">

  <image
  
  src={zoomimage}
  
  ></image>
    
</div>





         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}



{
edit? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setEdit(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           
          <div className="py-1 px-2">
          <span className="px-1 text-sm text-gray-600">Address</span>

          <div class='flex flex-row'>
          <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Dzongkhag, Area"
            value={newAddress}
            onChange={(e)=>setNewAddress(e.target.value)}
            >

            </input>

            <svg class="h-10 w-10 text-green-500 ml-3 cursor-pointer"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
            
            onClick={()=>{
              addAddress(newAddress)
            }}

            >  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="10" y1="14" x2="21" y2="3" />  <path d="M21 3L14.5 21a.55 .55 0 0 1 -1 0L10 14L3 10.5a.55 .55 0 0 1 0 -1L21 3" /></svg>          </div>
           
          </div>


<div class="container flex flex-col items-center justify-center w-full mx-auto bg-white rounded-lg shadow dark:bg-gray-800">
    <ul class="flex flex-col divide-y divide">

            {address.map(item=>{
              return(
<li class="flex flex-row">
            <div class="flex items-center flex-1 p-4 cursor-pointer select-none">
                <div class="flex flex-col items-center justify-center w-10 h-10 mr-4">
                <svg class="h-8 w-8 text-green-300"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="18" y1="6" x2="18" y2="6.01" />  <path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5" />  <polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15" />  <line x1="9" y1="4" x2="9" y2="17" />  <line x1="15" y1="15" x2="15" y2="20" /></svg>                </div>
                <div class="flex-1 pl-1 mr-16">
                    <div class="font-medium dark:text-white">
                        {item}
                    </div>
                   
                </div>
                {/* <div class="text-xs text-gray-600 dark:text-gray-200">
                    6:00 AM
                </div> */}
            </div>
        </li>
      
              )
            })}

        
      
    </ul>
</div>


          {/* <div className="py-1 px-2">
          <span className="px-1 text-sm text-gray-600">Email</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Email"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            >

            </input>
          </div> */}

          <div class="py-5 rounded-t sm:px-6">
    <div class="overflow-auto bg-white shadow dark:bg-gray-800 sm:rounded-md">
      
        <div class="w-full p-4 mx-auto ">
            <button type="button" class="py-2 px-4  bg-blue-500 hover:bg-blue-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            
            onClick={()=>{
              updateProfile()
                // postOrder(data)
                
              
              }}
            >
                Update
            </button>


        </div>
    </div>
</div>




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}





                <div className="mt-10 py-10 border-t border-gray-300 text-center">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                      <p className="mb-4 text-lg leading-relaxed text-gray-800">
                        Keep track of your orders, Track your Order status. View your history and connect with us.
                      </p>
                      {/* <a
                        href="#pablo"
                        className="font-normal text-pink-500"
                        onClick={e => e.preventDefault()}
                      >
                        Show more
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer></Footer>
</>
    );

}

export default Profile; 